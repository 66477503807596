/*--------------------------------

Nucleo Web Font 2018
Generated using nucleoapp.com

-------------------------------- */
@font-face {
  font-family: 'nucleo';
  src: url(/static/media/nucleo.8155ccd9.eot);
  src: url(/static/media/nucleo.8155ccd9.eot) format('embedded-opentype'), url(/static/media/nucleo.0284acce.woff2) format('woff2'), url(/static/media/nucleo.bcd38b76.woff) format('woff'), url(/static/media/nucleo.338ec058.ttf) format('truetype'), url(/static/media/nucleo.c6e50eca.svg) format('svg');
  font-weight: normal;
  font-style: normal;
}

/*------------------------
	base class definition
-------------------------*/
.nucleo-icon {
  display: inline-block;
  font: normal normal normal 1em/1 'nucleo';
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*------------------------
  change icon size
-------------------------*/
/* relative units */
.nucleo-icon-sm {
  font-size: 0.8em;
}
.nucleo-icon-lg {
  font-size: 1.2em;
}
/* absolute units */
.nucleo-icon-16 {
  font-size: 16px;
}
.nucleo-icon-32 {
  font-size: 32px;
}
/*----------------------------------
  add a square/circle background
-----------------------------------*/
.nucleo-icon-bg-square,
.nucleo-icon-bg-circle {
  padding: 0.35em;
  background-color: #eee;
}
.nucleo-icon-bg-circle {
  border-radius: 50%;
}
/*------------------------------------
  use icons as list item markers
-------------------------------------*/
.nucleo-icon-ul {
  padding-left: 0;
  list-style-type: none;
}
.nucleo-icon-ul > li {
  display: flex;
  align-items: flex-start;
  line-height: 1.4;
}
.nucleo-icon-ul > li > .nucleo-icon {
  margin-right: 0.4em;
  line-height: inherit;
}
/*------------------------
  spinning icons
-------------------------*/
.nucleo-icon-is-spinning {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*------------------------
  rotated/flipped icons
-------------------------*/
.nucleo-icon-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.nucleo-icon-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.nucleo-icon-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.nucleo-icon-flip-y {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.nucleo-icon-flip-x {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}
/*------------------------
	icons
-------------------------*/

.nucleo-icon-2x-drag-down::before {
  content: "\EA01";
}

.nucleo-icon-2x-drag-up::before {
  content: "\EA02";
}

.nucleo-icon-3d-29::before {
  content: "\EA03";
}

.nucleo-icon-3d-model::before {
  content: "\EA04";
}

.nucleo-icon-3d::before {
  content: "\EA05";
}

.nucleo-icon-a-add::before {
  content: "\EA06";
}

.nucleo-icon-a-check::before {
  content: "\EA07";
}

.nucleo-icon-a-edit::before {
  content: "\EA08";
}

.nucleo-icon-a-heart::before {
  content: "\EA09";
}

.nucleo-icon-a-remove::before {
  content: "\EA0A";
}

.nucleo-icon-accessibility::before {
  content: "\EA0B";
}

.nucleo-icon-active-38::before {
  content: "\EA0C";
}

.nucleo-icon-active-40::before {
  content: "\EA0D";
}

.nucleo-icon-add-27::before {
  content: "\EA0E";
}

.nucleo-icon-add-29::before {
  content: "\EA0F";
}

.nucleo-icon-add-e-2::before {
  content: "\EA10";
}

.nucleo-icon-add::before {
  content: "\EA11";
}

.nucleo-icon-agenda-bookmark::before {
  content: "\EA12";
}

.nucleo-icon-agenda::before {
  content: "\EA13";
}

.nucleo-icon-air-baloon::before {
  content: "\EA14";
}

.nucleo-icon-air-conditioner::before {
  content: "\EA15";
}

.nucleo-icon-airbag::before {
  content: "\EA18";
}

.nucleo-icon-alarm-add::before {
  content: "\EA16";
}

.nucleo-icon-alarm-delete::before {
  content: "\EA17";
}

.nucleo-icon-album-2::before {
  content: "\EA19";
}

.nucleo-icon-album::before {
  content: "\EA1A";
}

.nucleo-icon-alert-2::before {
  content: "\EA1B";
}

.nucleo-icon-alert-circle-exc::before {
  content: "\EA1C";
}

.nucleo-icon-alert-circle-i::before {
  content: "\EA1D";
}

.nucleo-icon-alert-circle-que::before {
  content: "\EA1E";
}

.nucleo-icon-alert-exc::before {
  content: "\EA1F";
}

.nucleo-icon-alert-i::before {
  content: "\EA20";
}

.nucleo-icon-alert-que::before {
  content: "\EA21";
}

.nucleo-icon-alert-square-exc::before {
  content: "\EA22";
}

.nucleo-icon-alert-square-i::before {
  content: "\EA23";
}

.nucleo-icon-alert-square-que::before {
  content: "\EA24";
}

.nucleo-icon-alert::before {
  content: "\EA25";
}

.nucleo-icon-alien-29::before {
  content: "\EA26";
}

.nucleo-icon-alien-33::before {
  content: "\EA27";
}

.nucleo-icon-align-bottom::before {
  content: "\EA28";
}

.nucleo-icon-align-center-horizontal::before {
  content: "\EA29";
}

.nucleo-icon-align-center-vertical::before {
  content: "\EA2A";
}

.nucleo-icon-align-center::before {
  content: "\EA2B";
}

.nucleo-icon-align-justify::before {
  content: "\EA2C";
}

.nucleo-icon-align-left-2::before {
  content: "\EA2D";
}

.nucleo-icon-align-left::before {
  content: "\EA2E";
}

.nucleo-icon-align-right-2::before {
  content: "\EA2F";
}

.nucleo-icon-align-right::before {
  content: "\EA30";
}

.nucleo-icon-align-top::before {
  content: "\EA31";
}

.nucleo-icon-analytics-88::before {
  content: "\EA32";
}

.nucleo-icon-analytics-89::before {
  content: "\EA33";
}

.nucleo-icon-analytics::before {
  content: "\EA36";
}

.nucleo-icon-anchor::before {
  content: "\EA34";
}

.nucleo-icon-android::before {
  content: "\EA37";
}

.nucleo-icon-angle::before {
  content: "\EA35";
}

.nucleo-icon-angry-10::before {
  content: "\EA38";
}

.nucleo-icon-angry-44::before {
  content: "\EA39";
}

.nucleo-icon-angry-e-08::before {
  content: "\EA3A";
}

.nucleo-icon-angry-e-17::before {
  content: "\EA3B";
}

.nucleo-icon-animation-14::before {
  content: "\EA3C";
}

.nucleo-icon-animation-31::before {
  content: "\EA3D";
}

.nucleo-icon-animation-32::before {
  content: "\EA3E";
}

.nucleo-icon-app::before {
  content: "\EA3F";
}

.nucleo-icon-apple-2::before {
  content: "\EA40";
}

.nucleo-icon-apple::before {
  content: "\EA41";
}

.nucleo-icon-appointment::before {
  content: "\EA42";
}

.nucleo-icon-apron::before {
  content: "\EA43";
}

.nucleo-icon-archive-2::before {
  content: "\EA44";
}

.nucleo-icon-archive-3d-content::before {
  content: "\EA45";
}

.nucleo-icon-archive-check::before {
  content: "\EA46";
}

.nucleo-icon-archive-content::before {
  content: "\EA47";
}

.nucleo-icon-archive-e-download::before {
  content: "\EA48";
}

.nucleo-icon-archive-e-upload::before {
  content: "\EA49";
}

.nucleo-icon-archive-paper::before {
  content: "\EA4A";
}

.nucleo-icon-archive::before {
  content: "\EA4B";
}

.nucleo-icon-armchair::before {
  content: "\EA4C";
}

.nucleo-icon-artboard::before {
  content: "\EA4D";
}

.nucleo-icon-astronaut::before {
  content: "\EA4E";
}

.nucleo-icon-at-sign::before {
  content: "\EA4F";
}

.nucleo-icon-atm::before {
  content: "\EA50";
}

.nucleo-icon-atom::before {
  content: "\EA51";
}

.nucleo-icon-attach-86::before {
  content: "\EA52";
}

.nucleo-icon-attach-87::before {
  content: "\EA53";
}

.nucleo-icon-aubergine::before {
  content: "\EA55";
}

.nucleo-icon-audio-91::before {
  content: "\EA54";
}

.nucleo-icon-audio-92::before {
  content: "\EA56";
}

.nucleo-icon-avocado::before {
  content: "\EA5A";
}

.nucleo-icon-award-48::before {
  content: "\EA57";
}

.nucleo-icon-award-49::before {
  content: "\EA58";
}

.nucleo-icon-award-55::before {
  content: "\EA59";
}

.nucleo-icon-award-74::before {
  content: "\EA5B";
}

.nucleo-icon-award::before {
  content: "\EA5C";
}

.nucleo-icon-axe::before {
  content: "\EA5D";
}

.nucleo-icon-baby-2::before {
  content: "\EA5E";
}

.nucleo-icon-baby-3::before {
  content: "\EA5F";
}

.nucleo-icon-baby-bottle::before {
  content: "\EA60";
}

.nucleo-icon-baby::before {
  content: "\EA61";
}

.nucleo-icon-back-78::before {
  content: "\EA62";
}

.nucleo-icon-back-80::before {
  content: "\EA63";
}

.nucleo-icon-background::before {
  content: "\EA64";
}

.nucleo-icon-backpack-2::before {
  content: "\EA65";
}

.nucleo-icon-backpack-57::before {
  content: "\EA66";
}

.nucleo-icon-backpack-58::before {
  content: "\EA67";
}

.nucleo-icon-backpack::before {
  content: "\EA68";
}

.nucleo-icon-backward::before {
  content: "\EA69";
}

.nucleo-icon-bacon::before {
  content: "\EA6A";
}

.nucleo-icon-badge-13::before {
  content: "\EA6B";
}

.nucleo-icon-badge-14::before {
  content: "\EA6C";
}

.nucleo-icon-badge-15::before {
  content: "\EA6D";
}

.nucleo-icon-badge::before {
  content: "\EA6E";
}

.nucleo-icon-badminton::before {
  content: "\EA6F";
}

.nucleo-icon-bag-09::before {
  content: "\EA70";
}

.nucleo-icon-bag-16::before {
  content: "\EA71";
}

.nucleo-icon-bag-17::before {
  content: "\EA72";
}

.nucleo-icon-bag-20::before {
  content: "\EA73";
}

.nucleo-icon-bag-21::before {
  content: "\EA74";
}

.nucleo-icon-bag-22::before {
  content: "\EA75";
}

.nucleo-icon-bag-49::before {
  content: "\EA76";
}

.nucleo-icon-bag-50::before {
  content: "\EA77";
}

.nucleo-icon-bag-add-18::before {
  content: "\EA78";
}

.nucleo-icon-bag-add-21::before {
  content: "\EA79";
}

.nucleo-icon-bag-delivery::before {
  content: "\EA7A";
}

.nucleo-icon-bag-edit::before {
  content: "\EA7B";
}

.nucleo-icon-bag-remove-19::before {
  content: "\EA7C";
}

.nucleo-icon-bag-remove-22::before {
  content: "\EA7D";
}

.nucleo-icon-bag-time::before {
  content: "\EA7E";
}

.nucleo-icon-bag::before {
  content: "\EA7F";
}

.nucleo-icon-bakery::before {
  content: "\EA80";
}

.nucleo-icon-balance::before {
  content: "\EA81";
}

.nucleo-icon-ball-basket::before {
  content: "\EA82";
}

.nucleo-icon-ball-soccer::before {
  content: "\EA83";
}

.nucleo-icon-baloon::before {
  content: "\EA84";
}

.nucleo-icon-ban::before {
  content: "\EA85";
}

.nucleo-icon-banana::before {
  content: "\EA86";
}

.nucleo-icon-bank::before {
  content: "\EA87";
}

.nucleo-icon-barbecue-tools::before {
  content: "\EA88";
}

.nucleo-icon-barcode-qr::before {
  content: "\EA89";
}

.nucleo-icon-barcode::before {
  content: "\EA8A";
}

.nucleo-icon-bars-2::before {
  content: "\EA8E";
}

.nucleo-icon-bars-rotate::before {
  content: "\EA91";
}

.nucleo-icon-bars::before {
  content: "\EA8B";
}

.nucleo-icon-baseball-ball::before {
  content: "\EA8C";
}

.nucleo-icon-baseball-bat::before {
  content: "\EA8D";
}

.nucleo-icon-basket-simple-add::before {
  content: "\EA8F";
}

.nucleo-icon-basket-simple-remove::before {
  content: "\EA90";
}

.nucleo-icon-basket-simple::before {
  content: "\EA92";
}

.nucleo-icon-basket::before {
  content: "\EA93";
}

.nucleo-icon-basketball-12::before {
  content: "\EA94";
}

.nucleo-icon-bat::before {
  content: "\EA95";
}

.nucleo-icon-battery-81::before {
  content: "\EA96";
}

.nucleo-icon-battery-83::before {
  content: "\EA97";
}

.nucleo-icon-battery-half::before {
  content: "\EA98";
}

.nucleo-icon-battery-level::before {
  content: "\EA99";
}

.nucleo-icon-battery-low::before {
  content: "\EA9A";
}

.nucleo-icon-battery::before {
  content: "\EA9B";
}

.nucleo-icon-bear-2::before {
  content: "\EA9C";
}

.nucleo-icon-bed-09::before {
  content: "\EA9D";
}

.nucleo-icon-bed-23::before {
  content: "\EA9E";
}

.nucleo-icon-bee::before {
  content: "\EA9F";
}

.nucleo-icon-beer-95::before {
  content: "\EAA0";
}

.nucleo-icon-beer-96::before {
  content: "\EAA1";
}

.nucleo-icon-bell-53::before {
  content: "\EAA2";
}

.nucleo-icon-bell-54::before {
  content: "\EAA3";
}

.nucleo-icon-bell-55::before {
  content: "\EAA4";
}

.nucleo-icon-belt::before {
  content: "\EAA5";
}

.nucleo-icon-beverage::before {
  content: "\EAA6";
}

.nucleo-icon-big-eyes::before {
  content: "\EAA7";
}

.nucleo-icon-big-smile::before {
  content: "\EAA8";
}

.nucleo-icon-bigmouth::before {
  content: "\EAA9";
}

.nucleo-icon-bike-2::before {
  content: "\EAAB";
}

.nucleo-icon-bike::before {
  content: "\EAAA";
}

.nucleo-icon-bill::before {
  content: "\EAB5";
}

.nucleo-icon-billiard::before {
  content: "\EAAC";
}

.nucleo-icon-binocular::before {
  content: "\EAAD";
}

.nucleo-icon-bitcoin::before {
  content: "\EAAE";
}

.nucleo-icon-blend::before {
  content: "\EAAF";
}

.nucleo-icon-blender::before {
  content: "\EAB6";
}

.nucleo-icon-block-bottom-left::before {
  content: "\EAB0";
}

.nucleo-icon-block-bottom-right::before {
  content: "\EAB1";
}

.nucleo-icon-block-down::before {
  content: "\EAB2";
}

.nucleo-icon-block-left::before {
  content: "\EAB3";
}

.nucleo-icon-block-right::before {
  content: "\EAB4";
}

.nucleo-icon-block-top-left::before {
  content: "\EAB7";
}

.nucleo-icon-block-top-right::before {
  content: "\EAB8";
}

.nucleo-icon-block-up::before {
  content: "\EAB9";
}

.nucleo-icon-blog::before {
  content: "\EABA";
}

.nucleo-icon-blueberries::before {
  content: "\EABB";
}

.nucleo-icon-bluetooth::before {
  content: "\EABC";
}

.nucleo-icon-board-27::before {
  content: "\EABD";
}

.nucleo-icon-board-28::before {
  content: "\EABE";
}

.nucleo-icon-board-51::before {
  content: "\EABF";
}

.nucleo-icon-board::before {
  content: "\EAC0";
}

.nucleo-icon-boat-small-02::before {
  content: "\EAC1";
}

.nucleo-icon-boat-small-03::before {
  content: "\EAC2";
}

.nucleo-icon-boat::before {
  content: "\EAC3";
}

.nucleo-icon-boiling-water::before {
  content: "\EAC4";
}

.nucleo-icon-bold-add::before {
  content: "\EAC5";
}

.nucleo-icon-bold-delete::before {
  content: "\EAC6";
}

.nucleo-icon-bold-down::before {
  content: "\EAC7";
}

.nucleo-icon-bold-left::before {
  content: "\EAC8";
}

.nucleo-icon-bold-remove::before {
  content: "\EAC9";
}

.nucleo-icon-bold-right::before {
  content: "\EACA";
}

.nucleo-icon-bold-up::before {
  content: "\EACB";
}

.nucleo-icon-bold::before {
  content: "\EACC";
}

.nucleo-icon-bolt::before {
  content: "\EACD";
}

.nucleo-icon-bomb::before {
  content: "\EACE";
}

.nucleo-icon-bones::before {
  content: "\EACF";
}

.nucleo-icon-book-07::before {
  content: "\EAD0";
}

.nucleo-icon-book-39::before {
  content: "\EAD1";
}

.nucleo-icon-book-bookmark-2::before {
  content: "\EAD2";
}

.nucleo-icon-book-bookmark::before {
  content: "\EAD3";
}

.nucleo-icon-book-e::before {
  content: "\EAD6";
}

.nucleo-icon-book-open-2::before {
  content: "\EAD4";
}

.nucleo-icon-book-open::before {
  content: "\EAD5";
}

.nucleo-icon-book::before {
  content: "\EAD7";
}

.nucleo-icon-bookmark-2::before {
  content: "\EAD8";
}

.nucleo-icon-bookmark-add-2::before {
  content: "\EAD9";
}

.nucleo-icon-bookmark-add-e::before {
  content: "\EADA";
}

.nucleo-icon-bookmark-add::before {
  content: "\EADB";
}

.nucleo-icon-bookmark-remove-2::before {
  content: "\EADC";
}

.nucleo-icon-bookmark-remove-e::before {
  content: "\EADD";
}

.nucleo-icon-bookmark-remove::before {
  content: "\EADE";
}

.nucleo-icon-books-46::before {
  content: "\EADF";
}

.nucleo-icon-books::before {
  content: "\EAE0";
}

.nucleo-icon-boot-2::before {
  content: "\EAE1";
}

.nucleo-icon-boot-woman::before {
  content: "\EAE2";
}

.nucleo-icon-boot::before {
  content: "\EAE3";
}

.nucleo-icon-border-radius::before {
  content: "\EAE5";
}

.nucleo-icon-border::before {
  content: "\EAE6";
}

.nucleo-icon-bottle-wine::before {
  content: "\EAE4";
}

.nucleo-icon-bottle::before {
  content: "\EAE7";
}

.nucleo-icon-bow::before {
  content: "\EAE8";
}

.nucleo-icon-bowl::before {
  content: "\EAE9";
}

.nucleo-icon-bowling-pins::before {
  content: "\EAEA";
}

.nucleo-icon-bowling::before {
  content: "\EAEB";
}

.nucleo-icon-box-2::before {
  content: "\EAEC";
}

.nucleo-icon-box-ribbon::before {
  content: "\EAED";
}

.nucleo-icon-box::before {
  content: "\EAEE";
}

.nucleo-icon-boxing-bag::before {
  content: "\EAEF";
}

.nucleo-icon-boxing::before {
  content: "\EAF0";
}

.nucleo-icon-bra::before {
  content: "\EAF1";
}

.nucleo-icon-brain::before {
  content: "\EAF2";
}

.nucleo-icon-brakes::before {
  content: "\EAF3";
}

.nucleo-icon-bread::before {
  content: "\EAF4";
}

.nucleo-icon-briefcase-24::before {
  content: "\EAF5";
}

.nucleo-icon-briefcase-25::before {
  content: "\EAF6";
}

.nucleo-icon-briefcase-26::before {
  content: "\EAF7";
}

.nucleo-icon-brightness-46::before {
  content: "\EAF8";
}

.nucleo-icon-brightness-47::before {
  content: "\EAF9";
}

.nucleo-icon-brioche::before {
  content: "\EB01";
}

.nucleo-icon-broccoli::before {
  content: "\EAFA";
}

.nucleo-icon-broom::before {
  content: "\EAFB";
}

.nucleo-icon-brush::before {
  content: "\EAFC";
}

.nucleo-icon-bucket::before {
  content: "\EAFD";
}

.nucleo-icon-bug::before {
  content: "\EAFE";
}

.nucleo-icon-building::before {
  content: "\EAFF";
}

.nucleo-icon-bulb-61::before {
  content: "\EB00";
}

.nucleo-icon-bulb-62::before {
  content: "\EB02";
}

.nucleo-icon-bulb-63::before {
  content: "\EB03";
}

.nucleo-icon-bulb-saver::before {
  content: "\EB04";
}

.nucleo-icon-bulb::before {
  content: "\EB05";
}

.nucleo-icon-bullet-list-67::before {
  content: "\EB06";
}

.nucleo-icon-bullet-list-68::before {
  content: "\EB07";
}

.nucleo-icon-bullet-list-69::before {
  content: "\EB08";
}

.nucleo-icon-bullet-list-70::before {
  content: "\EB09";
}

.nucleo-icon-bullet-list::before {
  content: "\EB0A";
}

.nucleo-icon-bus-front-10::before {
  content: "\EB0B";
}

.nucleo-icon-bus-front-12::before {
  content: "\EB0C";
}

.nucleo-icon-businessman-03::before {
  content: "\EB0D";
}

.nucleo-icon-businessman-04::before {
  content: "\EB0E";
}

.nucleo-icon-butter::before {
  content: "\EB0F";
}

.nucleo-icon-butterfly::before {
  content: "\EB10";
}

.nucleo-icon-button-2::before {
  content: "\EB11";
}

.nucleo-icon-button-eject::before {
  content: "\EB12";
}

.nucleo-icon-button-next::before {
  content: "\EB13";
}

.nucleo-icon-button-pause::before {
  content: "\EB14";
}

.nucleo-icon-button-play::before {
  content: "\EB15";
}

.nucleo-icon-button-power::before {
  content: "\EB16";
}

.nucleo-icon-button-previous::before {
  content: "\EB17";
}

.nucleo-icon-button-record::before {
  content: "\EB18";
}

.nucleo-icon-button-rewind::before {
  content: "\EB19";
}

.nucleo-icon-button-skip::before {
  content: "\EB1A";
}

.nucleo-icon-button-stop::before {
  content: "\EB1B";
}

.nucleo-icon-button::before {
  content: "\EB1C";
}

.nucleo-icon-cabinet::before {
  content: "\EB1D";
}

.nucleo-icon-cable-49::before {
  content: "\EB1E";
}

.nucleo-icon-cactus::before {
  content: "\EB1F";
}

.nucleo-icon-cake-13::before {
  content: "\EB20";
}

.nucleo-icon-cake-slice::before {
  content: "\EB21";
}

.nucleo-icon-cake::before {
  content: "\EB22";
}

.nucleo-icon-calculator::before {
  content: "\EB23";
}

.nucleo-icon-calendar-57::before {
  content: "\EB24";
}

.nucleo-icon-calendar-60::before {
  content: "\EB25";
}

.nucleo-icon-calendar-add-2::before {
  content: "\EB26";
}

.nucleo-icon-calendar-add::before {
  content: "\EB27";
}

.nucleo-icon-calendar-check-59::before {
  content: "\EB28";
}

.nucleo-icon-calendar-check-62::before {
  content: "\EB29";
}

.nucleo-icon-calendar-grid-58::before {
  content: "\EB2A";
}

.nucleo-icon-calendar-grid-61::before {
  content: "\EB2B";
}

.nucleo-icon-camera-18::before {
  content: "\EB2C";
}

.nucleo-icon-camera-19::before {
  content: "\EB2D";
}

.nucleo-icon-camera-20::before {
  content: "\EB2E";
}

.nucleo-icon-camera-ban-37::before {
  content: "\EB2F";
}

.nucleo-icon-camera-compact::before {
  content: "\EB30";
}

.nucleo-icon-camera-square-57::before {
  content: "\EB31";
}

.nucleo-icon-camera-time::before {
  content: "\EB32";
}

.nucleo-icon-camera::before {
  content: "\EB33";
}

.nucleo-icon-camping::before {
  content: "\EB34";
}

.nucleo-icon-can::before {
  content: "\EB36";
}

.nucleo-icon-candle::before {
  content: "\EB35";
}

.nucleo-icon-candy-2::before {
  content: "\EB37";
}

.nucleo-icon-candy::before {
  content: "\EB38";
}

.nucleo-icon-canvas::before {
  content: "\EB39";
}

.nucleo-icon-cap::before {
  content: "\EB3A";
}

.nucleo-icon-capitalize::before {
  content: "\EB3B";
}

.nucleo-icon-caps-small::before {
  content: "\EB3C";
}

.nucleo-icon-car-2::before {
  content: "\EB3D";
}

.nucleo-icon-car-accident::before {
  content: "\EB3E";
}

.nucleo-icon-car-connect::before {
  content: "\EB3F";
}

.nucleo-icon-car-door::before {
  content: "\EB41";
}

.nucleo-icon-car-front::before {
  content: "\EB40";
}

.nucleo-icon-car-lights::before {
  content: "\EB42";
}

.nucleo-icon-car-parking::before {
  content: "\EB43";
}

.nucleo-icon-car-taxi::before {
  content: "\EB44";
}

.nucleo-icon-car-ventilation::before {
  content: "\EB45";
}

.nucleo-icon-car-wash::before {
  content: "\EB46";
}

.nucleo-icon-car::before {
  content: "\EB47";
}

.nucleo-icon-card-add-2::before {
  content: "\EB48";
}

.nucleo-icon-card-add::before {
  content: "\EB49";
}

.nucleo-icon-card-alert::before {
  content: "\EB4A";
}

.nucleo-icon-card-edit::before {
  content: "\EB4B";
}

.nucleo-icon-card-favorite::before {
  content: "\EB4C";
}

.nucleo-icon-card-remove::before {
  content: "\EB4D";
}

.nucleo-icon-cardio::before {
  content: "\EB4E";
}

.nucleo-icon-cards::before {
  content: "\EB4F";
}

.nucleo-icon-carrot::before {
  content: "\EB50";
}

.nucleo-icon-cart-9::before {
  content: "\EB51";
}

.nucleo-icon-cart-favorite::before {
  content: "\EB52";
}

.nucleo-icon-cart-in-9::before {
  content: "\EB53";
}

.nucleo-icon-cart-return::before {
  content: "\EB54";
}

.nucleo-icon-cart-simple-add::before {
  content: "\EB55";
}

.nucleo-icon-cart-simple-in::before {
  content: "\EB56";
}

.nucleo-icon-cart-simple-remove::before {
  content: "\EB57";
}

.nucleo-icon-cart-simple::before {
  content: "\EB58";
}

.nucleo-icon-cart-speed::before {
  content: "\EB59";
}

.nucleo-icon-cart::before {
  content: "\EB5A";
}

.nucleo-icon-cash-register::before {
  content: "\EB5B";
}

.nucleo-icon-cat::before {
  content: "\EB5C";
}

.nucleo-icon-cauldron::before {
  content: "\EB5D";
}

.nucleo-icon-cctv::before {
  content: "\EB5E";
}

.nucleo-icon-cd-reader::before {
  content: "\EB5F";
}

.nucleo-icon-celsius::before {
  content: "\EB60";
}

.nucleo-icon-centralize::before {
  content: "\EB61";
}

.nucleo-icon-chair-2::before {
  content: "\EB62";
}

.nucleo-icon-chair::before {
  content: "\EB63";
}

.nucleo-icon-champagne::before {
  content: "\EB64";
}

.nucleo-icon-chart-2::before {
  content: "\EB66";
}

.nucleo-icon-chart-3::before {
  content: "\EB65";
}

.nucleo-icon-chart-bar-32::before {
  content: "\EB67";
}

.nucleo-icon-chart-bar-52::before {
  content: "\EB68";
}

.nucleo-icon-chart-bar-53::before {
  content: "\EB69";
}

.nucleo-icon-chart-bars::before {
  content: "\EB6A";
}

.nucleo-icon-chart-growth::before {
  content: "\EB6B";
}

.nucleo-icon-chart-pie-35::before {
  content: "\EB6C";
}

.nucleo-icon-chart-pie-36::before {
  content: "\EB6D";
}

.nucleo-icon-chart::before {
  content: "\EB6E";
}

.nucleo-icon-chat-33::before {
  content: "\EB6F";
}

.nucleo-icon-chat-45::before {
  content: "\EB70";
}

.nucleo-icon-chat-46::before {
  content: "\EB71";
}

.nucleo-icon-chat-content::before {
  content: "\EB72";
}

.nucleo-icon-chat-reply::before {
  content: "\EB73";
}

.nucleo-icon-chat-round-content::before {
  content: "\EB74";
}

.nucleo-icon-chat-round::before {
  content: "\EB75";
}

.nucleo-icon-chat::before {
  content: "\EB76";
}

.nucleo-icon-check-2::before {
  content: "\EB77";
}

.nucleo-icon-check-all::before {
  content: "\EB7A";
}

.nucleo-icon-check-bold::before {
  content: "\EB78";
}

.nucleo-icon-check-circle-07::before {
  content: "\EB79";
}

.nucleo-icon-check-circle-08::before {
  content: "\EB7B";
}

.nucleo-icon-check-curve::before {
  content: "\EB7C";
}

.nucleo-icon-check-in::before {
  content: "\EB7D";
}

.nucleo-icon-check-out::before {
  content: "\EB7E";
}

.nucleo-icon-check-simple::before {
  content: "\EB7F";
}

.nucleo-icon-check-small::before {
  content: "\EB80";
}

.nucleo-icon-check-square-09::before {
  content: "\EB81";
}

.nucleo-icon-check-square-11::before {
  content: "\EB82";
}

.nucleo-icon-cheese-24::before {
  content: "\EB83";
}

.nucleo-icon-cheese-87::before {
  content: "\EB84";
}

.nucleo-icon-cheeseburger::before {
  content: "\EB85";
}

.nucleo-icon-chef-hat::before {
  content: "\EB86";
}

.nucleo-icon-chef::before {
  content: "\EB87";
}

.nucleo-icon-cheque-2::before {
  content: "\EB88";
}

.nucleo-icon-cheque-3::before {
  content: "\EB89";
}

.nucleo-icon-cheque::before {
  content: "\EB8A";
}

.nucleo-icon-cherry::before {
  content: "\EB8B";
}

.nucleo-icon-chicken-2::before {
  content: "\EB8C";
}

.nucleo-icon-chicken::before {
  content: "\EB8D";
}

.nucleo-icon-chili::before {
  content: "\EB8E";
}

.nucleo-icon-chimney::before {
  content: "\EB8F";
}

.nucleo-icon-chips::before {
  content: "\EB90";
}

.nucleo-icon-choco-cream::before {
  content: "\EB91";
}

.nucleo-icon-chocolate-mousse::before {
  content: "\EB92";
}

.nucleo-icon-chocolate::before {
  content: "\EB93";
}

.nucleo-icon-churros::before {
  content: "\EB94";
}

.nucleo-icon-circle-02::before {
  content: "\EB95";
}

.nucleo-icon-circle-03::before {
  content: "\EB96";
}

.nucleo-icon-circle-04::before {
  content: "\EB97";
}

.nucleo-icon-circle-08::before {
  content: "\EB98";
}

.nucleo-icon-circle-09::before {
  content: "\EB99";
}

.nucleo-icon-circle-10::before {
  content: "\EB9A";
}

.nucleo-icon-circle-add::before {
  content: "\EB9B";
}

.nucleo-icon-circle-bold-add::before {
  content: "\EB9C";
}

.nucleo-icon-circle-bold-delete::before {
  content: "\EB9D";
}

.nucleo-icon-circle-bold-remove::before {
  content: "\EB9E";
}

.nucleo-icon-circle-delete::before {
  content: "\EB9F";
}

.nucleo-icon-circle-down-12::before {
  content: "\EBA0";
}

.nucleo-icon-circle-down-40::before {
  content: "\EBA1";
}

.nucleo-icon-circle-e-down-04::before {
  content: "\EBA2";
}

.nucleo-icon-circle-e-down-12::before {
  content: "\EBA3";
}

.nucleo-icon-circle-e-left-02::before {
  content: "\EBA4";
}

.nucleo-icon-circle-e-left-10::before {
  content: "\EBA5";
}

.nucleo-icon-circle-e-right-01::before {
  content: "\EBA6";
}

.nucleo-icon-circle-e-right-09::before {
  content: "\EBA7";
}

.nucleo-icon-circle-e-up-03::before {
  content: "\EBA8";
}

.nucleo-icon-circle-e-up-11::before {
  content: "\EBA9";
}

.nucleo-icon-circle-left-10::before {
  content: "\EBAA";
}

.nucleo-icon-circle-left-38::before {
  content: "\EBAB";
}

.nucleo-icon-circle-remove::before {
  content: "\EBAC";
}

.nucleo-icon-circle-right-09::before {
  content: "\EBAD";
}

.nucleo-icon-circle-right-37::before {
  content: "\EBAE";
}

.nucleo-icon-circle-simple-down::before {
  content: "\EBAF";
}

.nucleo-icon-circle-simple-left::before {
  content: "\EBB0";
}

.nucleo-icon-circle-simple-right::before {
  content: "\EBB1";
}

.nucleo-icon-circle-simple-up::before {
  content: "\EBB2";
}

.nucleo-icon-circle-up-11::before {
  content: "\EBB3";
}

.nucleo-icon-circle-up-39::before {
  content: "\EBB4";
}

.nucleo-icon-circle::before {
  content: "\EBB5";
}

.nucleo-icon-clone::before {
  content: "\EBB6";
}

.nucleo-icon-cloud-01::before {
  content: "\EBB7";
}

.nucleo-icon-cloud-02::before {
  content: "\EBB8";
}

.nucleo-icon-cloud-25::before {
  content: "\EBB9";
}

.nucleo-icon-cloud-26::before {
  content: "\EBBA";
}

.nucleo-icon-cloud-download-95::before {
  content: "\EBBB";
}

.nucleo-icon-cloud-drop::before {
  content: "\EBBC";
}

.nucleo-icon-cloud-fog-31::before {
  content: "\EBBD";
}

.nucleo-icon-cloud-fog-32::before {
  content: "\EBBE";
}

.nucleo-icon-cloud-hail::before {
  content: "\EBBF";
}

.nucleo-icon-cloud-light::before {
  content: "\EBC0";
}

.nucleo-icon-cloud-moon::before {
  content: "\EBC1";
}

.nucleo-icon-cloud-rain::before {
  content: "\EBC2";
}

.nucleo-icon-cloud-snow-34::before {
  content: "\EBC3";
}

.nucleo-icon-cloud-snow-42::before {
  content: "\EBC4";
}

.nucleo-icon-cloud-sun-17::before {
  content: "\EBC5";
}

.nucleo-icon-cloud-upload-96::before {
  content: "\EBC6";
}

.nucleo-icon-clover::before {
  content: "\EBC7";
}

.nucleo-icon-coat-hanger::before {
  content: "\EBC8";
}

.nucleo-icon-cockade::before {
  content: "\EBC9";
}

.nucleo-icon-cocktail::before {
  content: "\EBCA";
}

.nucleo-icon-code::before {
  content: "\EBCB";
}

.nucleo-icon-coffe-long::before {
  content: "\EBCC";
}

.nucleo-icon-coffee-2::before {
  content: "\EBCD";
}

.nucleo-icon-coffee-bean::before {
  content: "\EBCE";
}

.nucleo-icon-coffee-long::before {
  content: "\EBCF";
}

.nucleo-icon-coffee::before {
  content: "\EBD0";
}

.nucleo-icon-coffin::before {
  content: "\EBD1";
}

.nucleo-icon-coins::before {
  content: "\EBD2";
}

.nucleo-icon-collar::before {
  content: "\EBD3";
}

.nucleo-icon-collection::before {
  content: "\EBD4";
}

.nucleo-icon-color::before {
  content: "\EBD5";
}

.nucleo-icon-command::before {
  content: "\EBD6";
}

.nucleo-icon-comment-add::before {
  content: "\EBD7";
}

.nucleo-icon-compare::before {
  content: "\EBD8";
}

.nucleo-icon-compass-04::before {
  content: "\EBD9";
}

.nucleo-icon-compass-05::before {
  content: "\EBDA";
}

.nucleo-icon-compass-06::before {
  content: "\EBDB";
}

.nucleo-icon-compass-2::before {
  content: "\EBDD";
}

.nucleo-icon-compass-3::before {
  content: "\EBDC";
}

.nucleo-icon-compass::before {
  content: "\EBDE";
}

.nucleo-icon-components::before {
  content: "\EBDF";
}

.nucleo-icon-computer-monitor::before {
  content: "\EBE0";
}

.nucleo-icon-computer-old::before {
  content: "\EBE1";
}

.nucleo-icon-computer::before {
  content: "\EBE2";
}

.nucleo-icon-cone::before {
  content: "\EBE3";
}

.nucleo-icon-connect::before {
  content: "\EBE4";
}

.nucleo-icon-connection::before {
  content: "\EBE5";
}

.nucleo-icon-contacts-44::before {
  content: "\EBE6";
}

.nucleo-icon-contacts-45::before {
  content: "\EBE7";
}

.nucleo-icon-contacts::before {
  content: "\EBE8";
}

.nucleo-icon-contrast-2::before {
  content: "\EBE9";
}

.nucleo-icon-contrast::before {
  content: "\EBEA";
}

.nucleo-icon-controller-2::before {
  content: "\EBEB";
}

.nucleo-icon-controller-3::before {
  content: "\EBEC";
}

.nucleo-icon-controller::before {
  content: "\EBED";
}

.nucleo-icon-conversion::before {
  content: "\EBEE";
}

.nucleo-icon-cookies::before {
  content: "\EBEF";
}

.nucleo-icon-copy-2::before {
  content: "\EBF0";
}

.nucleo-icon-copy::before {
  content: "\EBF1";
}

.nucleo-icon-corn::before {
  content: "\EBF2";
}

.nucleo-icon-corner-down-round::before {
  content: "\EBF3";
}

.nucleo-icon-corner-down::before {
  content: "\EBF4";
}

.nucleo-icon-corner-left-down::before {
  content: "\EBF5";
}

.nucleo-icon-corner-left-round::before {
  content: "\EBF6";
}

.nucleo-icon-corner-left::before {
  content: "\EBF7";
}

.nucleo-icon-corner-right-down::before {
  content: "\EBF8";
}

.nucleo-icon-corner-right-round::before {
  content: "\EBF9";
}

.nucleo-icon-corner-right::before {
  content: "\EBFA";
}

.nucleo-icon-corner-up-left::before {
  content: "\EBFB";
}

.nucleo-icon-corner-up-right::before {
  content: "\EBFC";
}

.nucleo-icon-corner-up-round::before {
  content: "\EBFD";
}

.nucleo-icon-corner-up::before {
  content: "\EBFE";
}

.nucleo-icon-corset::before {
  content: "\EBFF";
}

.nucleo-icon-countdown-34::before {
  content: "\EC00";
}

.nucleo-icon-coupon::before {
  content: "\EC01";
}

.nucleo-icon-cow::before {
  content: "\EC02";
}

.nucleo-icon-crab::before {
  content: "\EC03";
}

.nucleo-icon-cradle::before {
  content: "\EC04";
}

.nucleo-icon-credit-card-in::before {
  content: "\EC05";
}

.nucleo-icon-credit-card::before {
  content: "\EC06";
}

.nucleo-icon-crepe::before {
  content: "\EC07";
}

.nucleo-icon-cricket::before {
  content: "\EC08";
}

.nucleo-icon-croissant::before {
  content: "\EC09";
}

.nucleo-icon-crop::before {
  content: "\EC0A";
}

.nucleo-icon-cross-down::before {
  content: "\EC0B";
}

.nucleo-icon-cross-left::before {
  content: "\EC0C";
}

.nucleo-icon-cross-right::before {
  content: "\EC0D";
}

.nucleo-icon-cross-up::before {
  content: "\EC0E";
}

.nucleo-icon-cross::before {
  content: "\EC0F";
}

.nucleo-icon-crosshair::before {
  content: "\EC10";
}

.nucleo-icon-crown::before {
  content: "\EC11";
}

.nucleo-icon-crumpet::before {
  content: "\EC12";
}

.nucleo-icon-cry-15::before {
  content: "\EC13";
}

.nucleo-icon-css3::before {
  content: "\EC14";
}

.nucleo-icon-cupcake::before {
  content: "\EC15";
}

.nucleo-icon-curling-stone::before {
  content: "\EC16";
}

.nucleo-icon-currency-dollar::before {
  content: "\EC17";
}

.nucleo-icon-currency-euro::before {
  content: "\EC18";
}

.nucleo-icon-currency-exchange::before {
  content: "\EC19";
}

.nucleo-icon-currency-pound::before {
  content: "\EC1A";
}

.nucleo-icon-currency-yen::before {
  content: "\EC1B";
}

.nucleo-icon-cursor-48::before {
  content: "\EC1C";
}

.nucleo-icon-cursor-49::before {
  content: "\EC1D";
}

.nucleo-icon-cursor-add::before {
  content: "\EC1E";
}

.nucleo-icon-cursor-grab::before {
  content: "\EC1F";
}

.nucleo-icon-cursor-load::before {
  content: "\EC20";
}

.nucleo-icon-cursor-menu::before {
  content: "\EC21";
}

.nucleo-icon-cursor-not-allowed::before {
  content: "\EC22";
}

.nucleo-icon-cursor-pointer::before {
  content: "\EC23";
}

.nucleo-icon-cursor-text::before {
  content: "\EC24";
}

.nucleo-icon-curtain::before {
  content: "\EC25";
}

.nucleo-icon-curved-next::before {
  content: "\EC26";
}

.nucleo-icon-curved-previous::before {
  content: "\EC27";
}

.nucleo-icon-customer-support::before {
  content: "\EC28";
}

.nucleo-icon-cute::before {
  content: "\EC29";
}

.nucleo-icon-cutlery-75::before {
  content: "\EC2A";
}

.nucleo-icon-cutlery-76::before {
  content: "\EC2B";
}

.nucleo-icon-cutlery-77::before {
  content: "\EC2C";
}

.nucleo-icon-cutlery::before {
  content: "\EC2E";
}

.nucleo-icon-cyclist::before {
  content: "\EC2D";
}

.nucleo-icon-dancer::before {
  content: "\EC2F";
}

.nucleo-icon-dart::before {
  content: "\EC30";
}

.nucleo-icon-dashboard-29::before {
  content: "\EC31";
}

.nucleo-icon-dashboard-30::before {
  content: "\EC32";
}

.nucleo-icon-dashboard-half::before {
  content: "\EC33";
}

.nucleo-icon-dashboard-level::before {
  content: "\EC36";
}

.nucleo-icon-database::before {
  content: "\EC34";
}

.nucleo-icon-deer::before {
  content: "\EC35";
}

.nucleo-icon-delete-28::before {
  content: "\EC37";
}

.nucleo-icon-delete-30::before {
  content: "\EC38";
}

.nucleo-icon-delete-49::before {
  content: "\EC39";
}

.nucleo-icon-delete-50::before {
  content: "\EC3A";
}

.nucleo-icon-delivery-2::before {
  content: "\EC3B";
}

.nucleo-icon-delivery-fast::before {
  content: "\EC3C";
}

.nucleo-icon-delivery::before {
  content: "\EC3D";
}

.nucleo-icon-design-system::before {
  content: "\EC3E";
}

.nucleo-icon-design::before {
  content: "\EC3F";
}

.nucleo-icon-desk::before {
  content: "\EC40";
}

.nucleo-icon-desktop-screen::before {
  content: "\EC41";
}

.nucleo-icon-desktop::before {
  content: "\EC42";
}

.nucleo-icon-detox::before {
  content: "\EC43";
}

.nucleo-icon-device-connection::before {
  content: "\EC44";
}

.nucleo-icon-devil::before {
  content: "\EC45";
}

.nucleo-icon-diag-bottom-left::before {
  content: "\EC46";
}

.nucleo-icon-diag-bottom-right::before {
  content: "\EC47";
}

.nucleo-icon-diag-top-left::before {
  content: "\EC48";
}

.nucleo-icon-diag-top-right::before {
  content: "\EC49";
}

.nucleo-icon-diamond::before {
  content: "\EC4A";
}

.nucleo-icon-dice::before {
  content: "\EC4B";
}

.nucleo-icon-diet-food::before {
  content: "\EC4C";
}

.nucleo-icon-diet-plan::before {
  content: "\EC4D";
}

.nucleo-icon-diet::before {
  content: "\EC4E";
}

.nucleo-icon-direction-53::before {
  content: "\EC4F";
}

.nucleo-icon-direction-56::before {
  content: "\EC50";
}

.nucleo-icon-direction::before {
  content: "\EC51";
}

.nucleo-icon-directions::before {
  content: "\EC52";
}

.nucleo-icon-disabled::before {
  content: "\EC53";
}

.nucleo-icon-discord::before {
  content: "\EC55";
}

.nucleo-icon-discount-2::before {
  content: "\EC54";
}

.nucleo-icon-discount::before {
  content: "\EC56";
}

.nucleo-icon-dish::before {
  content: "\EC57";
}

.nucleo-icon-disk-2::before {
  content: "\EC58";
}

.nucleo-icon-disk-reader::before {
  content: "\EC59";
}

.nucleo-icon-disk::before {
  content: "\EC5A";
}

.nucleo-icon-disperse::before {
  content: "\EC5B";
}

.nucleo-icon-distance::before {
  content: "\EC5E";
}

.nucleo-icon-distribute-horizontal::before {
  content: "\EC5C";
}

.nucleo-icon-distribute-vertical::before {
  content: "\EC5D";
}

.nucleo-icon-divider::before {
  content: "\EC5F";
}

.nucleo-icon-dna-27::before {
  content: "\EC60";
}

.nucleo-icon-dna-38::before {
  content: "\EC61";
}

.nucleo-icon-dock-bottom-2::before {
  content: "\EC62";
}

.nucleo-icon-dock-bottom::before {
  content: "\EC63";
}

.nucleo-icon-dock-left-2::before {
  content: "\EC64";
}

.nucleo-icon-dock-left::before {
  content: "\EC65";
}

.nucleo-icon-dock-right-2::before {
  content: "\EC66";
}

.nucleo-icon-dock-right::before {
  content: "\EC67";
}

.nucleo-icon-dock-top-2::before {
  content: "\EC68";
}

.nucleo-icon-dock-top::before {
  content: "\EC69";
}

.nucleo-icon-dock::before {
  content: "\EC6A";
}

.nucleo-icon-dog-house::before {
  content: "\EC6B";
}

.nucleo-icon-dog::before {
  content: "\EC6C";
}

.nucleo-icon-donut::before {
  content: "\EC6D";
}

.nucleo-icon-door::before {
  content: "\EC6E";
}

.nucleo-icon-dots-05::before {
  content: "\EC6F";
}

.nucleo-icon-dots-06::before {
  content: "\EC71";
}

.nucleo-icon-dots-07::before {
  content: "\EC72";
}

.nucleo-icon-dots-download::before {
  content: "\EC70";
}

.nucleo-icon-dots-upload::before {
  content: "\EC73";
}

.nucleo-icon-dots::before {
  content: "\EC74";
}

.nucleo-icon-double-left::before {
  content: "\EC75";
}

.nucleo-icon-double-right::before {
  content: "\EC76";
}

.nucleo-icon-double-tap::before {
  content: "\EC77";
}

.nucleo-icon-download-2::before {
  content: "\EC78";
}

.nucleo-icon-download::before {
  content: "\EC79";
}

.nucleo-icon-drag-21::before {
  content: "\EC7A";
}

.nucleo-icon-drag-31::before {
  content: "\EC7B";
}

.nucleo-icon-drag-down::before {
  content: "\EC7C";
}

.nucleo-icon-drag-left::before {
  content: "\EC7D";
}

.nucleo-icon-drag-right::before {
  content: "\EC7E";
}

.nucleo-icon-drag-up::before {
  content: "\EC7F";
}

.nucleo-icon-drawer-2::before {
  content: "\EC80";
}

.nucleo-icon-drawer::before {
  content: "\EC81";
}

.nucleo-icon-dress-woman::before {
  content: "\EC82";
}

.nucleo-icon-drink-2::before {
  content: "\EC83";
}

.nucleo-icon-drink-list::before {
  content: "\EC85";
}

.nucleo-icon-drink::before {
  content: "\EC84";
}

.nucleo-icon-drinking-bottle::before {
  content: "\EC86";
}

.nucleo-icon-drop-15::before {
  content: "\EC87";
}

.nucleo-icon-drop::before {
  content: "\EC88";
}

.nucleo-icon-drops::before {
  content: "\EC89";
}

.nucleo-icon-dumbbells::before {
  content: "\EC8A";
}

.nucleo-icon-e-check::before {
  content: "\EC8B";
}

.nucleo-icon-eclipse::before {
  content: "\EC8C";
}

.nucleo-icon-edit-2::before {
  content: "\EC8D";
}

.nucleo-icon-edit-71::before {
  content: "\EC8E";
}

.nucleo-icon-edit-72::before {
  content: "\EC8F";
}

.nucleo-icon-edit-73::before {
  content: "\EC90";
}

.nucleo-icon-edit-74::before {
  content: "\EC91";
}

.nucleo-icon-edit-75::before {
  content: "\EC92";
}

.nucleo-icon-edit-76::before {
  content: "\EC93";
}

.nucleo-icon-edit-77::before {
  content: "\EC94";
}

.nucleo-icon-edit-78::before {
  content: "\EC95";
}

.nucleo-icon-edit-color::before {
  content: "\EC96";
}

.nucleo-icon-edit-contrast-42::before {
  content: "\EC97";
}

.nucleo-icon-edit-contrast-43::before {
  content: "\EC98";
}

.nucleo-icon-edit-saturation::before {
  content: "\EC99";
}

.nucleo-icon-egg-38::before {
  content: "\EC9A";
}

.nucleo-icon-egg-39::before {
  content: "\EC9B";
}

.nucleo-icon-egg::before {
  content: "\EC9C";
}

.nucleo-icon-eggs::before {
  content: "\EC9E";
}

.nucleo-icon-eject::before {
  content: "\EC9D";
}

.nucleo-icon-email-83::before {
  content: "\EC9F";
}

.nucleo-icon-email-84::before {
  content: "\ECA0";
}

.nucleo-icon-email-85::before {
  content: "\ECA1";
}

.nucleo-icon-energy-drink::before {
  content: "\ECA2";
}

.nucleo-icon-energy::before {
  content: "\ECA3";
}

.nucleo-icon-engine-start::before {
  content: "\ECA4";
}

.nucleo-icon-engine::before {
  content: "\ECA6";
}

.nucleo-icon-enlarge-21::before {
  content: "\ECA5";
}

.nucleo-icon-enlarge-22::before {
  content: "\ECA7";
}

.nucleo-icon-enlarge-45::before {
  content: "\ECA8";
}

.nucleo-icon-enlarge-46::before {
  content: "\ECA9";
}

.nucleo-icon-enlarge-57::before {
  content: "\ECAA";
}

.nucleo-icon-enlarge-58::before {
  content: "\ECAB";
}

.nucleo-icon-enlarge-circle::before {
  content: "\ECAC";
}

.nucleo-icon-enlarge-diagonal-43::before {
  content: "\ECAD";
}

.nucleo-icon-enlarge-diagonal-44::before {
  content: "\ECAE";
}

.nucleo-icon-enlarge-horizontal::before {
  content: "\ECAF";
}

.nucleo-icon-enlarge-vertical::before {
  content: "\ECB0";
}

.nucleo-icon-eraser-32::before {
  content: "\ECB1";
}

.nucleo-icon-eraser-33::before {
  content: "\ECB2";
}

.nucleo-icon-eraser-46::before {
  content: "\ECB3";
}

.nucleo-icon-event-confirm::before {
  content: "\ECB5";
}

.nucleo-icon-event-create::before {
  content: "\ECB6";
}

.nucleo-icon-expand::before {
  content: "\ECB4";
}

.nucleo-icon-explore-2::before {
  content: "\ECB7";
}

.nucleo-icon-explore::before {
  content: "\ECB8";
}

.nucleo-icon-export::before {
  content: "\ECB9";
}

.nucleo-icon-eye-17::before {
  content: "\ECBA";
}

.nucleo-icon-eye-19::before {
  content: "\ECBB";
}

.nucleo-icon-eye-ban-18::before {
  content: "\ECBC";
}

.nucleo-icon-eye-ban-20::before {
  content: "\ECBD";
}

.nucleo-icon-factory::before {
  content: "\ECBE";
}

.nucleo-icon-fahrenheit::before {
  content: "\ECBF";
}

.nucleo-icon-fav-remove::before {
  content: "\ECC0";
}

.nucleo-icon-favourite-28::before {
  content: "\ECC2";
}

.nucleo-icon-favourite-31::before {
  content: "\ECC1";
}

.nucleo-icon-favourite-add-29::before {
  content: "\ECC3";
}

.nucleo-icon-favourite-add-32::before {
  content: "\ECC4";
}

.nucleo-icon-favourite-remove-30::before {
  content: "\ECC5";
}

.nucleo-icon-favourite-remove-33::before {
  content: "\ECC6";
}

.nucleo-icon-fencing::before {
  content: "\ECC7";
}

.nucleo-icon-file-add::before {
  content: "\ECC8";
}

.nucleo-icon-file-delete::before {
  content: "\ECC9";
}

.nucleo-icon-file-download-87::before {
  content: "\ECCA";
}

.nucleo-icon-file-upload-86::before {
  content: "\ECCB";
}

.nucleo-icon-filter-check::before {
  content: "\ECCC";
}

.nucleo-icon-filter-organization::before {
  content: "\ECCD";
}

.nucleo-icon-filter-remove::before {
  content: "\ECCE";
}

.nucleo-icon-filter::before {
  content: "\ECCF";
}

.nucleo-icon-fire::before {
  content: "\ECD0";
}

.nucleo-icon-firewall::before {
  content: "\ECD1";
}

.nucleo-icon-fish::before {
  content: "\ECD2";
}

.nucleo-icon-fishing::before {
  content: "\ECD3";
}

.nucleo-icon-fist::before {
  content: "\ECD4";
}

.nucleo-icon-flag-diagonal-33::before {
  content: "\ECD5";
}

.nucleo-icon-flag-diagonal-34::before {
  content: "\ECD6";
}

.nucleo-icon-flag-e-06::before {
  content: "\ECD7";
}

.nucleo-icon-flag-e-07::before {
  content: "\ECD8";
}

.nucleo-icon-flag-finish::before {
  content: "\ECD9";
}

.nucleo-icon-flag-points-31::before {
  content: "\ECDA";
}

.nucleo-icon-flag-points-32::before {
  content: "\ECDB";
}

.nucleo-icon-flag-simple::before {
  content: "\ECDC";
}

.nucleo-icon-flag-triangle::before {
  content: "\ECDD";
}

.nucleo-icon-flag::before {
  content: "\ECDE";
}

.nucleo-icon-flame::before {
  content: "\ECDF";
}

.nucleo-icon-flash-21::before {
  content: "\ECE0";
}

.nucleo-icon-flash-24::before {
  content: "\ECE3";
}

.nucleo-icon-flash-29::before {
  content: "\ECE2";
}

.nucleo-icon-flask-2::before {
  content: "\ECE1";
}

.nucleo-icon-flask::before {
  content: "\ECE4";
}

.nucleo-icon-flip-horizontal::before {
  content: "\ECE5";
}

.nucleo-icon-flip-vertical::before {
  content: "\ECE6";
}

.nucleo-icon-flip::before {
  content: "\ECE7";
}

.nucleo-icon-flower-05::before {
  content: "\ECE8";
}

.nucleo-icon-flower-06::before {
  content: "\ECE9";
}

.nucleo-icon-flower-07::before {
  content: "\ECEA";
}

.nucleo-icon-focus-32::before {
  content: "\ECEB";
}

.nucleo-icon-focus-38::before {
  content: "\ECEC";
}

.nucleo-icon-focus-40::before {
  content: "\ECED";
}

.nucleo-icon-focus-circle::before {
  content: "\ECEE";
}

.nucleo-icon-focus::before {
  content: "\ECEF";
}

.nucleo-icon-fog::before {
  content: "\ECF0";
}

.nucleo-icon-folder-13::before {
  content: "\ECF1";
}

.nucleo-icon-folder-14::before {
  content: "\ECF2";
}

.nucleo-icon-folder-15::before {
  content: "\ECF3";
}

.nucleo-icon-folder-16::before {
  content: "\ECF4";
}

.nucleo-icon-folder-17::before {
  content: "\ECF5";
}

.nucleo-icon-folder-18::before {
  content: "\ECF6";
}

.nucleo-icon-folder-add-e-2::before {
  content: "\ECF7";
}

.nucleo-icon-folder-add::before {
  content: "\ECF8";
}

.nucleo-icon-folder-e-02::before {
  content: "\ECF9";
}

.nucleo-icon-folder-e-03::before {
  content: "\ECFA";
}

.nucleo-icon-folder-remove-e-2::before {
  content: "\ECFB";
}

.nucleo-icon-folder-remove::before {
  content: "\ECFC";
}

.nucleo-icon-food-course::before {
  content: "\ECFD";
}

.nucleo-icon-food-dog::before {
  content: "\ECFE";
}

.nucleo-icon-food-scale::before {
  content: "\ED00";
}

.nucleo-icon-football-headguard::before {
  content: "\ECFF";
}

.nucleo-icon-forecast::before {
  content: "\ED01";
}

.nucleo-icon-form::before {
  content: "\ED02";
}

.nucleo-icon-format-left::before {
  content: "\ED03";
}

.nucleo-icon-format-right::before {
  content: "\ED04";
}

.nucleo-icon-forward::before {
  content: "\ED05";
}

.nucleo-icon-frame-12::before {
  content: "\ED06";
}

.nucleo-icon-frame-41::before {
  content: "\ED07";
}

.nucleo-icon-frame::before {
  content: "\ED08";
}

.nucleo-icon-fridge::before {
  content: "\ED09";
}

.nucleo-icon-fuel-2::before {
  content: "\ED0A";
}

.nucleo-icon-fuel-electric::before {
  content: "\ED0B";
}

.nucleo-icon-fuel::before {
  content: "\ED0C";
}

.nucleo-icon-full-screen::before {
  content: "\ED0D";
}

.nucleo-icon-fullscreen-70::before {
  content: "\ED0E";
}

.nucleo-icon-fullscreen-71::before {
  content: "\ED0F";
}

.nucleo-icon-fullscreen-76::before {
  content: "\ED10";
}

.nucleo-icon-fullscreen-77::before {
  content: "\ED11";
}

.nucleo-icon-fullscreen-double-74::before {
  content: "\ED12";
}

.nucleo-icon-fullscreen-double-75::before {
  content: "\ED13";
}

.nucleo-icon-fullscreen-split-72::before {
  content: "\ED14";
}

.nucleo-icon-fullscreen-split-73::before {
  content: "\ED15";
}

.nucleo-icon-fullsize::before {
  content: "\ED16";
}

.nucleo-icon-funnel-39::before {
  content: "\ED17";
}

.nucleo-icon-funnel-40::before {
  content: "\ED18";
}

.nucleo-icon-funnel-41::before {
  content: "\ED19";
}

.nucleo-icon-gantt::before {
  content: "\ED1A";
}

.nucleo-icon-garlic::before {
  content: "\ED1B";
}

.nucleo-icon-ghost-2::before {
  content: "\ED1C";
}

.nucleo-icon-ghost-e::before {
  content: "\ED1D";
}

.nucleo-icon-ghost::before {
  content: "\ED1E";
}

.nucleo-icon-gift-2::before {
  content: "\ED1F";
}

.nucleo-icon-gift::before {
  content: "\ED20";
}

.nucleo-icon-git-commit::before {
  content: "\ED21";
}

.nucleo-icon-glass-water::before {
  content: "\ED22";
}

.nucleo-icon-glass::before {
  content: "\ED23";
}

.nucleo-icon-glasses-2::before {
  content: "\ED24";
}

.nucleo-icon-glasses::before {
  content: "\ED25";
}

.nucleo-icon-globe-2::before {
  content: "\ED26";
}

.nucleo-icon-globe::before {
  content: "\ED27";
}

.nucleo-icon-glove::before {
  content: "\ED28";
}

.nucleo-icon-goal-64::before {
  content: "\ED29";
}

.nucleo-icon-goal-65::before {
  content: "\ED2A";
}

.nucleo-icon-golf-ball::before {
  content: "\ED2B";
}

.nucleo-icon-golf-course::before {
  content: "\ED2C";
}

.nucleo-icon-golf-strike::before {
  content: "\ED2E";
}

.nucleo-icon-golf::before {
  content: "\ED2D";
}

.nucleo-icon-gps-e::before {
  content: "\ED2F";
}

.nucleo-icon-gps::before {
  content: "\ED30";
}

.nucleo-icon-grab::before {
  content: "\ED31";
}

.nucleo-icon-gradient::before {
  content: "\ED32";
}

.nucleo-icon-grain::before {
  content: "\ED34";
}

.nucleo-icon-grape::before {
  content: "\ED33";
}

.nucleo-icon-grave::before {
  content: "\ED35";
}

.nucleo-icon-grid-45::before {
  content: "\ED36";
}

.nucleo-icon-grid-46::before {
  content: "\ED37";
}

.nucleo-icon-grid-48::before {
  content: "\ED38";
}

.nucleo-icon-grid-50::before {
  content: "\ED39";
}

.nucleo-icon-grid-square::before {
  content: "\ED3A";
}

.nucleo-icon-grid-system::before {
  content: "\ED3C";
}

.nucleo-icon-grid::before {
  content: "\ED3B";
}

.nucleo-icon-group::before {
  content: "\ED3D";
}

.nucleo-icon-hammer::before {
  content: "\ED3E";
}

.nucleo-icon-handout::before {
  content: "\ED3F";
}

.nucleo-icon-handshake::before {
  content: "\ED40";
}

.nucleo-icon-hanger::before {
  content: "\ED41";
}

.nucleo-icon-happy-sun::before {
  content: "\ED42";
}

.nucleo-icon-hat-2::before {
  content: "\ED43";
}

.nucleo-icon-hat-3::before {
  content: "\ED44";
}

.nucleo-icon-hat-top::before {
  content: "\ED45";
}

.nucleo-icon-hat::before {
  content: "\ED46";
}

.nucleo-icon-hazelnut::before {
  content: "\ED48";
}

.nucleo-icon-hdmi::before {
  content: "\ED47";
}

.nucleo-icon-headphone::before {
  content: "\ED49";
}

.nucleo-icon-headphones-2::before {
  content: "\ED4A";
}

.nucleo-icon-headphones-mic::before {
  content: "\ED4B";
}

.nucleo-icon-headphones::before {
  content: "\ED4C";
}

.nucleo-icon-heart-2::before {
  content: "\ED4D";
}

.nucleo-icon-heart-add::before {
  content: "\ED4E";
}

.nucleo-icon-heart-remove::before {
  content: "\ED4F";
}

.nucleo-icon-heartbeat::before {
  content: "\ED50";
}

.nucleo-icon-height::before {
  content: "\ED51";
}

.nucleo-icon-helicopter::before {
  content: "\ED52";
}

.nucleo-icon-helmet-2::before {
  content: "\ED53";
}

.nucleo-icon-helmet::before {
  content: "\ED54";
}

.nucleo-icon-hierarchy-53::before {
  content: "\ED55";
}

.nucleo-icon-hit-down::before {
  content: "\ED56";
}

.nucleo-icon-hit-left::before {
  content: "\ED57";
}

.nucleo-icon-hit-right::before {
  content: "\ED58";
}

.nucleo-icon-hit-up::before {
  content: "\ED59";
}

.nucleo-icon-hob::before {
  content: "\ED5A";
}

.nucleo-icon-hockey::before {
  content: "\ED5B";
}

.nucleo-icon-home-2::before {
  content: "\ED5C";
}

.nucleo-icon-home-51::before {
  content: "\ED5D";
}

.nucleo-icon-home-52::before {
  content: "\ED5E";
}

.nucleo-icon-home-minimal::before {
  content: "\ED5F";
}

.nucleo-icon-home-simple::before {
  content: "\ED60";
}

.nucleo-icon-home::before {
  content: "\ED61";
}

.nucleo-icon-honey::before {
  content: "\ED62";
}

.nucleo-icon-horse::before {
  content: "\ED64";
}

.nucleo-icon-hospital-32::before {
  content: "\ED63";
}

.nucleo-icon-hospital-33::before {
  content: "\ED65";
}

.nucleo-icon-hospital-34::before {
  content: "\ED66";
}

.nucleo-icon-hot-dog::before {
  content: "\ED67";
}

.nucleo-icon-hot-key::before {
  content: "\ED68";
}

.nucleo-icon-hotel-bell::before {
  content: "\ED69";
}

.nucleo-icon-hotel-symbol::before {
  content: "\ED6A";
}

.nucleo-icon-hotel::before {
  content: "\ED6B";
}

.nucleo-icon-hourglass::before {
  content: "\ED6C";
}

.nucleo-icon-html5::before {
  content: "\ED6D";
}

.nucleo-icon-humidity-26::before {
  content: "\ED6E";
}

.nucleo-icon-humidity-52::before {
  content: "\ED6F";
}

.nucleo-icon-hurricane-44::before {
  content: "\ED70";
}

.nucleo-icon-hurricane-45::before {
  content: "\ED71";
}

.nucleo-icon-hut::before {
  content: "\ED72";
}

.nucleo-icon-hybrid::before {
  content: "\ED74";
}

.nucleo-icon-ice-cream-22::before {
  content: "\ED73";
}

.nucleo-icon-ice-cream-72::before {
  content: "\ED75";
}

.nucleo-icon-ice-cream::before {
  content: "\ED76";
}

.nucleo-icon-ice-skates::before {
  content: "\ED78";
}

.nucleo-icon-igloo::before {
  content: "\ED77";
}

.nucleo-icon-image-01::before {
  content: "\ED79";
}

.nucleo-icon-image-02::before {
  content: "\ED7A";
}

.nucleo-icon-image-05::before {
  content: "\ED7B";
}

.nucleo-icon-image-2::before {
  content: "\ED7C";
}

.nucleo-icon-image-add::before {
  content: "\ED7D";
}

.nucleo-icon-image-delete::before {
  content: "\ED7E";
}

.nucleo-icon-image::before {
  content: "\ED7F";
}

.nucleo-icon-img-stack::before {
  content: "\ED81";
}

.nucleo-icon-infinite::before {
  content: "\ED80";
}

.nucleo-icon-input-12::before {
  content: "\ED82";
}

.nucleo-icon-input-21::before {
  content: "\ED83";
}

.nucleo-icon-invert::before {
  content: "\ED84";
}

.nucleo-icon-iron-2::before {
  content: "\ED85";
}

.nucleo-icon-iron::before {
  content: "\ED86";
}

.nucleo-icon-italic::before {
  content: "\ED87";
}

.nucleo-icon-jeans-41::before {
  content: "\ED88";
}

.nucleo-icon-jeans-pocket::before {
  content: "\ED89";
}

.nucleo-icon-jelly::before {
  content: "\ED8A";
}

.nucleo-icon-jellyfish::before {
  content: "\ED8B";
}

.nucleo-icon-journey-04::before {
  content: "\ED8C";
}

.nucleo-icon-journey-05::before {
  content: "\ED8D";
}

.nucleo-icon-journey-06::before {
  content: "\ED8E";
}

.nucleo-icon-journey-07::before {
  content: "\ED8F";
}

.nucleo-icon-journey-08::before {
  content: "\ED90";
}

.nucleo-icon-juice::before {
  content: "\ED91";
}

.nucleo-icon-ketchup::before {
  content: "\ED92";
}

.nucleo-icon-kettle::before {
  content: "\ED93";
}

.nucleo-icon-kettlebell::before {
  content: "\ED94";
}

.nucleo-icon-key-25::before {
  content: "\ED95";
}

.nucleo-icon-key-26::before {
  content: "\ED96";
}

.nucleo-icon-keyboard-hide::before {
  content: "\ED97";
}

.nucleo-icon-keyboard-wifi::before {
  content: "\ED98";
}

.nucleo-icon-keyboard-wireless::before {
  content: "\ED99";
}

.nucleo-icon-keyboard::before {
  content: "\ED9A";
}

.nucleo-icon-kiss-e::before {
  content: "\ED9B";
}

.nucleo-icon-kiss::before {
  content: "\ED9C";
}

.nucleo-icon-kiwi::before {
  content: "\ED9E";
}

.nucleo-icon-knife::before {
  content: "\ED9D";
}

.nucleo-icon-knob::before {
  content: "\ED9F";
}

.nucleo-icon-l-add::before {
  content: "\EDA0";
}

.nucleo-icon-l-check::before {
  content: "\EDA1";
}

.nucleo-icon-l-circle::before {
  content: "\EDA2";
}

.nucleo-icon-l-circles::before {
  content: "\EDA3";
}

.nucleo-icon-l-remove::before {
  content: "\EDA4";
}

.nucleo-icon-l-system-update::before {
  content: "\EDA5";
}

.nucleo-icon-lab::before {
  content: "\EDA6";
}

.nucleo-icon-lamp-2::before {
  content: "\EDA7";
}

.nucleo-icon-lamp-3::before {
  content: "\EDA8";
}

.nucleo-icon-lamp-floor::before {
  content: "\EDA9";
}

.nucleo-icon-lamp::before {
  content: "\EDAA";
}

.nucleo-icon-language::before {
  content: "\EDAB";
}

.nucleo-icon-laptop-1::before {
  content: "\EDAC";
}

.nucleo-icon-laptop-2::before {
  content: "\EDAD";
}

.nucleo-icon-laptop::before {
  content: "\EDAE";
}

.nucleo-icon-laugh-35::before {
  content: "\EDAF";
}

.nucleo-icon-laugh-e::before {
  content: "\EDB0";
}

.nucleo-icon-launch-11::before {
  content: "\EDB1";
}

.nucleo-icon-launch-47::before {
  content: "\EDB2";
}

.nucleo-icon-layers-2::before {
  content: "\EDB3";
}

.nucleo-icon-layers-3::before {
  content: "\EDB4";
}

.nucleo-icon-layers::before {
  content: "\EDB5";
}

.nucleo-icon-layout-11::before {
  content: "\EDB6";
}

.nucleo-icon-layout-25::before {
  content: "\EDB7";
}

.nucleo-icon-leaf-36::before {
  content: "\EDB8";
}

.nucleo-icon-leaf-80::before {
  content: "\EDB9";
}

.nucleo-icon-leaf-81::before {
  content: "\EDBA";
}

.nucleo-icon-leaf-edit::before {
  content: "\EDBB";
}

.nucleo-icon-leggins::before {
  content: "\EDBD";
}

.nucleo-icon-lemon::before {
  content: "\EDC5";
}

.nucleo-icon-lens-31::before {
  content: "\EDBC";
}

.nucleo-icon-lens-56::before {
  content: "\EDBE";
}

.nucleo-icon-library::before {
  content: "\EDBF";
}

.nucleo-icon-light-2::before {
  content: "\EDC0";
}

.nucleo-icon-light-3::before {
  content: "\EDC1";
}

.nucleo-icon-light-traffic::before {
  content: "\EDC2";
}

.nucleo-icon-light::before {
  content: "\EDC3";
}

.nucleo-icon-lighter::before {
  content: "\EDC4";
}

.nucleo-icon-like-2::before {
  content: "\EDC6";
}

.nucleo-icon-like-no::before {
  content: "\EDC7";
}

.nucleo-icon-like::before {
  content: "\EDC8";
}

.nucleo-icon-line-height::before {
  content: "\EDC9";
}

.nucleo-icon-link-2::before {
  content: "\EDCA";
}

.nucleo-icon-link-66::before {
  content: "\EDCB";
}

.nucleo-icon-link-67::before {
  content: "\EDCC";
}

.nucleo-icon-link-68::before {
  content: "\EDCD";
}

.nucleo-icon-link-69::before {
  content: "\EDCE";
}

.nucleo-icon-link-72::before {
  content: "\EDCF";
}

.nucleo-icon-link-broken-70::before {
  content: "\EDD0";
}

.nucleo-icon-link-broken-73::before {
  content: "\EDD1";
}

.nucleo-icon-linux::before {
  content: "\EDD3";
}

.nucleo-icon-list-bullet::before {
  content: "\EDD2";
}

.nucleo-icon-list-numbers::before {
  content: "\EDD4";
}

.nucleo-icon-list::before {
  content: "\EDD5";
}

.nucleo-icon-lock-circle-open::before {
  content: "\EDD6";
}

.nucleo-icon-lock-circle::before {
  content: "\EDD7";
}

.nucleo-icon-lock-landscape::before {
  content: "\EDD8";
}

.nucleo-icon-lock-open::before {
  content: "\EDD9";
}

.nucleo-icon-lock-portrait::before {
  content: "\EDDA";
}

.nucleo-icon-lock::before {
  content: "\EDDB";
}

.nucleo-icon-log-in::before {
  content: "\EDDC";
}

.nucleo-icon-log-out-2::before {
  content: "\EDDD";
}

.nucleo-icon-log-out::before {
  content: "\EDDE";
}

.nucleo-icon-logo-500px::before {
  content: "\EDDF";
}

.nucleo-icon-logo-angellist::before {
  content: "\EDE0";
}

.nucleo-icon-logo-behance::before {
  content: "\EDE1";
}

.nucleo-icon-logo-blogger::before {
  content: "\EDE2";
}

.nucleo-icon-logo-buffer::before {
  content: "\EDE3";
}

.nucleo-icon-logo-buysellads::before {
  content: "\EDE4";
}

.nucleo-icon-logo-codepen::before {
  content: "\EDE5";
}

.nucleo-icon-logo-creative-market::before {
  content: "\EDE6";
}

.nucleo-icon-logo-crunchbase::before {
  content: "\EDE7";
}

.nucleo-icon-logo-deviantart::before {
  content: "\EDE8";
}

.nucleo-icon-logo-dribbble::before {
  content: "\EDE9";
}

.nucleo-icon-logo-dropbox::before {
  content: "\EDEA";
}

.nucleo-icon-logo-envato::before {
  content: "\EDEB";
}

.nucleo-icon-logo-evernote::before {
  content: "\EDEC";
}

.nucleo-icon-logo-facebook::before {
  content: "\EDED";
}

.nucleo-icon-logo-fb-simple::before {
  content: "\EDEE";
}

.nucleo-icon-logo-feedly::before {
  content: "\EDEF";
}

.nucleo-icon-logo-flickr::before {
  content: "\EDF0";
}

.nucleo-icon-logo-github::before {
  content: "\EDF1";
}

.nucleo-icon-logo-google-plus::before {
  content: "\EDF2";
}

.nucleo-icon-logo-instagram::before {
  content: "\EDF3";
}

.nucleo-icon-logo-lastfm::before {
  content: "\EDF4";
}

.nucleo-icon-logo-linkedin::before {
  content: "\EDF5";
}

.nucleo-icon-logo-medium::before {
  content: "\EDF6";
}

.nucleo-icon-logo-meetup::before {
  content: "\EDF7";
}

.nucleo-icon-logo-messenger::before {
  content: "\EDF8";
}

.nucleo-icon-logo-mixer::before {
  content: "\EDFE";
}

.nucleo-icon-logo-myspace::before {
  content: "\EDF9";
}

.nucleo-icon-logo-paypal::before {
  content: "\EDFA";
}

.nucleo-icon-logo-pinterest::before {
  content: "\EDFB";
}

.nucleo-icon-logo-product-hunt::before {
  content: "\EDFC";
}

.nucleo-icon-logo-qq::before {
  content: "\EDFD";
}

.nucleo-icon-logo-reddit::before {
  content: "\EDFF";
}

.nucleo-icon-logo-rss::before {
  content: "\EE00";
}

.nucleo-icon-logo-shopify::before {
  content: "\EE01";
}

.nucleo-icon-logo-skype::before {
  content: "\EE02";
}

.nucleo-icon-logo-slack::before {
  content: "\EE03";
}

.nucleo-icon-logo-soundcloud::before {
  content: "\EE04";
}

.nucleo-icon-logo-spotify::before {
  content: "\EE05";
}

.nucleo-icon-logo-squarespace::before {
  content: "\EE06";
}

.nucleo-icon-logo-trello::before {
  content: "\EE07";
}

.nucleo-icon-logo-tumblr::before {
  content: "\EE08";
}

.nucleo-icon-logo-twitter::before {
  content: "\EE09";
}

.nucleo-icon-logo-vimeo::before {
  content: "\EE0A";
}

.nucleo-icon-logo-vine::before {
  content: "\EE0B";
}

.nucleo-icon-logo-vk::before {
  content: "\EE0C";
}

.nucleo-icon-logo-wechat::before {
  content: "\EE0D";
}

.nucleo-icon-logo-weibo::before {
  content: "\EE0E";
}

.nucleo-icon-logo-whatsapp::before {
  content: "\EE0F";
}

.nucleo-icon-logo-wikipedia::before {
  content: "\EE10";
}

.nucleo-icon-logo-wordpress::before {
  content: "\EE11";
}

.nucleo-icon-logo-yelp::before {
  content: "\EE12";
}

.nucleo-icon-logo-youtube::before {
  content: "\EE13";
}

.nucleo-icon-logout::before {
  content: "\EE14";
}

.nucleo-icon-lollipop::before {
  content: "\EE2F";
}

.nucleo-icon-long-sleeve::before {
  content: "\EE15";
}

.nucleo-icon-loop-30::before {
  content: "\EE16";
}

.nucleo-icon-loop-34::before {
  content: "\EE17";
}

.nucleo-icon-loop-82::before {
  content: "\EE18";
}

.nucleo-icon-love-card::before {
  content: "\EE19";
}

.nucleo-icon-luggage::before {
  content: "\EE1A";
}

.nucleo-icon-m-add::before {
  content: "\EE1B";
}

.nucleo-icon-m-check::before {
  content: "\EE1C";
}

.nucleo-icon-m-edit::before {
  content: "\EE1D";
}

.nucleo-icon-m-heart::before {
  content: "\EE1F";
}

.nucleo-icon-m-remove::before {
  content: "\EE1E";
}

.nucleo-icon-m-security::before {
  content: "\EE20";
}

.nucleo-icon-m-update::before {
  content: "\EE30";
}

.nucleo-icon-macro::before {
  content: "\EE21";
}

.nucleo-icon-magnet::before {
  content: "\EE22";
}

.nucleo-icon-makeup::before {
  content: "\EE23";
}

.nucleo-icon-man-20::before {
  content: "\EE24";
}

.nucleo-icon-man::before {
  content: "\EE25";
}

.nucleo-icon-manga-62::before {
  content: "\EE26";
}

.nucleo-icon-manga-63::before {
  content: "\EE27";
}

.nucleo-icon-map::before {
  content: "\EE28";
}

.nucleo-icon-margin-left::before {
  content: "\EE29";
}

.nucleo-icon-margin-right::before {
  content: "\EE2A";
}

.nucleo-icon-marker-2::before {
  content: "\EE31";
}

.nucleo-icon-marker-3::before {
  content: "\EE2B";
}

.nucleo-icon-marker::before {
  content: "\EE2C";
}

.nucleo-icon-market-music::before {
  content: "\EE2D";
}

.nucleo-icon-market-play::before {
  content: "\EE2E";
}

.nucleo-icon-mask-oval::before {
  content: "\EE32";
}

.nucleo-icon-mask-rect::before {
  content: "\EE39";
}

.nucleo-icon-matches::before {
  content: "\EE33";
}

.nucleo-icon-math::before {
  content: "\EE34";
}

.nucleo-icon-mayo::before {
  content: "\EE3A";
}

.nucleo-icon-measure-02::before {
  content: "\EE35";
}

.nucleo-icon-measure-17::before {
  content: "\EE36";
}

.nucleo-icon-measure-big::before {
  content: "\EE37";
}

.nucleo-icon-measuring-cup::before {
  content: "\EE38";
}

.nucleo-icon-menu-34::before {
  content: "\EE3B";
}

.nucleo-icon-menu-35::before {
  content: "\EE3C";
}

.nucleo-icon-menu-bold::before {
  content: "\EE3D";
}

.nucleo-icon-menu-dots::before {
  content: "\EE3E";
}

.nucleo-icon-menu-left::before {
  content: "\EE3F";
}

.nucleo-icon-menu-right::before {
  content: "\EE40";
}

.nucleo-icon-menu-square::before {
  content: "\EE41";
}

.nucleo-icon-menu::before {
  content: "\EE42";
}

.nucleo-icon-merge::before {
  content: "\EE43";
}

.nucleo-icon-metrics::before {
  content: "\EE44";
}

.nucleo-icon-mic-2::before {
  content: "\EE45";
}

.nucleo-icon-mic::before {
  content: "\EE46";
}

.nucleo-icon-mickey-mouse::before {
  content: "\EE47";
}

.nucleo-icon-microwave::before {
  content: "\EE48";
}

.nucleo-icon-milk::before {
  content: "\EE49";
}

.nucleo-icon-minimal-down::before {
  content: "\EE4A";
}

.nucleo-icon-minimal-left::before {
  content: "\EE4B";
}

.nucleo-icon-minimal-right::before {
  content: "\EE4C";
}

.nucleo-icon-minimal-up::before {
  content: "\EE4D";
}

.nucleo-icon-mirror-2::before {
  content: "\EE4E";
}

.nucleo-icon-mirror::before {
  content: "\EE4F";
}

.nucleo-icon-mixer::before {
  content: "\EE50";
}

.nucleo-icon-mobile-button::before {
  content: "\EE51";
}

.nucleo-icon-mobile-camera::before {
  content: "\EE52";
}

.nucleo-icon-mobile-landscape::before {
  content: "\EE53";
}

.nucleo-icon-mobile-recharger-08::before {
  content: "\EE54";
}

.nucleo-icon-mobile-recharger-09::before {
  content: "\EE55";
}

.nucleo-icon-mobile-toolbar::before {
  content: "\EE56";
}

.nucleo-icon-mobile::before {
  content: "\EE57";
}

.nucleo-icon-moka::before {
  content: "\EE58";
}

.nucleo-icon-molecule-39::before {
  content: "\EE59";
}

.nucleo-icon-molecule::before {
  content: "\EE5A";
}

.nucleo-icon-money-11::before {
  content: "\EE5B";
}

.nucleo-icon-money-12::before {
  content: "\EE5C";
}

.nucleo-icon-money-13::before {
  content: "\EE5D";
}

.nucleo-icon-money-bag::before {
  content: "\EE5E";
}

.nucleo-icon-monster-e::before {
  content: "\EE5F";
}

.nucleo-icon-monster::before {
  content: "\EE60";
}

.nucleo-icon-moon-cloud-drop::before {
  content: "\EE61";
}

.nucleo-icon-moon-cloud-fog::before {
  content: "\EE62";
}

.nucleo-icon-moon-cloud-hail::before {
  content: "\EE63";
}

.nucleo-icon-moon-cloud-light::before {
  content: "\EE64";
}

.nucleo-icon-moon-cloud-rain::before {
  content: "\EE65";
}

.nucleo-icon-moon-cloud-snow-61::before {
  content: "\EE66";
}

.nucleo-icon-moon-cloud-snow-62::before {
  content: "\EE67";
}

.nucleo-icon-moon-fog::before {
  content: "\EE68";
}

.nucleo-icon-moon-full::before {
  content: "\EE69";
}

.nucleo-icon-moon-stars::before {
  content: "\EE6A";
}

.nucleo-icon-moon::before {
  content: "\EE6B";
}

.nucleo-icon-mortar::before {
  content: "\EE6C";
}

.nucleo-icon-moto::before {
  content: "\EE6D";
}

.nucleo-icon-mountain::before {
  content: "\EE6E";
}

.nucleo-icon-mouse-08::before {
  content: "\EE6F";
}

.nucleo-icon-mouse-09::before {
  content: "\EE70";
}

.nucleo-icon-mouse-10::before {
  content: "\EE71";
}

.nucleo-icon-mouse::before {
  content: "\EE97";
}

.nucleo-icon-move-bottom-right::before {
  content: "\EE72";
}

.nucleo-icon-move-down-2::before {
  content: "\EE85";
}

.nucleo-icon-move-down-right::before {
  content: "\EE73";
}

.nucleo-icon-move-down::before {
  content: "\EE74";
}

.nucleo-icon-move-left::before {
  content: "\EE75";
}

.nucleo-icon-move-right::before {
  content: "\EE76";
}

.nucleo-icon-move-top-left::before {
  content: "\EE77";
}

.nucleo-icon-move-up-2::before {
  content: "\EE86";
}

.nucleo-icon-move-up-left::before {
  content: "\EE78";
}

.nucleo-icon-move-up::before {
  content: "\EE79";
}

.nucleo-icon-movie-61::before {
  content: "\EE7A";
}

.nucleo-icon-movie-62::before {
  content: "\EE7B";
}

.nucleo-icon-mower::before {
  content: "\EE7C";
}

.nucleo-icon-muffin::before {
  content: "\EE7D";
}

.nucleo-icon-mug::before {
  content: "\EE7E";
}

.nucleo-icon-multiple-11::before {
  content: "\EE7F";
}

.nucleo-icon-multiple-19::before {
  content: "\EE80";
}

.nucleo-icon-multiple::before {
  content: "\EE81";
}

.nucleo-icon-mushroom::before {
  content: "\EE82";
}

.nucleo-icon-music-2::before {
  content: "\EE83";
}

.nucleo-icon-music-album::before {
  content: "\EE84";
}

.nucleo-icon-name-card::before {
  content: "\EE87";
}

.nucleo-icon-navigation::before {
  content: "\EE88";
}

.nucleo-icon-nerd-22::before {
  content: "\EE89";
}

.nucleo-icon-net::before {
  content: "\EE8A";
}

.nucleo-icon-newsletter::before {
  content: "\EE8B";
}

.nucleo-icon-night::before {
  content: "\EE8C";
}

.nucleo-icon-node::before {
  content: "\EE8D";
}

.nucleo-icon-noodles::before {
  content: "\EE98";
}

.nucleo-icon-note-03::before {
  content: "\EE8E";
}

.nucleo-icon-note-04::before {
  content: "\EE8F";
}

.nucleo-icon-note-code::before {
  content: "\EE90";
}

.nucleo-icon-notebook-2::before {
  content: "\EE91";
}

.nucleo-icon-notebook::before {
  content: "\EE92";
}

.nucleo-icon-notepad::before {
  content: "\EE93";
}

.nucleo-icon-notes::before {
  content: "\EE94";
}

.nucleo-icon-notification-69::before {
  content: "\EE95";
}

.nucleo-icon-nutrition::before {
  content: "\EE9E";
}

.nucleo-icon-octopus::before {
  content: "\EE96";
}

.nucleo-icon-oil-2::before {
  content: "\EE99";
}

.nucleo-icon-oil::before {
  content: "\EE9B";
}

.nucleo-icon-olympic-flame::before {
  content: "\EE9A";
}

.nucleo-icon-onenote::before {
  content: "\EE9F";
}

.nucleo-icon-onion::before {
  content: "\EE9C";
}

.nucleo-icon-open-in-browser::before {
  content: "\EE9D";
}

.nucleo-icon-opening-times::before {
  content: "\EEA0";
}

.nucleo-icon-orange::before {
  content: "\EEAB";
}

.nucleo-icon-organic-2::before {
  content: "\EEA1";
}

.nucleo-icon-organic::before {
  content: "\EEA2";
}

.nucleo-icon-oven::before {
  content: "\EEA3";
}

.nucleo-icon-owl::before {
  content: "\EEA4";
}

.nucleo-icon-p-add::before {
  content: "\EEA5";
}

.nucleo-icon-p-check::before {
  content: "\EEA6";
}

.nucleo-icon-p-edit::before {
  content: "\EEA7";
}

.nucleo-icon-p-heart::before {
  content: "\EEA8";
}

.nucleo-icon-p-remove::before {
  content: "\EEA9";
}

.nucleo-icon-p-system-update::before {
  content: "\EEAA";
}

.nucleo-icon-paint-16::before {
  content: "\EEAC";
}

.nucleo-icon-paint-37::before {
  content: "\EEAD";
}

.nucleo-icon-paint-38::before {
  content: "\EEAE";
}

.nucleo-icon-paint-brush::before {
  content: "\EEAF";
}

.nucleo-icon-paint-bucket-39::before {
  content: "\EEB0";
}

.nucleo-icon-paint-bucket-40::before {
  content: "\EEB1";
}

.nucleo-icon-palette::before {
  content: "\EEB2";
}

.nucleo-icon-pan::before {
  content: "\EEB3";
}

.nucleo-icon-pancake::before {
  content: "\EEBA";
}

.nucleo-icon-panda::before {
  content: "\EEB4";
}

.nucleo-icon-panel::before {
  content: "\EEB5";
}

.nucleo-icon-pantone::before {
  content: "\EEB6";
}

.nucleo-icon-paper-2::before {
  content: "\EEBB";
}

.nucleo-icon-paper-diploma::before {
  content: "\EEB7";
}

.nucleo-icon-paper::before {
  content: "\EEB8";
}

.nucleo-icon-paragraph::before {
  content: "\EEB9";
}

.nucleo-icon-paralympic-games::before {
  content: "\EEC7";
}

.nucleo-icon-parking-sensors::before {
  content: "\EEBC";
}

.nucleo-icon-parking::before {
  content: "\EEC8";
}

.nucleo-icon-passport::before {
  content: "\EEBD";
}

.nucleo-icon-pasta::before {
  content: "\EED3";
}

.nucleo-icon-patch-19::before {
  content: "\EEBE";
}

.nucleo-icon-patch-34::before {
  content: "\EEBF";
}

.nucleo-icon-patch::before {
  content: "\EEC0";
}

.nucleo-icon-path-exclude::before {
  content: "\EEC1";
}

.nucleo-icon-path-intersect::before {
  content: "\EEC2";
}

.nucleo-icon-path-minus::before {
  content: "\EEC3";
}

.nucleo-icon-path-unite::before {
  content: "\EEC4";
}

.nucleo-icon-paw::before {
  content: "\EEC5";
}

.nucleo-icon-pci-card::before {
  content: "\EEC6";
}

.nucleo-icon-peanut::before {
  content: "\EEC9";
}

.nucleo-icon-pear::before {
  content: "\EECB";
}

.nucleo-icon-peas::before {
  content: "\EED4";
}

.nucleo-icon-pen-01::before {
  content: "\EECA";
}

.nucleo-icon-pen-23::before {
  content: "\EECC";
}

.nucleo-icon-pen-tool::before {
  content: "\EECD";
}

.nucleo-icon-pencil-47::before {
  content: "\EECE";
}

.nucleo-icon-pencil::before {
  content: "\EECF";
}

.nucleo-icon-pepper::before {
  content: "\EEDC";
}

.nucleo-icon-percentage-39::before {
  content: "\EED0";
}

.nucleo-icon-pet-food::before {
  content: "\EED1";
}

.nucleo-icon-phone-2::before {
  content: "\EED2";
}

.nucleo-icon-phone-3::before {
  content: "\EED5";
}

.nucleo-icon-phone-call-end::before {
  content: "\EED6";
}

.nucleo-icon-phone-call::before {
  content: "\EED7";
}

.nucleo-icon-phone::before {
  content: "\EED8";
}

.nucleo-icon-pickaxe::before {
  content: "\EED9";
}

.nucleo-icon-pickle::before {
  content: "\EEDD";
}

.nucleo-icon-picnic-basket::before {
  content: "\EEDE";
}

.nucleo-icon-picture::before {
  content: "\EEDA";
}

.nucleo-icon-pig-2::before {
  content: "\EEDB";
}

.nucleo-icon-pill-42::before {
  content: "\EEDF";
}

.nucleo-icon-pill-43::before {
  content: "\EEE0";
}

.nucleo-icon-pill-container-44::before {
  content: "\EEE1";
}

.nucleo-icon-pill-container-47::before {
  content: "\EEE2";
}

.nucleo-icon-pin-2::before {
  content: "\EEE3";
}

.nucleo-icon-pin-3::before {
  content: "\EEE4";
}

.nucleo-icon-pin-4::before {
  content: "\EEE9";
}

.nucleo-icon-pin-add-2::before {
  content: "\EEEA";
}

.nucleo-icon-pin-add-e::before {
  content: "\EEE5";
}

.nucleo-icon-pin-add::before {
  content: "\EEE6";
}

.nucleo-icon-pin-check::before {
  content: "\EEEB";
}

.nucleo-icon-pin-copy::before {
  content: "\EEE7";
}

.nucleo-icon-pin-e::before {
  content: "\EEE8";
}

.nucleo-icon-pin-edit::before {
  content: "\EEEC";
}

.nucleo-icon-pin-heart::before {
  content: "\EEF1";
}

.nucleo-icon-pin-remove-2::before {
  content: "\EEF2";
}

.nucleo-icon-pin-remove-e::before {
  content: "\EEED";
}

.nucleo-icon-pin-remove::before {
  content: "\EEEE";
}

.nucleo-icon-pin-security::before {
  content: "\EEF3";
}

.nucleo-icon-pin::before {
  content: "\EEEF";
}

.nucleo-icon-pinch::before {
  content: "\EEF0";
}

.nucleo-icon-pineapple::before {
  content: "\EEF4";
}

.nucleo-icon-ping-pong::before {
  content: "\EEF5";
}

.nucleo-icon-pins::before {
  content: "\EEF6";
}

.nucleo-icon-pizza-slice::before {
  content: "\EEF7";
}

.nucleo-icon-plane-17::before {
  content: "\EEF8";
}

.nucleo-icon-plane-18::before {
  content: "\EEF9";
}

.nucleo-icon-planet::before {
  content: "\EEFA";
}

.nucleo-icon-plant-ground::before {
  content: "\EEFB";
}

.nucleo-icon-plant-vase::before {
  content: "\EEFC";
}

.nucleo-icon-plate::before {
  content: "\EEFD";
}

.nucleo-icon-play-68::before {
  content: "\EEFE";
}

.nucleo-icon-play-69::before {
  content: "\EEFF";
}

.nucleo-icon-player-19::before {
  content: "\EF00";
}

.nucleo-icon-player-48::before {
  content: "\EF01";
}

.nucleo-icon-player::before {
  content: "\EF02";
}

.nucleo-icon-playlist::before {
  content: "\EF03";
}

.nucleo-icon-plug::before {
  content: "\EF04";
}

.nucleo-icon-podium::before {
  content: "\EF05";
}

.nucleo-icon-polaroid-add::before {
  content: "\EF06";
}

.nucleo-icon-polaroid-delete::before {
  content: "\EF07";
}

.nucleo-icon-polaroid-multiple::before {
  content: "\EF08";
}

.nucleo-icon-polaroid::before {
  content: "\EF09";
}

.nucleo-icon-police::before {
  content: "\EF0A";
}

.nucleo-icon-pool::before {
  content: "\EF0B";
}

.nucleo-icon-poop::before {
  content: "\EF0C";
}

.nucleo-icon-popcorn::before {
  content: "\EF0F";
}

.nucleo-icon-position-marker::before {
  content: "\EF0D";
}

.nucleo-icon-position::before {
  content: "\EF3A";
}

.nucleo-icon-pot::before {
  content: "\EF0E";
}

.nucleo-icon-potato::before {
  content: "\EF10";
}

.nucleo-icon-power-level::before {
  content: "\EF11";
}

.nucleo-icon-preferences-circle-rotate::before {
  content: "\EF12";
}

.nucleo-icon-preferences-circle::before {
  content: "\EF13";
}

.nucleo-icon-preferences-container-circle-rotate::before {
  content: "\EF14";
}

.nucleo-icon-preferences-container-circle::before {
  content: "\EF15";
}

.nucleo-icon-preferences-container-rotate::before {
  content: "\EF16";
}

.nucleo-icon-preferences-container::before {
  content: "\EF17";
}

.nucleo-icon-preferences-rotate::before {
  content: "\EF18";
}

.nucleo-icon-preferences::before {
  content: "\EF19";
}

.nucleo-icon-print::before {
  content: "\EF1A";
}

.nucleo-icon-printer::before {
  content: "\EF1B";
}

.nucleo-icon-priority-high::before {
  content: "\EF1C";
}

.nucleo-icon-priority-low::before {
  content: "\EF1D";
}

.nucleo-icon-privacy-policy::before {
  content: "\EF21";
}

.nucleo-icon-progress::before {
  content: "\EF1E";
}

.nucleo-icon-prototype::before {
  content: "\EF3D";
}

.nucleo-icon-pulse-chart::before {
  content: "\EF1F";
}

.nucleo-icon-pulse-sleep::before {
  content: "\EF20";
}

.nucleo-icon-pulse::before {
  content: "\EF22";
}

.nucleo-icon-pumpkin::before {
  content: "\EF23";
}

.nucleo-icon-push-next::before {
  content: "\EF24";
}

.nucleo-icon-push-previous::before {
  content: "\EF25";
}

.nucleo-icon-puzzle-09::before {
  content: "\EF26";
}

.nucleo-icon-puzzle-10::before {
  content: "\EF27";
}

.nucleo-icon-puzzled-e::before {
  content: "\EF28";
}

.nucleo-icon-puzzled::before {
  content: "\EF29";
}

.nucleo-icon-quite-happy-e::before {
  content: "\EF2A";
}

.nucleo-icon-quite-happy::before {
  content: "\EF2B";
}

.nucleo-icon-quote::before {
  content: "\EF2C";
}

.nucleo-icon-rabbit::before {
  content: "\EF2D";
}

.nucleo-icon-rackets::before {
  content: "\EF2E";
}

.nucleo-icon-radar::before {
  content: "\EF2F";
}

.nucleo-icon-radiation::before {
  content: "\EF30";
}

.nucleo-icon-radio::before {
  content: "\EF31";
}

.nucleo-icon-rain-hail::before {
  content: "\EF32";
}

.nucleo-icon-rain::before {
  content: "\EF33";
}

.nucleo-icon-rainbow::before {
  content: "\EF34";
}

.nucleo-icon-ram::before {
  content: "\EF35";
}

.nucleo-icon-ranking::before {
  content: "\EF3B";
}

.nucleo-icon-rat::before {
  content: "\EF36";
}

.nucleo-icon-receipt-list-42::before {
  content: "\EF37";
}

.nucleo-icon-receipt-list-43::before {
  content: "\EF38";
}

.nucleo-icon-receipt::before {
  content: "\EF39";
}

.nucleo-icon-recipe-book-46::before {
  content: "\EF3C";
}

.nucleo-icon-recipe-book-47::before {
  content: "\EF3E";
}

.nucleo-icon-recipe-create::before {
  content: "\EF4F";
}

.nucleo-icon-recipe::before {
  content: "\EF50";
}

.nucleo-icon-recycling::before {
  content: "\EF3F";
}

.nucleo-icon-redo-10::before {
  content: "\EF40";
}

.nucleo-icon-redo-26::before {
  content: "\EF41";
}

.nucleo-icon-redo-79::before {
  content: "\EF42";
}

.nucleo-icon-redo-81::before {
  content: "\EF43";
}

.nucleo-icon-refresh-01::before {
  content: "\EF44";
}

.nucleo-icon-refresh-02::before {
  content: "\EF52";
}

.nucleo-icon-refresh-19::before {
  content: "\EF45";
}

.nucleo-icon-refresh-20::before {
  content: "\EF46";
}

.nucleo-icon-refresh-68::before {
  content: "\EF47";
}

.nucleo-icon-refresh-69::before {
  content: "\EF48";
}

.nucleo-icon-remote::before {
  content: "\EF49";
}

.nucleo-icon-remove-e-2::before {
  content: "\EF4A";
}

.nucleo-icon-remove::before {
  content: "\EF4B";
}

.nucleo-icon-replay::before {
  content: "\EF4C";
}

.nucleo-icon-reply-all::before {
  content: "\EF4D";
}

.nucleo-icon-reply::before {
  content: "\EF4E";
}

.nucleo-icon-reservation::before {
  content: "\EF51";
}

.nucleo-icon-resize-h::before {
  content: "\EF53";
}

.nucleo-icon-resize-v::before {
  content: "\EF54";
}

.nucleo-icon-restaurant-menu::before {
  content: "\EF55";
}

.nucleo-icon-rice::before {
  content: "\EF56";
}

.nucleo-icon-rim::before {
  content: "\EF58";
}

.nucleo-icon-ring::before {
  content: "\EF57";
}

.nucleo-icon-rings::before {
  content: "\EF59";
}

.nucleo-icon-ripples::before {
  content: "\EF5A";
}

.nucleo-icon-road-2::before {
  content: "\EF5B";
}

.nucleo-icon-road-sign-left::before {
  content: "\EF5C";
}

.nucleo-icon-road-sign-right::before {
  content: "\EF5D";
}

.nucleo-icon-roast-chicken::before {
  content: "\EF5E";
}

.nucleo-icon-robot-e::before {
  content: "\EF5F";
}

.nucleo-icon-robot::before {
  content: "\EF60";
}

.nucleo-icon-roll::before {
  content: "\EF61";
}

.nucleo-icon-rolling-pin::before {
  content: "\EF62";
}

.nucleo-icon-rope::before {
  content: "\EF63";
}

.nucleo-icon-rotate-22::before {
  content: "\EF64";
}

.nucleo-icon-rotate::before {
  content: "\EF65";
}

.nucleo-icon-round-down::before {
  content: "\EF66";
}

.nucleo-icon-round-e-alert::before {
  content: "\EF67";
}

.nucleo-icon-round-e-help::before {
  content: "\EF68";
}

.nucleo-icon-round-e-info::before {
  content: "\EF69";
}

.nucleo-icon-round-left-down::before {
  content: "\EF6A";
}

.nucleo-icon-round-left::before {
  content: "\EF6B";
}

.nucleo-icon-round-right-down::before {
  content: "\EF6C";
}

.nucleo-icon-round-right::before {
  content: "\EF6D";
}

.nucleo-icon-round-up-left::before {
  content: "\EF6E";
}

.nucleo-icon-round-up-right::before {
  content: "\EF6F";
}

.nucleo-icon-round-up::before {
  content: "\EF70";
}

.nucleo-icon-rowing::before {
  content: "\EF71";
}

.nucleo-icon-rugby::before {
  content: "\EF72";
}

.nucleo-icon-ruler-pencil::before {
  content: "\EF73";
}

.nucleo-icon-sad-e::before {
  content: "\EF74";
}

.nucleo-icon-sad::before {
  content: "\EF75";
}

.nucleo-icon-safe::before {
  content: "\EF76";
}

.nucleo-icon-salad::before {
  content: "\EF78";
}

.nucleo-icon-santa-hat::before {
  content: "\EF77";
}

.nucleo-icon-satisfied-e::before {
  content: "\EF79";
}

.nucleo-icon-satisfied::before {
  content: "\EF7A";
}

.nucleo-icon-sausage::before {
  content: "\EF7B";
}

.nucleo-icon-save-planet::before {
  content: "\EF7C";
}

.nucleo-icon-scale-2::before {
  content: "\EF7D";
}

.nucleo-icon-scale-3::before {
  content: "\EF7E";
}

.nucleo-icon-scale-4::before {
  content: "\EF7F";
}

.nucleo-icon-scale-down::before {
  content: "\EF80";
}

.nucleo-icon-scale-horizontal::before {
  content: "\EF81";
}

.nucleo-icon-scale-up::before {
  content: "\EF82";
}

.nucleo-icon-scale-vertical::before {
  content: "\EF83";
}

.nucleo-icon-scan::before {
  content: "\EF84";
}

.nucleo-icon-scarf::before {
  content: "\EF85";
}

.nucleo-icon-school::before {
  content: "\EF86";
}

.nucleo-icon-scissors::before {
  content: "\EF87";
}

.nucleo-icon-sd::before {
  content: "\EF88";
}

.nucleo-icon-sea-mask::before {
  content: "\EF89";
}

.nucleo-icon-search-2::before {
  content: "\EF8A";
}

.nucleo-icon-seat::before {
  content: "\EF8B";
}

.nucleo-icon-seatbelt::before {
  content: "\EF8D";
}

.nucleo-icon-security::before {
  content: "\EF8C";
}

.nucleo-icon-segmentation::before {
  content: "\EF8E";
}

.nucleo-icon-select-83::before {
  content: "\EF90";
}

.nucleo-icon-select-84::before {
  content: "\EF91";
}

.nucleo-icon-select::before {
  content: "\EF8F";
}

.nucleo-icon-selection::before {
  content: "\EF92";
}

.nucleo-icon-send-2::before {
  content: "\EF93";
}

.nucleo-icon-send::before {
  content: "\EF94";
}

.nucleo-icon-sensor::before {
  content: "\EF95";
}

.nucleo-icon-server-rack::before {
  content: "\EF96";
}

.nucleo-icon-settings-gear-63::before {
  content: "\EF97";
}

.nucleo-icon-settings-gear-64::before {
  content: "\EF98";
}

.nucleo-icon-settings-gear-65::before {
  content: "\EF99";
}

.nucleo-icon-settings-tool-66::before {
  content: "\EF9A";
}

.nucleo-icon-shaker::before {
  content: "\EF9B";
}

.nucleo-icon-shape-adjust::before {
  content: "\EF9C";
}

.nucleo-icon-shape-arrow::before {
  content: "\EF9D";
}

.nucleo-icon-shape-circle::before {
  content: "\EF9E";
}

.nucleo-icon-shape-custom::before {
  content: "\EF9F";
}

.nucleo-icon-shape-line::before {
  content: "\EFA0";
}

.nucleo-icon-shape-oval::before {
  content: "\EFA1";
}

.nucleo-icon-shape-polygon-2::before {
  content: "\EFA2";
}

.nucleo-icon-shape-polygon::before {
  content: "\EFA3";
}

.nucleo-icon-shape-rectangle::before {
  content: "\EFA5";
}

.nucleo-icon-shape-square::before {
  content: "\EFA4";
}

.nucleo-icon-shape-star::before {
  content: "\EFA6";
}

.nucleo-icon-shape-triangle-2::before {
  content: "\EFA8";
}

.nucleo-icon-shape-triangle::before {
  content: "\EFA7";
}

.nucleo-icon-shapes::before {
  content: "\EFA9";
}

.nucleo-icon-share-2::before {
  content: "\EFAA";
}

.nucleo-icon-share-26::before {
  content: "\EFAB";
}

.nucleo-icon-share-27::before {
  content: "\EFAC";
}

.nucleo-icon-share-66::before {
  content: "\EFAD";
}

.nucleo-icon-share-91::before {
  content: "\EFAE";
}

.nucleo-icon-share-92::before {
  content: "\EFAF";
}

.nucleo-icon-share-bold::before {
  content: "\EFB0";
}

.nucleo-icon-share::before {
  content: "\EFB1";
}

.nucleo-icon-shark-2::before {
  content: "\EFB2";
}

.nucleo-icon-shark-e::before {
  content: "\EFB3";
}

.nucleo-icon-shark::before {
  content: "\EFB4";
}

.nucleo-icon-sharpener::before {
  content: "\EFB5";
}

.nucleo-icon-shell::before {
  content: "\EFB6";
}

.nucleo-icon-shirt-buttons::before {
  content: "\EFB7";
}

.nucleo-icon-shirt-neck::before {
  content: "\EFB8";
}

.nucleo-icon-shirt::before {
  content: "\EFB9";
}

.nucleo-icon-shoe-man::before {
  content: "\EFBA";
}

.nucleo-icon-shoe-sport::before {
  content: "\EFBB";
}

.nucleo-icon-shoe-woman::before {
  content: "\EFBC";
}

.nucleo-icon-shop::before {
  content: "\EFBD";
}

.nucleo-icon-shorts::before {
  content: "\EFBE";
}

.nucleo-icon-shovel::before {
  content: "\EFBF";
}

.nucleo-icon-shower::before {
  content: "\EFC0";
}

.nucleo-icon-shuffle-01::before {
  content: "\EFC1";
}

.nucleo-icon-shuffle-35::before {
  content: "\EFC2";
}

.nucleo-icon-shuffle-97::before {
  content: "\EFC3";
}

.nucleo-icon-shuffle-98::before {
  content: "\EFC4";
}

.nucleo-icon-shy-e::before {
  content: "\EFC5";
}

.nucleo-icon-shy::before {
  content: "\EFC6";
}

.nucleo-icon-sickle::before {
  content: "\EFC7";
}

.nucleo-icon-sidebar::before {
  content: "\EFC8";
}

.nucleo-icon-sign-board::before {
  content: "\EFC9";
}

.nucleo-icon-sign::before {
  content: "\EFCA";
}

.nucleo-icon-signal-2::before {
  content: "\EFCB";
}

.nucleo-icon-signal::before {
  content: "\EFCC";
}

.nucleo-icon-signature::before {
  content: "\EFCD";
}

.nucleo-icon-sim-card::before {
  content: "\EFCE";
}

.nucleo-icon-simple-add::before {
  content: "\EFCF";
}

.nucleo-icon-simple-delete::before {
  content: "\EFD0";
}

.nucleo-icon-simple-down::before {
  content: "\EFD1";
}

.nucleo-icon-simple-left::before {
  content: "\EFD2";
}

.nucleo-icon-simple-remove::before {
  content: "\EFD3";
}

.nucleo-icon-simple-right::before {
  content: "\EFD4";
}

.nucleo-icon-simple-up::before {
  content: "\EFD5";
}

.nucleo-icon-single-01::before {
  content: "\EFD6";
}

.nucleo-icon-single-02::before {
  content: "\EFD7";
}

.nucleo-icon-single-03::before {
  content: "\EFD8";
}

.nucleo-icon-single-04::before {
  content: "\EFD9";
}

.nucleo-icon-single-05::before {
  content: "\EFDA";
}

.nucleo-icon-single-body::before {
  content: "\EFDB";
}

.nucleo-icon-single-content-02::before {
  content: "\EFDC";
}

.nucleo-icon-single-content-03::before {
  content: "\EFDD";
}

.nucleo-icon-single-copies::before {
  content: "\EFDE";
}

.nucleo-icon-single-copy-04::before {
  content: "\EFDF";
}

.nucleo-icon-single-copy-06::before {
  content: "\EFE0";
}

.nucleo-icon-single-folded-content::before {
  content: "\EFE6";
}

.nucleo-icon-single-folded::before {
  content: "\EFE1";
}

.nucleo-icon-single-paragraph::before {
  content: "\EFE5";
}

.nucleo-icon-single::before {
  content: "\EFE2";
}

.nucleo-icon-sink-wash::before {
  content: "\EFE3";
}

.nucleo-icon-sink::before {
  content: "\EFE4";
}

.nucleo-icon-size-large::before {
  content: "\EFE7";
}

.nucleo-icon-size-medium::before {
  content: "\EFE8";
}

.nucleo-icon-size-small::before {
  content: "\EFEC";
}

.nucleo-icon-size::before {
  content: "\EFE9";
}

.nucleo-icon-skateboard-2::before {
  content: "\EFEA";
}

.nucleo-icon-skateboard::before {
  content: "\EFEB";
}

.nucleo-icon-skating::before {
  content: "\EFF0";
}

.nucleo-icon-skew-down::before {
  content: "\EFED";
}

.nucleo-icon-skew-left::before {
  content: "\EFEE";
}

.nucleo-icon-skew-right::before {
  content: "\EFEF";
}

.nucleo-icon-skew-up::before {
  content: "\EFF1";
}

.nucleo-icon-skirt::before {
  content: "\EFF2";
}

.nucleo-icon-skull-2::before {
  content: "\EFF3";
}

.nucleo-icon-skull::before {
  content: "\EFF4";
}

.nucleo-icon-slacks-12::before {
  content: "\EFF5";
}

.nucleo-icon-slice::before {
  content: "\EFF6";
}

.nucleo-icon-slide-left::before {
  content: "\EFF7";
}

.nucleo-icon-slide-right::before {
  content: "\EFF8";
}

.nucleo-icon-slider::before {
  content: "\EFF9";
}

.nucleo-icon-small-add::before {
  content: "\EFFA";
}

.nucleo-icon-small-delete::before {
  content: "\EFFB";
}

.nucleo-icon-small-down::before {
  content: "\EFFC";
}

.nucleo-icon-small-left::before {
  content: "\EFFE";
}

.nucleo-icon-small-remove::before {
  content: "\EFFF";
}

.nucleo-icon-small-right::before {
  content: "\EFFD";
}

.nucleo-icon-small-triangle-down::before {
  content: "\F000";
}

.nucleo-icon-small-triangle-left::before {
  content: "\F001";
}

.nucleo-icon-small-triangle-right::before {
  content: "\F002";
}

.nucleo-icon-small-triangle-up::before {
  content: "\F003";
}

.nucleo-icon-small-up::before {
  content: "\F004";
}

.nucleo-icon-smile-e::before {
  content: "\F005";
}

.nucleo-icon-smile::before {
  content: "\F006";
}

.nucleo-icon-smoothie::before {
  content: "\F007";
}

.nucleo-icon-snack::before {
  content: "\F008";
}

.nucleo-icon-snow-ball::before {
  content: "\F009";
}

.nucleo-icon-snow::before {
  content: "\F00A";
}

.nucleo-icon-snowboard::before {
  content: "\F00B";
}

.nucleo-icon-snowman::before {
  content: "\F00C";
}

.nucleo-icon-soccer-field::before {
  content: "\F00D";
}

.nucleo-icon-sock::before {
  content: "\F00E";
}

.nucleo-icon-socket-europe-1::before {
  content: "\F00F";
}

.nucleo-icon-socket-europe-2::before {
  content: "\F010";
}

.nucleo-icon-socket-uk::before {
  content: "\F011";
}

.nucleo-icon-socket::before {
  content: "\F012";
}

.nucleo-icon-soft-drink::before {
  content: "\F013";
}

.nucleo-icon-sound-wave::before {
  content: "\F014";
}

.nucleo-icon-soup::before {
  content: "\F015";
}

.nucleo-icon-soy-sauce::before {
  content: "\F016";
}

.nucleo-icon-spa::before {
  content: "\F017";
}

.nucleo-icon-spaceship::before {
  content: "\F018";
}

.nucleo-icon-speaker-01::before {
  content: "\F019";
}

.nucleo-icon-speaker-05::before {
  content: "\F01A";
}

.nucleo-icon-spectrum::before {
  content: "\F01C";
}

.nucleo-icon-speechless-e::before {
  content: "\F01B";
}

.nucleo-icon-speechless::before {
  content: "\F01D";
}

.nucleo-icon-spider::before {
  content: "\F01E";
}

.nucleo-icon-split-33::before {
  content: "\F01F";
}

.nucleo-icon-split-37::before {
  content: "\F020";
}

.nucleo-icon-sport-bag::before {
  content: "\F021";
}

.nucleo-icon-sports-fan::before {
  content: "\F022";
}

.nucleo-icon-spray::before {
  content: "\F023";
}

.nucleo-icon-square-add-08::before {
  content: "\F024";
}

.nucleo-icon-square-add-11::before {
  content: "\F025";
}

.nucleo-icon-square-corner-down-left::before {
  content: "\F026";
}

.nucleo-icon-square-corner-down-right::before {
  content: "\F027";
}

.nucleo-icon-square-corner-up-left::before {
  content: "\F028";
}

.nucleo-icon-square-corner-up-right::before {
  content: "\F029";
}

.nucleo-icon-square-delete-10::before {
  content: "\F02A";
}

.nucleo-icon-square-delete-13::before {
  content: "\F02B";
}

.nucleo-icon-square-down-06::before {
  content: "\F02C";
}

.nucleo-icon-square-down::before {
  content: "\F02D";
}

.nucleo-icon-square-download::before {
  content: "\F02E";
}

.nucleo-icon-square-e-alert::before {
  content: "\F02F";
}

.nucleo-icon-square-e-down::before {
  content: "\F030";
}

.nucleo-icon-square-e-help::before {
  content: "\F031";
}

.nucleo-icon-square-e-info::before {
  content: "\F032";
}

.nucleo-icon-square-e-left::before {
  content: "\F033";
}

.nucleo-icon-square-e-right::before {
  content: "\F034";
}

.nucleo-icon-square-e-up::before {
  content: "\F035";
}

.nucleo-icon-square-left-04::before {
  content: "\F036";
}

.nucleo-icon-square-left::before {
  content: "\F037";
}

.nucleo-icon-square-remove-09::before {
  content: "\F038";
}

.nucleo-icon-square-remove-12::before {
  content: "\F039";
}

.nucleo-icon-square-right-03::before {
  content: "\F03A";
}

.nucleo-icon-square-right::before {
  content: "\F03B";
}

.nucleo-icon-square-simple-down::before {
  content: "\F03C";
}

.nucleo-icon-square-simple-left::before {
  content: "\F03D";
}

.nucleo-icon-square-simple-right::before {
  content: "\F03E";
}

.nucleo-icon-square-simple-up::before {
  content: "\F03F";
}

.nucleo-icon-square-up-05::before {
  content: "\F040";
}

.nucleo-icon-square-up::before {
  content: "\F041";
}

.nucleo-icon-square-upload::before {
  content: "\F042";
}

.nucleo-icon-squares::before {
  content: "\F043";
}

.nucleo-icon-stadium::before {
  content: "\F044";
}

.nucleo-icon-stamp::before {
  content: "\F045";
}

.nucleo-icon-star-empty::before {
  content: "\F046";
}

.nucleo-icon-star-half::before {
  content: "\F047";
}

.nucleo-icon-steak-2::before {
  content: "\F048";
}

.nucleo-icon-steak::before {
  content: "\F049";
}

.nucleo-icon-steering-wheel::before {
  content: "\F04A";
}

.nucleo-icon-steps::before {
  content: "\F04B";
}

.nucleo-icon-stock-2::before {
  content: "\F04C";
}

.nucleo-icon-stock::before {
  content: "\F04D";
}

.nucleo-icon-storage-hanger::before {
  content: "\F04E";
}

.nucleo-icon-storage::before {
  content: "\F04F";
}

.nucleo-icon-store::before {
  content: "\F050";
}

.nucleo-icon-strategy::before {
  content: "\F051";
}

.nucleo-icon-strawberry::before {
  content: "\F052";
}

.nucleo-icon-stre-down::before {
  content: "\F053";
}

.nucleo-icon-stre-left::before {
  content: "\F054";
}

.nucleo-icon-stre-right::before {
  content: "\F055";
}

.nucleo-icon-stre-up::before {
  content: "\F056";
}

.nucleo-icon-stretch::before {
  content: "\F057";
}

.nucleo-icon-strikethrough::before {
  content: "\F058";
}

.nucleo-icon-strong-down::before {
  content: "\F059";
}

.nucleo-icon-strong-left::before {
  content: "\F05A";
}

.nucleo-icon-strong-right::before {
  content: "\F05B";
}

.nucleo-icon-strong-up::before {
  content: "\F05C";
}

.nucleo-icon-subscript::before {
  content: "\F05D";
}

.nucleo-icon-subtitles::before {
  content: "\F05E";
}

.nucleo-icon-sugar::before {
  content: "\F060";
}

.nucleo-icon-sun-cloud-drop::before {
  content: "\F05F";
}

.nucleo-icon-sun-cloud-fog::before {
  content: "\F061";
}

.nucleo-icon-sun-cloud-hail::before {
  content: "\F062";
}

.nucleo-icon-sun-cloud-light::before {
  content: "\F063";
}

.nucleo-icon-sun-cloud-rain::before {
  content: "\F064";
}

.nucleo-icon-sun-cloud-snow-54::before {
  content: "\F065";
}

.nucleo-icon-sun-cloud-snow-55::before {
  content: "\F066";
}

.nucleo-icon-sun-cloud::before {
  content: "\F067";
}

.nucleo-icon-sun-fog-30::before {
  content: "\F068";
}

.nucleo-icon-sun-fog-43::before {
  content: "\F069";
}

.nucleo-icon-sunglasses-48::before {
  content: "\F06A";
}

.nucleo-icon-sunglasses-49::before {
  content: "\F06B";
}

.nucleo-icon-sunglasses::before {
  content: "\F06C";
}

.nucleo-icon-superscript::before {
  content: "\F06D";
}

.nucleo-icon-supplement::before {
  content: "\F06E";
}

.nucleo-icon-support-16::before {
  content: "\F06F";
}

.nucleo-icon-support-17::before {
  content: "\F070";
}

.nucleo-icon-surf-2::before {
  content: "\F071";
}

.nucleo-icon-surf::before {
  content: "\F072";
}

.nucleo-icon-surprise-e::before {
  content: "\F073";
}

.nucleo-icon-surprise::before {
  content: "\F074";
}

.nucleo-icon-sushi::before {
  content: "\F075";
}

.nucleo-icon-swap-horizontal::before {
  content: "\F076";
}

.nucleo-icon-swap-vertical::before {
  content: "\F077";
}

.nucleo-icon-swimwear::before {
  content: "\F078";
}

.nucleo-icon-swipe-bottom::before {
  content: "\F079";
}

.nucleo-icon-swipe-left::before {
  content: "\F07A";
}

.nucleo-icon-swipe-right::before {
  content: "\F07B";
}

.nucleo-icon-swipe-up::before {
  content: "\F07C";
}

.nucleo-icon-swiss-knife::before {
  content: "\F07D";
}

.nucleo-icon-switcher::before {
  content: "\F07E";
}

.nucleo-icon-sync::before {
  content: "\F07F";
}

.nucleo-icon-syringe::before {
  content: "\F080";
}

.nucleo-icon-system-update::before {
  content: "\F081";
}

.nucleo-icon-table-left::before {
  content: "\F082";
}

.nucleo-icon-table-right::before {
  content: "\F083";
}

.nucleo-icon-tablet-2::before {
  content: "\F084";
}

.nucleo-icon-tablet-button::before {
  content: "\F085";
}

.nucleo-icon-tablet-reader-31::before {
  content: "\F086";
}

.nucleo-icon-tablet-reader-42::before {
  content: "\F087";
}

.nucleo-icon-tablet-toolbar::before {
  content: "\F088";
}

.nucleo-icon-tablet::before {
  content: "\F089";
}

.nucleo-icon-tacos::before {
  content: "\F08A";
}

.nucleo-icon-tactic::before {
  content: "\F08B";
}

.nucleo-icon-tag-2::before {
  content: "\F08C";
}

.nucleo-icon-tag-add::before {
  content: "\F08D";
}

.nucleo-icon-tag-check::before {
  content: "\F08E";
}

.nucleo-icon-tag-content::before {
  content: "\F08F";
}

.nucleo-icon-tag-cut::before {
  content: "\F090";
}

.nucleo-icon-tag-loyalty::before {
  content: "\F09C";
}

.nucleo-icon-tag-remove::before {
  content: "\F091";
}

.nucleo-icon-tag::before {
  content: "\F092";
}

.nucleo-icon-tags-stack::before {
  content: "\F0AD";
}

.nucleo-icon-tail-down::before {
  content: "\F093";
}

.nucleo-icon-tail-left::before {
  content: "\F094";
}

.nucleo-icon-tail-right::before {
  content: "\F095";
}

.nucleo-icon-tail-triangle-down::before {
  content: "\F096";
}

.nucleo-icon-tail-triangle-left::before {
  content: "\F097";
}

.nucleo-icon-tail-triangle-right::before {
  content: "\F098";
}

.nucleo-icon-tail-triangle-up::before {
  content: "\F099";
}

.nucleo-icon-tail-up::before {
  content: "\F09A";
}

.nucleo-icon-takeaway::before {
  content: "\F09D";
}

.nucleo-icon-tap-01::before {
  content: "\F09B";
}

.nucleo-icon-tap-02::before {
  content: "\F09E";
}

.nucleo-icon-tape::before {
  content: "\F09F";
}

.nucleo-icon-target::before {
  content: "\F0A0";
}

.nucleo-icon-tea-bag::before {
  content: "\F0A5";
}

.nucleo-icon-tea::before {
  content: "\F0A1";
}

.nucleo-icon-temperature-23::before {
  content: "\F0A2";
}

.nucleo-icon-temperature-24::before {
  content: "\F0A3";
}

.nucleo-icon-tennis-ball::before {
  content: "\F0A4";
}

.nucleo-icon-tennis::before {
  content: "\F0A6";
}

.nucleo-icon-terrace::before {
  content: "\F0BA";
}

.nucleo-icon-text-2::before {
  content: "\F0A7";
}

.nucleo-icon-text::before {
  content: "\F0A8";
}

.nucleo-icon-texture::before {
  content: "\F0AE";
}

.nucleo-icon-ticket-75::before {
  content: "\F0A9";
}

.nucleo-icon-ticket-76::before {
  content: "\F0AA";
}

.nucleo-icon-tie-01::before {
  content: "\F0AB";
}

.nucleo-icon-tie-02::before {
  content: "\F0AC";
}

.nucleo-icon-tie-bow::before {
  content: "\F0AF";
}

.nucleo-icon-tile-55::before {
  content: "\F0B0";
}

.nucleo-icon-tile-56::before {
  content: "\F0B1";
}

.nucleo-icon-time-3::before {
  content: "\F0B2";
}

.nucleo-icon-time-alarm::before {
  content: "\F0B3";
}

.nucleo-icon-time-clock::before {
  content: "\F0B4";
}

.nucleo-icon-time-countdown::before {
  content: "\F0B5";
}

.nucleo-icon-timeline::before {
  content: "\F0B6";
}

.nucleo-icon-timer::before {
  content: "\F0BB";
}

.nucleo-icon-todo::before {
  content: "\F0B7";
}

.nucleo-icon-toilet-paper::before {
  content: "\F0B8";
}

.nucleo-icon-toilet::before {
  content: "\F0B9";
}

.nucleo-icon-tomato::before {
  content: "\F0C8";
}

.nucleo-icon-tool-blur::before {
  content: "\F0BC";
}

.nucleo-icon-tool-hand::before {
  content: "\F0C1";
}

.nucleo-icon-tool-select::before {
  content: "\F0C2";
}

.nucleo-icon-tooth::before {
  content: "\F0BD";
}

.nucleo-icon-touch::before {
  content: "\F0BE";
}

.nucleo-icon-tracking::before {
  content: "\F0BF";
}

.nucleo-icon-tractor::before {
  content: "\F0C0";
}

.nucleo-icon-train::before {
  content: "\F0C3";
}

.nucleo-icon-tram::before {
  content: "\F0C4";
}

.nucleo-icon-transform-2d::before {
  content: "\F0C9";
}

.nucleo-icon-transform-origin::before {
  content: "\F0C5";
}

.nucleo-icon-transform::before {
  content: "\F0C6";
}

.nucleo-icon-transparent::before {
  content: "\F0CA";
}

.nucleo-icon-trash-round::before {
  content: "\F0C7";
}

.nucleo-icon-trash-simple::before {
  content: "\F0CB";
}

.nucleo-icon-trash::before {
  content: "\F0CC";
}

.nucleo-icon-treadmill::before {
  content: "\F0CE";
}

.nucleo-icon-treasure-map-40::before {
  content: "\F0CD";
}

.nucleo-icon-tree-01::before {
  content: "\F0CF";
}

.nucleo-icon-tree-02::before {
  content: "\F0D0";
}

.nucleo-icon-tree-03::before {
  content: "\F0D1";
}

.nucleo-icon-tree-ball::before {
  content: "\F0D2";
}

.nucleo-icon-tree::before {
  content: "\F0D3";
}

.nucleo-icon-trend-down::before {
  content: "\F0D4";
}

.nucleo-icon-trend-up::before {
  content: "\F0D5";
}

.nucleo-icon-triangle-down-20::before {
  content: "\F0D6";
}

.nucleo-icon-triangle-down-65::before {
  content: "\F0D7";
}

.nucleo-icon-triangle-down::before {
  content: "\F0D8";
}

.nucleo-icon-triangle-left-18::before {
  content: "\F0D9";
}

.nucleo-icon-triangle-left-63::before {
  content: "\F0DA";
}

.nucleo-icon-triangle-left::before {
  content: "\F0DB";
}

.nucleo-icon-triangle-right-17::before {
  content: "\F0DC";
}

.nucleo-icon-triangle-right-62::before {
  content: "\F0DD";
}

.nucleo-icon-triangle-right::before {
  content: "\F0DE";
}

.nucleo-icon-triangle-up-19::before {
  content: "\F0DF";
}

.nucleo-icon-triangle-up-64::before {
  content: "\F0E0";
}

.nucleo-icon-triangle-up::before {
  content: "\F0E1";
}

.nucleo-icon-tripod::before {
  content: "\F0E2";
}

.nucleo-icon-trolley::before {
  content: "\F0E3";
}

.nucleo-icon-trophy::before {
  content: "\F0E4";
}

.nucleo-icon-truck-front::before {
  content: "\F0E5";
}

.nucleo-icon-trunk::before {
  content: "\F0E6";
}

.nucleo-icon-tshirt-53::before {
  content: "\F0E7";
}

.nucleo-icon-tshirt-54::before {
  content: "\F0E8";
}

.nucleo-icon-turtle::before {
  content: "\F0E9";
}

.nucleo-icon-tv-2::before {
  content: "\F0EA";
}

.nucleo-icon-tv-old::before {
  content: "\F0EB";
}

.nucleo-icon-tv::before {
  content: "\F0EC";
}

.nucleo-icon-twitch::before {
  content: "\F0EE";
}

.nucleo-icon-ui-03::before {
  content: "\F0ED";
}

.nucleo-icon-ui-04::before {
  content: "\F0EF";
}

.nucleo-icon-umbrella-13::before {
  content: "\F0F0";
}

.nucleo-icon-umbrella-14::before {
  content: "\F0F1";
}

.nucleo-icon-underline::before {
  content: "\F0F2";
}

.nucleo-icon-underwear-man::before {
  content: "\F0F3";
}

.nucleo-icon-underwear::before {
  content: "\F0F4";
}

.nucleo-icon-undo-25::before {
  content: "\F0F5";
}

.nucleo-icon-undo-29::before {
  content: "\F0F6";
}

.nucleo-icon-ungroup::before {
  content: "\F0F7";
}

.nucleo-icon-upload::before {
  content: "\F0F8";
}

.nucleo-icon-usb::before {
  content: "\F0F9";
}

.nucleo-icon-user-frame-31::before {
  content: "\F0FA";
}

.nucleo-icon-user-frame-32::before {
  content: "\F0FB";
}

.nucleo-icon-user-frame-33::before {
  content: "\F0FC";
}

.nucleo-icon-user-run::before {
  content: "\F0FD";
}

.nucleo-icon-vampire::before {
  content: "\F0FE";
}

.nucleo-icon-vector::before {
  content: "\F0FF";
}

.nucleo-icon-ventilation::before {
  content: "\F102";
}

.nucleo-icon-vespa-front::before {
  content: "\F100";
}

.nucleo-icon-vest::before {
  content: "\F101";
}

.nucleo-icon-video-64::before {
  content: "\F103";
}

.nucleo-icon-video-65::before {
  content: "\F104";
}

.nucleo-icon-video-66::before {
  content: "\F105";
}

.nucleo-icon-video-67::before {
  content: "\F106";
}

.nucleo-icon-videocamera-71::before {
  content: "\F107";
}

.nucleo-icon-videocamera-72::before {
  content: "\F108";
}

.nucleo-icon-virus::before {
  content: "\F109";
}

.nucleo-icon-voice-record::before {
  content: "\F10A";
}

.nucleo-icon-volleyball-player::before {
  content: "\F10E";
}

.nucleo-icon-volleyball::before {
  content: "\F10B";
}

.nucleo-icon-volume-93::before {
  content: "\F10C";
}

.nucleo-icon-volume-97::before {
  content: "\F10D";
}

.nucleo-icon-volume-down::before {
  content: "\F10F";
}

.nucleo-icon-volume-up::before {
  content: "\F110";
}

.nucleo-icon-vpn::before {
  content: "\F111";
}

.nucleo-icon-waffle::before {
  content: "\F112";
}

.nucleo-icon-walk::before {
  content: "\F115";
}

.nucleo-icon-wallet-43::before {
  content: "\F113";
}

.nucleo-icon-wallet-44::before {
  content: "\F114";
}

.nucleo-icon-wallet::before {
  content: "\F116";
}

.nucleo-icon-wand-11::before {
  content: "\F117";
}

.nucleo-icon-wardrobe::before {
  content: "\F118";
}

.nucleo-icon-wash-2::before {
  content: "\F119";
}

.nucleo-icon-wash::before {
  content: "\F11A";
}

.nucleo-icon-washing-fluid::before {
  content: "\F11D";
}

.nucleo-icon-waste-danger::before {
  content: "\F11B";
}

.nucleo-icon-waste-recycling::before {
  content: "\F11C";
}

.nucleo-icon-waste::before {
  content: "\F11E";
}

.nucleo-icon-watch-circle::before {
  content: "\F11F";
}

.nucleo-icon-watch-time::before {
  content: "\F120";
}

.nucleo-icon-watch::before {
  content: "\F121";
}

.nucleo-icon-water-aerobics::before {
  content: "\F122";
}

.nucleo-icon-water-hand::before {
  content: "\F123";
}

.nucleo-icon-water-polo-ball::before {
  content: "\F125";
}

.nucleo-icon-water-sink::before {
  content: "\F124";
}

.nucleo-icon-water-surface::before {
  content: "\F126";
}

.nucleo-icon-water-wave::before {
  content: "\F128";
}

.nucleo-icon-water::before {
  content: "\F127";
}

.nucleo-icon-watermelon::before {
  content: "\F129";
}

.nucleo-icon-webcam-38::before {
  content: "\F12A";
}

.nucleo-icon-webcam-39::before {
  content: "\F12B";
}

.nucleo-icon-webpage-2::before {
  content: "\F12C";
}

.nucleo-icon-webpage::before {
  content: "\F12D";
}

.nucleo-icon-weed::before {
  content: "\F12E";
}

.nucleo-icon-weight-plate::before {
  content: "\F12F";
}

.nucleo-icon-weight::before {
  content: "\F130";
}

.nucleo-icon-what-e::before {
  content: "\F131";
}

.nucleo-icon-what::before {
  content: "\F132";
}

.nucleo-icon-wheel-2::before {
  content: "\F133";
}

.nucleo-icon-wheel::before {
  content: "\F135";
}

.nucleo-icon-wheelchair::before {
  content: "\F134";
}

.nucleo-icon-whisk::before {
  content: "\F136";
}

.nucleo-icon-whistle::before {
  content: "\F137";
}

.nucleo-icon-widget::before {
  content: "\F138";
}

.nucleo-icon-wifi-off::before {
  content: "\F139";
}

.nucleo-icon-wifi-protected::before {
  content: "\F13A";
}

.nucleo-icon-wifi-router::before {
  content: "\F13B";
}

.nucleo-icon-wifi::before {
  content: "\F13C";
}

.nucleo-icon-wind-2::before {
  content: "\F13D";
}

.nucleo-icon-wind::before {
  content: "\F13E";
}

.nucleo-icon-window-add::before {
  content: "\F13F";
}

.nucleo-icon-window-code::before {
  content: "\F140";
}

.nucleo-icon-window-delete::before {
  content: "\F141";
}

.nucleo-icon-window-paragraph::before {
  content: "\F142";
}

.nucleo-icon-window-responsive::before {
  content: "\F143";
}

.nucleo-icon-wine-list::before {
  content: "\F145";
}

.nucleo-icon-witch-hat::before {
  content: "\F144";
}

.nucleo-icon-wolf::before {
  content: "\F146";
}

.nucleo-icon-woman-21::before {
  content: "\F147";
}

.nucleo-icon-woman::before {
  content: "\F148";
}

.nucleo-icon-wood::before {
  content: "\F149";
}

.nucleo-icon-wool-ball::before {
  content: "\F14A";
}

.nucleo-icon-workout-plan::before {
  content: "\F14D";
}

.nucleo-icon-world-2::before {
  content: "\F14B";
}

.nucleo-icon-world::before {
  content: "\F14C";
}

.nucleo-icon-xmas-sock::before {
  content: "\F14E";
}

.nucleo-icon-yogurt::before {
  content: "\F151";
}

.nucleo-icon-zombie::before {
  content: "\F14F";
}

.nucleo-icon-zoom-100::before {
  content: "\F150";
}

.nucleo-icon-zoom-2::before {
  content: "\F152";
}

.nucleo-icon-zoom-88::before {
  content: "\F153";
}

.nucleo-icon-zoom-99::before {
  content: "\F155";
}

.nucleo-icon-zoom-bold-in::before {
  content: "\F154";
}

.nucleo-icon-zoom-bold-out::before {
  content: "\F156";
}

.nucleo-icon-zoom-bold::before {
  content: "\F157";
}

.nucleo-icon-zoom-circle::before {
  content: "\F158";
}

.nucleo-icon-zoom-e::before {
  content: "\F159";
}

.nucleo-icon-zoom-in::before {
  content: "\F15A";
}

.nucleo-icon-zoom-out::before {
  content: "\F15B";
}

.nucleo-icon-zoom-split-in::before {
  content: "\F15C";
}

.nucleo-icon-zoom-split-out::before {
  content: "\F15D";
}

.nucleo-icon-zoom-split::before {
  content: "\F15E";
}

.nucleo-icon-zoom-square::before {
  content: "\F15F";
}

.nucleo-icon-zoom-triangles::before {
  content: "\F160";
}

.nucleo-icon-zoom::before {
  content: "\F161";
}


