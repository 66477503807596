/*--------------------------------

Nucleo Web Font 2018
Generated using nucleoapp.com

-------------------------------- */
@font-face {
  font-family: 'nucleo';
  src: url('../fonts/nucleo/nucleo.eot');
  src: url('../fonts/nucleo/nucleo.eot') format('embedded-opentype'), url('../fonts/nucleo/nucleo.woff2') format('woff2'), url('../fonts/nucleo/nucleo.woff') format('woff'), url('../fonts/nucleo/nucleo.ttf') format('truetype'), url('../fonts/nucleo/nucleo.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

/*------------------------
	base class definition
-------------------------*/
.nucleo-icon {
  display: inline-block;
  font: normal normal normal 1em/1 'nucleo';
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*------------------------
  change icon size
-------------------------*/
/* relative units */
.nucleo-icon-sm {
  font-size: 0.8em;
}
.nucleo-icon-lg {
  font-size: 1.2em;
}
/* absolute units */
.nucleo-icon-16 {
  font-size: 16px;
}
.nucleo-icon-32 {
  font-size: 32px;
}
/*----------------------------------
  add a square/circle background
-----------------------------------*/
.nucleo-icon-bg-square,
.nucleo-icon-bg-circle {
  padding: 0.35em;
  background-color: #eee;
}
.nucleo-icon-bg-circle {
  border-radius: 50%;
}
/*------------------------------------
  use icons as list item markers
-------------------------------------*/
.nucleo-icon-ul {
  padding-left: 0;
  list-style-type: none;
}
.nucleo-icon-ul > li {
  display: flex;
  align-items: flex-start;
  line-height: 1.4;
}
.nucleo-icon-ul > li > .nucleo-icon {
  margin-right: 0.4em;
  line-height: inherit;
}
/*------------------------
  spinning icons
-------------------------*/
.nucleo-icon-is-spinning {
  -webkit-animation: icon-spin 2s infinite linear;
  -moz-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes icon-spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*------------------------
  rotated/flipped icons
-------------------------*/
.nucleo-icon-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.nucleo-icon-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.nucleo-icon-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
.nucleo-icon-flip-y {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.nucleo-icon-flip-x {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1);
}
/*------------------------
	icons
-------------------------*/

.nucleo-icon-2x-drag-down::before {
  content: "\ea01";
}

.nucleo-icon-2x-drag-up::before {
  content: "\ea02";
}

.nucleo-icon-3d-29::before {
  content: "\ea03";
}

.nucleo-icon-3d-model::before {
  content: "\ea04";
}

.nucleo-icon-3d::before {
  content: "\ea05";
}

.nucleo-icon-a-add::before {
  content: "\ea06";
}

.nucleo-icon-a-check::before {
  content: "\ea07";
}

.nucleo-icon-a-edit::before {
  content: "\ea08";
}

.nucleo-icon-a-heart::before {
  content: "\ea09";
}

.nucleo-icon-a-remove::before {
  content: "\ea0a";
}

.nucleo-icon-accessibility::before {
  content: "\ea0b";
}

.nucleo-icon-active-38::before {
  content: "\ea0c";
}

.nucleo-icon-active-40::before {
  content: "\ea0d";
}

.nucleo-icon-add-27::before {
  content: "\ea0e";
}

.nucleo-icon-add-29::before {
  content: "\ea0f";
}

.nucleo-icon-add-e-2::before {
  content: "\ea10";
}

.nucleo-icon-add::before {
  content: "\ea11";
}

.nucleo-icon-agenda-bookmark::before {
  content: "\ea12";
}

.nucleo-icon-agenda::before {
  content: "\ea13";
}

.nucleo-icon-air-baloon::before {
  content: "\ea14";
}

.nucleo-icon-air-conditioner::before {
  content: "\ea15";
}

.nucleo-icon-airbag::before {
  content: "\ea18";
}

.nucleo-icon-alarm-add::before {
  content: "\ea16";
}

.nucleo-icon-alarm-delete::before {
  content: "\ea17";
}

.nucleo-icon-album-2::before {
  content: "\ea19";
}

.nucleo-icon-album::before {
  content: "\ea1a";
}

.nucleo-icon-alert-2::before {
  content: "\ea1b";
}

.nucleo-icon-alert-circle-exc::before {
  content: "\ea1c";
}

.nucleo-icon-alert-circle-i::before {
  content: "\ea1d";
}

.nucleo-icon-alert-circle-que::before {
  content: "\ea1e";
}

.nucleo-icon-alert-exc::before {
  content: "\ea1f";
}

.nucleo-icon-alert-i::before {
  content: "\ea20";
}

.nucleo-icon-alert-que::before {
  content: "\ea21";
}

.nucleo-icon-alert-square-exc::before {
  content: "\ea22";
}

.nucleo-icon-alert-square-i::before {
  content: "\ea23";
}

.nucleo-icon-alert-square-que::before {
  content: "\ea24";
}

.nucleo-icon-alert::before {
  content: "\ea25";
}

.nucleo-icon-alien-29::before {
  content: "\ea26";
}

.nucleo-icon-alien-33::before {
  content: "\ea27";
}

.nucleo-icon-align-bottom::before {
  content: "\ea28";
}

.nucleo-icon-align-center-horizontal::before {
  content: "\ea29";
}

.nucleo-icon-align-center-vertical::before {
  content: "\ea2a";
}

.nucleo-icon-align-center::before {
  content: "\ea2b";
}

.nucleo-icon-align-justify::before {
  content: "\ea2c";
}

.nucleo-icon-align-left-2::before {
  content: "\ea2d";
}

.nucleo-icon-align-left::before {
  content: "\ea2e";
}

.nucleo-icon-align-right-2::before {
  content: "\ea2f";
}

.nucleo-icon-align-right::before {
  content: "\ea30";
}

.nucleo-icon-align-top::before {
  content: "\ea31";
}

.nucleo-icon-analytics-88::before {
  content: "\ea32";
}

.nucleo-icon-analytics-89::before {
  content: "\ea33";
}

.nucleo-icon-analytics::before {
  content: "\ea36";
}

.nucleo-icon-anchor::before {
  content: "\ea34";
}

.nucleo-icon-android::before {
  content: "\ea37";
}

.nucleo-icon-angle::before {
  content: "\ea35";
}

.nucleo-icon-angry-10::before {
  content: "\ea38";
}

.nucleo-icon-angry-44::before {
  content: "\ea39";
}

.nucleo-icon-angry-e-08::before {
  content: "\ea3a";
}

.nucleo-icon-angry-e-17::before {
  content: "\ea3b";
}

.nucleo-icon-animation-14::before {
  content: "\ea3c";
}

.nucleo-icon-animation-31::before {
  content: "\ea3d";
}

.nucleo-icon-animation-32::before {
  content: "\ea3e";
}

.nucleo-icon-app::before {
  content: "\ea3f";
}

.nucleo-icon-apple-2::before {
  content: "\ea40";
}

.nucleo-icon-apple::before {
  content: "\ea41";
}

.nucleo-icon-appointment::before {
  content: "\ea42";
}

.nucleo-icon-apron::before {
  content: "\ea43";
}

.nucleo-icon-archive-2::before {
  content: "\ea44";
}

.nucleo-icon-archive-3d-content::before {
  content: "\ea45";
}

.nucleo-icon-archive-check::before {
  content: "\ea46";
}

.nucleo-icon-archive-content::before {
  content: "\ea47";
}

.nucleo-icon-archive-e-download::before {
  content: "\ea48";
}

.nucleo-icon-archive-e-upload::before {
  content: "\ea49";
}

.nucleo-icon-archive-paper::before {
  content: "\ea4a";
}

.nucleo-icon-archive::before {
  content: "\ea4b";
}

.nucleo-icon-armchair::before {
  content: "\ea4c";
}

.nucleo-icon-artboard::before {
  content: "\ea4d";
}

.nucleo-icon-astronaut::before {
  content: "\ea4e";
}

.nucleo-icon-at-sign::before {
  content: "\ea4f";
}

.nucleo-icon-atm::before {
  content: "\ea50";
}

.nucleo-icon-atom::before {
  content: "\ea51";
}

.nucleo-icon-attach-86::before {
  content: "\ea52";
}

.nucleo-icon-attach-87::before {
  content: "\ea53";
}

.nucleo-icon-aubergine::before {
  content: "\ea55";
}

.nucleo-icon-audio-91::before {
  content: "\ea54";
}

.nucleo-icon-audio-92::before {
  content: "\ea56";
}

.nucleo-icon-avocado::before {
  content: "\ea5a";
}

.nucleo-icon-award-48::before {
  content: "\ea57";
}

.nucleo-icon-award-49::before {
  content: "\ea58";
}

.nucleo-icon-award-55::before {
  content: "\ea59";
}

.nucleo-icon-award-74::before {
  content: "\ea5b";
}

.nucleo-icon-award::before {
  content: "\ea5c";
}

.nucleo-icon-axe::before {
  content: "\ea5d";
}

.nucleo-icon-baby-2::before {
  content: "\ea5e";
}

.nucleo-icon-baby-3::before {
  content: "\ea5f";
}

.nucleo-icon-baby-bottle::before {
  content: "\ea60";
}

.nucleo-icon-baby::before {
  content: "\ea61";
}

.nucleo-icon-back-78::before {
  content: "\ea62";
}

.nucleo-icon-back-80::before {
  content: "\ea63";
}

.nucleo-icon-background::before {
  content: "\ea64";
}

.nucleo-icon-backpack-2::before {
  content: "\ea65";
}

.nucleo-icon-backpack-57::before {
  content: "\ea66";
}

.nucleo-icon-backpack-58::before {
  content: "\ea67";
}

.nucleo-icon-backpack::before {
  content: "\ea68";
}

.nucleo-icon-backward::before {
  content: "\ea69";
}

.nucleo-icon-bacon::before {
  content: "\ea6a";
}

.nucleo-icon-badge-13::before {
  content: "\ea6b";
}

.nucleo-icon-badge-14::before {
  content: "\ea6c";
}

.nucleo-icon-badge-15::before {
  content: "\ea6d";
}

.nucleo-icon-badge::before {
  content: "\ea6e";
}

.nucleo-icon-badminton::before {
  content: "\ea6f";
}

.nucleo-icon-bag-09::before {
  content: "\ea70";
}

.nucleo-icon-bag-16::before {
  content: "\ea71";
}

.nucleo-icon-bag-17::before {
  content: "\ea72";
}

.nucleo-icon-bag-20::before {
  content: "\ea73";
}

.nucleo-icon-bag-21::before {
  content: "\ea74";
}

.nucleo-icon-bag-22::before {
  content: "\ea75";
}

.nucleo-icon-bag-49::before {
  content: "\ea76";
}

.nucleo-icon-bag-50::before {
  content: "\ea77";
}

.nucleo-icon-bag-add-18::before {
  content: "\ea78";
}

.nucleo-icon-bag-add-21::before {
  content: "\ea79";
}

.nucleo-icon-bag-delivery::before {
  content: "\ea7a";
}

.nucleo-icon-bag-edit::before {
  content: "\ea7b";
}

.nucleo-icon-bag-remove-19::before {
  content: "\ea7c";
}

.nucleo-icon-bag-remove-22::before {
  content: "\ea7d";
}

.nucleo-icon-bag-time::before {
  content: "\ea7e";
}

.nucleo-icon-bag::before {
  content: "\ea7f";
}

.nucleo-icon-bakery::before {
  content: "\ea80";
}

.nucleo-icon-balance::before {
  content: "\ea81";
}

.nucleo-icon-ball-basket::before {
  content: "\ea82";
}

.nucleo-icon-ball-soccer::before {
  content: "\ea83";
}

.nucleo-icon-baloon::before {
  content: "\ea84";
}

.nucleo-icon-ban::before {
  content: "\ea85";
}

.nucleo-icon-banana::before {
  content: "\ea86";
}

.nucleo-icon-bank::before {
  content: "\ea87";
}

.nucleo-icon-barbecue-tools::before {
  content: "\ea88";
}

.nucleo-icon-barcode-qr::before {
  content: "\ea89";
}

.nucleo-icon-barcode::before {
  content: "\ea8a";
}

.nucleo-icon-bars-2::before {
  content: "\ea8e";
}

.nucleo-icon-bars-rotate::before {
  content: "\ea91";
}

.nucleo-icon-bars::before {
  content: "\ea8b";
}

.nucleo-icon-baseball-ball::before {
  content: "\ea8c";
}

.nucleo-icon-baseball-bat::before {
  content: "\ea8d";
}

.nucleo-icon-basket-simple-add::before {
  content: "\ea8f";
}

.nucleo-icon-basket-simple-remove::before {
  content: "\ea90";
}

.nucleo-icon-basket-simple::before {
  content: "\ea92";
}

.nucleo-icon-basket::before {
  content: "\ea93";
}

.nucleo-icon-basketball-12::before {
  content: "\ea94";
}

.nucleo-icon-bat::before {
  content: "\ea95";
}

.nucleo-icon-battery-81::before {
  content: "\ea96";
}

.nucleo-icon-battery-83::before {
  content: "\ea97";
}

.nucleo-icon-battery-half::before {
  content: "\ea98";
}

.nucleo-icon-battery-level::before {
  content: "\ea99";
}

.nucleo-icon-battery-low::before {
  content: "\ea9a";
}

.nucleo-icon-battery::before {
  content: "\ea9b";
}

.nucleo-icon-bear-2::before {
  content: "\ea9c";
}

.nucleo-icon-bed-09::before {
  content: "\ea9d";
}

.nucleo-icon-bed-23::before {
  content: "\ea9e";
}

.nucleo-icon-bee::before {
  content: "\ea9f";
}

.nucleo-icon-beer-95::before {
  content: "\eaa0";
}

.nucleo-icon-beer-96::before {
  content: "\eaa1";
}

.nucleo-icon-bell-53::before {
  content: "\eaa2";
}

.nucleo-icon-bell-54::before {
  content: "\eaa3";
}

.nucleo-icon-bell-55::before {
  content: "\eaa4";
}

.nucleo-icon-belt::before {
  content: "\eaa5";
}

.nucleo-icon-beverage::before {
  content: "\eaa6";
}

.nucleo-icon-big-eyes::before {
  content: "\eaa7";
}

.nucleo-icon-big-smile::before {
  content: "\eaa8";
}

.nucleo-icon-bigmouth::before {
  content: "\eaa9";
}

.nucleo-icon-bike-2::before {
  content: "\eaab";
}

.nucleo-icon-bike::before {
  content: "\eaaa";
}

.nucleo-icon-bill::before {
  content: "\eab5";
}

.nucleo-icon-billiard::before {
  content: "\eaac";
}

.nucleo-icon-binocular::before {
  content: "\eaad";
}

.nucleo-icon-bitcoin::before {
  content: "\eaae";
}

.nucleo-icon-blend::before {
  content: "\eaaf";
}

.nucleo-icon-blender::before {
  content: "\eab6";
}

.nucleo-icon-block-bottom-left::before {
  content: "\eab0";
}

.nucleo-icon-block-bottom-right::before {
  content: "\eab1";
}

.nucleo-icon-block-down::before {
  content: "\eab2";
}

.nucleo-icon-block-left::before {
  content: "\eab3";
}

.nucleo-icon-block-right::before {
  content: "\eab4";
}

.nucleo-icon-block-top-left::before {
  content: "\eab7";
}

.nucleo-icon-block-top-right::before {
  content: "\eab8";
}

.nucleo-icon-block-up::before {
  content: "\eab9";
}

.nucleo-icon-blog::before {
  content: "\eaba";
}

.nucleo-icon-blueberries::before {
  content: "\eabb";
}

.nucleo-icon-bluetooth::before {
  content: "\eabc";
}

.nucleo-icon-board-27::before {
  content: "\eabd";
}

.nucleo-icon-board-28::before {
  content: "\eabe";
}

.nucleo-icon-board-51::before {
  content: "\eabf";
}

.nucleo-icon-board::before {
  content: "\eac0";
}

.nucleo-icon-boat-small-02::before {
  content: "\eac1";
}

.nucleo-icon-boat-small-03::before {
  content: "\eac2";
}

.nucleo-icon-boat::before {
  content: "\eac3";
}

.nucleo-icon-boiling-water::before {
  content: "\eac4";
}

.nucleo-icon-bold-add::before {
  content: "\eac5";
}

.nucleo-icon-bold-delete::before {
  content: "\eac6";
}

.nucleo-icon-bold-down::before {
  content: "\eac7";
}

.nucleo-icon-bold-left::before {
  content: "\eac8";
}

.nucleo-icon-bold-remove::before {
  content: "\eac9";
}

.nucleo-icon-bold-right::before {
  content: "\eaca";
}

.nucleo-icon-bold-up::before {
  content: "\eacb";
}

.nucleo-icon-bold::before {
  content: "\eacc";
}

.nucleo-icon-bolt::before {
  content: "\eacd";
}

.nucleo-icon-bomb::before {
  content: "\eace";
}

.nucleo-icon-bones::before {
  content: "\eacf";
}

.nucleo-icon-book-07::before {
  content: "\ead0";
}

.nucleo-icon-book-39::before {
  content: "\ead1";
}

.nucleo-icon-book-bookmark-2::before {
  content: "\ead2";
}

.nucleo-icon-book-bookmark::before {
  content: "\ead3";
}

.nucleo-icon-book-e::before {
  content: "\ead6";
}

.nucleo-icon-book-open-2::before {
  content: "\ead4";
}

.nucleo-icon-book-open::before {
  content: "\ead5";
}

.nucleo-icon-book::before {
  content: "\ead7";
}

.nucleo-icon-bookmark-2::before {
  content: "\ead8";
}

.nucleo-icon-bookmark-add-2::before {
  content: "\ead9";
}

.nucleo-icon-bookmark-add-e::before {
  content: "\eada";
}

.nucleo-icon-bookmark-add::before {
  content: "\eadb";
}

.nucleo-icon-bookmark-remove-2::before {
  content: "\eadc";
}

.nucleo-icon-bookmark-remove-e::before {
  content: "\eadd";
}

.nucleo-icon-bookmark-remove::before {
  content: "\eade";
}

.nucleo-icon-books-46::before {
  content: "\eadf";
}

.nucleo-icon-books::before {
  content: "\eae0";
}

.nucleo-icon-boot-2::before {
  content: "\eae1";
}

.nucleo-icon-boot-woman::before {
  content: "\eae2";
}

.nucleo-icon-boot::before {
  content: "\eae3";
}

.nucleo-icon-border-radius::before {
  content: "\eae5";
}

.nucleo-icon-border::before {
  content: "\eae6";
}

.nucleo-icon-bottle-wine::before {
  content: "\eae4";
}

.nucleo-icon-bottle::before {
  content: "\eae7";
}

.nucleo-icon-bow::before {
  content: "\eae8";
}

.nucleo-icon-bowl::before {
  content: "\eae9";
}

.nucleo-icon-bowling-pins::before {
  content: "\eaea";
}

.nucleo-icon-bowling::before {
  content: "\eaeb";
}

.nucleo-icon-box-2::before {
  content: "\eaec";
}

.nucleo-icon-box-ribbon::before {
  content: "\eaed";
}

.nucleo-icon-box::before {
  content: "\eaee";
}

.nucleo-icon-boxing-bag::before {
  content: "\eaef";
}

.nucleo-icon-boxing::before {
  content: "\eaf0";
}

.nucleo-icon-bra::before {
  content: "\eaf1";
}

.nucleo-icon-brain::before {
  content: "\eaf2";
}

.nucleo-icon-brakes::before {
  content: "\eaf3";
}

.nucleo-icon-bread::before {
  content: "\eaf4";
}

.nucleo-icon-briefcase-24::before {
  content: "\eaf5";
}

.nucleo-icon-briefcase-25::before {
  content: "\eaf6";
}

.nucleo-icon-briefcase-26::before {
  content: "\eaf7";
}

.nucleo-icon-brightness-46::before {
  content: "\eaf8";
}

.nucleo-icon-brightness-47::before {
  content: "\eaf9";
}

.nucleo-icon-brioche::before {
  content: "\eb01";
}

.nucleo-icon-broccoli::before {
  content: "\eafa";
}

.nucleo-icon-broom::before {
  content: "\eafb";
}

.nucleo-icon-brush::before {
  content: "\eafc";
}

.nucleo-icon-bucket::before {
  content: "\eafd";
}

.nucleo-icon-bug::before {
  content: "\eafe";
}

.nucleo-icon-building::before {
  content: "\eaff";
}

.nucleo-icon-bulb-61::before {
  content: "\eb00";
}

.nucleo-icon-bulb-62::before {
  content: "\eb02";
}

.nucleo-icon-bulb-63::before {
  content: "\eb03";
}

.nucleo-icon-bulb-saver::before {
  content: "\eb04";
}

.nucleo-icon-bulb::before {
  content: "\eb05";
}

.nucleo-icon-bullet-list-67::before {
  content: "\eb06";
}

.nucleo-icon-bullet-list-68::before {
  content: "\eb07";
}

.nucleo-icon-bullet-list-69::before {
  content: "\eb08";
}

.nucleo-icon-bullet-list-70::before {
  content: "\eb09";
}

.nucleo-icon-bullet-list::before {
  content: "\eb0a";
}

.nucleo-icon-bus-front-10::before {
  content: "\eb0b";
}

.nucleo-icon-bus-front-12::before {
  content: "\eb0c";
}

.nucleo-icon-businessman-03::before {
  content: "\eb0d";
}

.nucleo-icon-businessman-04::before {
  content: "\eb0e";
}

.nucleo-icon-butter::before {
  content: "\eb0f";
}

.nucleo-icon-butterfly::before {
  content: "\eb10";
}

.nucleo-icon-button-2::before {
  content: "\eb11";
}

.nucleo-icon-button-eject::before {
  content: "\eb12";
}

.nucleo-icon-button-next::before {
  content: "\eb13";
}

.nucleo-icon-button-pause::before {
  content: "\eb14";
}

.nucleo-icon-button-play::before {
  content: "\eb15";
}

.nucleo-icon-button-power::before {
  content: "\eb16";
}

.nucleo-icon-button-previous::before {
  content: "\eb17";
}

.nucleo-icon-button-record::before {
  content: "\eb18";
}

.nucleo-icon-button-rewind::before {
  content: "\eb19";
}

.nucleo-icon-button-skip::before {
  content: "\eb1a";
}

.nucleo-icon-button-stop::before {
  content: "\eb1b";
}

.nucleo-icon-button::before {
  content: "\eb1c";
}

.nucleo-icon-cabinet::before {
  content: "\eb1d";
}

.nucleo-icon-cable-49::before {
  content: "\eb1e";
}

.nucleo-icon-cactus::before {
  content: "\eb1f";
}

.nucleo-icon-cake-13::before {
  content: "\eb20";
}

.nucleo-icon-cake-slice::before {
  content: "\eb21";
}

.nucleo-icon-cake::before {
  content: "\eb22";
}

.nucleo-icon-calculator::before {
  content: "\eb23";
}

.nucleo-icon-calendar-57::before {
  content: "\eb24";
}

.nucleo-icon-calendar-60::before {
  content: "\eb25";
}

.nucleo-icon-calendar-add-2::before {
  content: "\eb26";
}

.nucleo-icon-calendar-add::before {
  content: "\eb27";
}

.nucleo-icon-calendar-check-59::before {
  content: "\eb28";
}

.nucleo-icon-calendar-check-62::before {
  content: "\eb29";
}

.nucleo-icon-calendar-grid-58::before {
  content: "\eb2a";
}

.nucleo-icon-calendar-grid-61::before {
  content: "\eb2b";
}

.nucleo-icon-camera-18::before {
  content: "\eb2c";
}

.nucleo-icon-camera-19::before {
  content: "\eb2d";
}

.nucleo-icon-camera-20::before {
  content: "\eb2e";
}

.nucleo-icon-camera-ban-37::before {
  content: "\eb2f";
}

.nucleo-icon-camera-compact::before {
  content: "\eb30";
}

.nucleo-icon-camera-square-57::before {
  content: "\eb31";
}

.nucleo-icon-camera-time::before {
  content: "\eb32";
}

.nucleo-icon-camera::before {
  content: "\eb33";
}

.nucleo-icon-camping::before {
  content: "\eb34";
}

.nucleo-icon-can::before {
  content: "\eb36";
}

.nucleo-icon-candle::before {
  content: "\eb35";
}

.nucleo-icon-candy-2::before {
  content: "\eb37";
}

.nucleo-icon-candy::before {
  content: "\eb38";
}

.nucleo-icon-canvas::before {
  content: "\eb39";
}

.nucleo-icon-cap::before {
  content: "\eb3a";
}

.nucleo-icon-capitalize::before {
  content: "\eb3b";
}

.nucleo-icon-caps-small::before {
  content: "\eb3c";
}

.nucleo-icon-car-2::before {
  content: "\eb3d";
}

.nucleo-icon-car-accident::before {
  content: "\eb3e";
}

.nucleo-icon-car-connect::before {
  content: "\eb3f";
}

.nucleo-icon-car-door::before {
  content: "\eb41";
}

.nucleo-icon-car-front::before {
  content: "\eb40";
}

.nucleo-icon-car-lights::before {
  content: "\eb42";
}

.nucleo-icon-car-parking::before {
  content: "\eb43";
}

.nucleo-icon-car-taxi::before {
  content: "\eb44";
}

.nucleo-icon-car-ventilation::before {
  content: "\eb45";
}

.nucleo-icon-car-wash::before {
  content: "\eb46";
}

.nucleo-icon-car::before {
  content: "\eb47";
}

.nucleo-icon-card-add-2::before {
  content: "\eb48";
}

.nucleo-icon-card-add::before {
  content: "\eb49";
}

.nucleo-icon-card-alert::before {
  content: "\eb4a";
}

.nucleo-icon-card-edit::before {
  content: "\eb4b";
}

.nucleo-icon-card-favorite::before {
  content: "\eb4c";
}

.nucleo-icon-card-remove::before {
  content: "\eb4d";
}

.nucleo-icon-cardio::before {
  content: "\eb4e";
}

.nucleo-icon-cards::before {
  content: "\eb4f";
}

.nucleo-icon-carrot::before {
  content: "\eb50";
}

.nucleo-icon-cart-9::before {
  content: "\eb51";
}

.nucleo-icon-cart-favorite::before {
  content: "\eb52";
}

.nucleo-icon-cart-in-9::before {
  content: "\eb53";
}

.nucleo-icon-cart-return::before {
  content: "\eb54";
}

.nucleo-icon-cart-simple-add::before {
  content: "\eb55";
}

.nucleo-icon-cart-simple-in::before {
  content: "\eb56";
}

.nucleo-icon-cart-simple-remove::before {
  content: "\eb57";
}

.nucleo-icon-cart-simple::before {
  content: "\eb58";
}

.nucleo-icon-cart-speed::before {
  content: "\eb59";
}

.nucleo-icon-cart::before {
  content: "\eb5a";
}

.nucleo-icon-cash-register::before {
  content: "\eb5b";
}

.nucleo-icon-cat::before {
  content: "\eb5c";
}

.nucleo-icon-cauldron::before {
  content: "\eb5d";
}

.nucleo-icon-cctv::before {
  content: "\eb5e";
}

.nucleo-icon-cd-reader::before {
  content: "\eb5f";
}

.nucleo-icon-celsius::before {
  content: "\eb60";
}

.nucleo-icon-centralize::before {
  content: "\eb61";
}

.nucleo-icon-chair-2::before {
  content: "\eb62";
}

.nucleo-icon-chair::before {
  content: "\eb63";
}

.nucleo-icon-champagne::before {
  content: "\eb64";
}

.nucleo-icon-chart-2::before {
  content: "\eb66";
}

.nucleo-icon-chart-3::before {
  content: "\eb65";
}

.nucleo-icon-chart-bar-32::before {
  content: "\eb67";
}

.nucleo-icon-chart-bar-52::before {
  content: "\eb68";
}

.nucleo-icon-chart-bar-53::before {
  content: "\eb69";
}

.nucleo-icon-chart-bars::before {
  content: "\eb6a";
}

.nucleo-icon-chart-growth::before {
  content: "\eb6b";
}

.nucleo-icon-chart-pie-35::before {
  content: "\eb6c";
}

.nucleo-icon-chart-pie-36::before {
  content: "\eb6d";
}

.nucleo-icon-chart::before {
  content: "\eb6e";
}

.nucleo-icon-chat-33::before {
  content: "\eb6f";
}

.nucleo-icon-chat-45::before {
  content: "\eb70";
}

.nucleo-icon-chat-46::before {
  content: "\eb71";
}

.nucleo-icon-chat-content::before {
  content: "\eb72";
}

.nucleo-icon-chat-reply::before {
  content: "\eb73";
}

.nucleo-icon-chat-round-content::before {
  content: "\eb74";
}

.nucleo-icon-chat-round::before {
  content: "\eb75";
}

.nucleo-icon-chat::before {
  content: "\eb76";
}

.nucleo-icon-check-2::before {
  content: "\eb77";
}

.nucleo-icon-check-all::before {
  content: "\eb7a";
}

.nucleo-icon-check-bold::before {
  content: "\eb78";
}

.nucleo-icon-check-circle-07::before {
  content: "\eb79";
}

.nucleo-icon-check-circle-08::before {
  content: "\eb7b";
}

.nucleo-icon-check-curve::before {
  content: "\eb7c";
}

.nucleo-icon-check-in::before {
  content: "\eb7d";
}

.nucleo-icon-check-out::before {
  content: "\eb7e";
}

.nucleo-icon-check-simple::before {
  content: "\eb7f";
}

.nucleo-icon-check-small::before {
  content: "\eb80";
}

.nucleo-icon-check-square-09::before {
  content: "\eb81";
}

.nucleo-icon-check-square-11::before {
  content: "\eb82";
}

.nucleo-icon-cheese-24::before {
  content: "\eb83";
}

.nucleo-icon-cheese-87::before {
  content: "\eb84";
}

.nucleo-icon-cheeseburger::before {
  content: "\eb85";
}

.nucleo-icon-chef-hat::before {
  content: "\eb86";
}

.nucleo-icon-chef::before {
  content: "\eb87";
}

.nucleo-icon-cheque-2::before {
  content: "\eb88";
}

.nucleo-icon-cheque-3::before {
  content: "\eb89";
}

.nucleo-icon-cheque::before {
  content: "\eb8a";
}

.nucleo-icon-cherry::before {
  content: "\eb8b";
}

.nucleo-icon-chicken-2::before {
  content: "\eb8c";
}

.nucleo-icon-chicken::before {
  content: "\eb8d";
}

.nucleo-icon-chili::before {
  content: "\eb8e";
}

.nucleo-icon-chimney::before {
  content: "\eb8f";
}

.nucleo-icon-chips::before {
  content: "\eb90";
}

.nucleo-icon-choco-cream::before {
  content: "\eb91";
}

.nucleo-icon-chocolate-mousse::before {
  content: "\eb92";
}

.nucleo-icon-chocolate::before {
  content: "\eb93";
}

.nucleo-icon-churros::before {
  content: "\eb94";
}

.nucleo-icon-circle-02::before {
  content: "\eb95";
}

.nucleo-icon-circle-03::before {
  content: "\eb96";
}

.nucleo-icon-circle-04::before {
  content: "\eb97";
}

.nucleo-icon-circle-08::before {
  content: "\eb98";
}

.nucleo-icon-circle-09::before {
  content: "\eb99";
}

.nucleo-icon-circle-10::before {
  content: "\eb9a";
}

.nucleo-icon-circle-add::before {
  content: "\eb9b";
}

.nucleo-icon-circle-bold-add::before {
  content: "\eb9c";
}

.nucleo-icon-circle-bold-delete::before {
  content: "\eb9d";
}

.nucleo-icon-circle-bold-remove::before {
  content: "\eb9e";
}

.nucleo-icon-circle-delete::before {
  content: "\eb9f";
}

.nucleo-icon-circle-down-12::before {
  content: "\eba0";
}

.nucleo-icon-circle-down-40::before {
  content: "\eba1";
}

.nucleo-icon-circle-e-down-04::before {
  content: "\eba2";
}

.nucleo-icon-circle-e-down-12::before {
  content: "\eba3";
}

.nucleo-icon-circle-e-left-02::before {
  content: "\eba4";
}

.nucleo-icon-circle-e-left-10::before {
  content: "\eba5";
}

.nucleo-icon-circle-e-right-01::before {
  content: "\eba6";
}

.nucleo-icon-circle-e-right-09::before {
  content: "\eba7";
}

.nucleo-icon-circle-e-up-03::before {
  content: "\eba8";
}

.nucleo-icon-circle-e-up-11::before {
  content: "\eba9";
}

.nucleo-icon-circle-left-10::before {
  content: "\ebaa";
}

.nucleo-icon-circle-left-38::before {
  content: "\ebab";
}

.nucleo-icon-circle-remove::before {
  content: "\ebac";
}

.nucleo-icon-circle-right-09::before {
  content: "\ebad";
}

.nucleo-icon-circle-right-37::before {
  content: "\ebae";
}

.nucleo-icon-circle-simple-down::before {
  content: "\ebaf";
}

.nucleo-icon-circle-simple-left::before {
  content: "\ebb0";
}

.nucleo-icon-circle-simple-right::before {
  content: "\ebb1";
}

.nucleo-icon-circle-simple-up::before {
  content: "\ebb2";
}

.nucleo-icon-circle-up-11::before {
  content: "\ebb3";
}

.nucleo-icon-circle-up-39::before {
  content: "\ebb4";
}

.nucleo-icon-circle::before {
  content: "\ebb5";
}

.nucleo-icon-clone::before {
  content: "\ebb6";
}

.nucleo-icon-cloud-01::before {
  content: "\ebb7";
}

.nucleo-icon-cloud-02::before {
  content: "\ebb8";
}

.nucleo-icon-cloud-25::before {
  content: "\ebb9";
}

.nucleo-icon-cloud-26::before {
  content: "\ebba";
}

.nucleo-icon-cloud-download-95::before {
  content: "\ebbb";
}

.nucleo-icon-cloud-drop::before {
  content: "\ebbc";
}

.nucleo-icon-cloud-fog-31::before {
  content: "\ebbd";
}

.nucleo-icon-cloud-fog-32::before {
  content: "\ebbe";
}

.nucleo-icon-cloud-hail::before {
  content: "\ebbf";
}

.nucleo-icon-cloud-light::before {
  content: "\ebc0";
}

.nucleo-icon-cloud-moon::before {
  content: "\ebc1";
}

.nucleo-icon-cloud-rain::before {
  content: "\ebc2";
}

.nucleo-icon-cloud-snow-34::before {
  content: "\ebc3";
}

.nucleo-icon-cloud-snow-42::before {
  content: "\ebc4";
}

.nucleo-icon-cloud-sun-17::before {
  content: "\ebc5";
}

.nucleo-icon-cloud-upload-96::before {
  content: "\ebc6";
}

.nucleo-icon-clover::before {
  content: "\ebc7";
}

.nucleo-icon-coat-hanger::before {
  content: "\ebc8";
}

.nucleo-icon-cockade::before {
  content: "\ebc9";
}

.nucleo-icon-cocktail::before {
  content: "\ebca";
}

.nucleo-icon-code::before {
  content: "\ebcb";
}

.nucleo-icon-coffe-long::before {
  content: "\ebcc";
}

.nucleo-icon-coffee-2::before {
  content: "\ebcd";
}

.nucleo-icon-coffee-bean::before {
  content: "\ebce";
}

.nucleo-icon-coffee-long::before {
  content: "\ebcf";
}

.nucleo-icon-coffee::before {
  content: "\ebd0";
}

.nucleo-icon-coffin::before {
  content: "\ebd1";
}

.nucleo-icon-coins::before {
  content: "\ebd2";
}

.nucleo-icon-collar::before {
  content: "\ebd3";
}

.nucleo-icon-collection::before {
  content: "\ebd4";
}

.nucleo-icon-color::before {
  content: "\ebd5";
}

.nucleo-icon-command::before {
  content: "\ebd6";
}

.nucleo-icon-comment-add::before {
  content: "\ebd7";
}

.nucleo-icon-compare::before {
  content: "\ebd8";
}

.nucleo-icon-compass-04::before {
  content: "\ebd9";
}

.nucleo-icon-compass-05::before {
  content: "\ebda";
}

.nucleo-icon-compass-06::before {
  content: "\ebdb";
}

.nucleo-icon-compass-2::before {
  content: "\ebdd";
}

.nucleo-icon-compass-3::before {
  content: "\ebdc";
}

.nucleo-icon-compass::before {
  content: "\ebde";
}

.nucleo-icon-components::before {
  content: "\ebdf";
}

.nucleo-icon-computer-monitor::before {
  content: "\ebe0";
}

.nucleo-icon-computer-old::before {
  content: "\ebe1";
}

.nucleo-icon-computer::before {
  content: "\ebe2";
}

.nucleo-icon-cone::before {
  content: "\ebe3";
}

.nucleo-icon-connect::before {
  content: "\ebe4";
}

.nucleo-icon-connection::before {
  content: "\ebe5";
}

.nucleo-icon-contacts-44::before {
  content: "\ebe6";
}

.nucleo-icon-contacts-45::before {
  content: "\ebe7";
}

.nucleo-icon-contacts::before {
  content: "\ebe8";
}

.nucleo-icon-contrast-2::before {
  content: "\ebe9";
}

.nucleo-icon-contrast::before {
  content: "\ebea";
}

.nucleo-icon-controller-2::before {
  content: "\ebeb";
}

.nucleo-icon-controller-3::before {
  content: "\ebec";
}

.nucleo-icon-controller::before {
  content: "\ebed";
}

.nucleo-icon-conversion::before {
  content: "\ebee";
}

.nucleo-icon-cookies::before {
  content: "\ebef";
}

.nucleo-icon-copy-2::before {
  content: "\ebf0";
}

.nucleo-icon-copy::before {
  content: "\ebf1";
}

.nucleo-icon-corn::before {
  content: "\ebf2";
}

.nucleo-icon-corner-down-round::before {
  content: "\ebf3";
}

.nucleo-icon-corner-down::before {
  content: "\ebf4";
}

.nucleo-icon-corner-left-down::before {
  content: "\ebf5";
}

.nucleo-icon-corner-left-round::before {
  content: "\ebf6";
}

.nucleo-icon-corner-left::before {
  content: "\ebf7";
}

.nucleo-icon-corner-right-down::before {
  content: "\ebf8";
}

.nucleo-icon-corner-right-round::before {
  content: "\ebf9";
}

.nucleo-icon-corner-right::before {
  content: "\ebfa";
}

.nucleo-icon-corner-up-left::before {
  content: "\ebfb";
}

.nucleo-icon-corner-up-right::before {
  content: "\ebfc";
}

.nucleo-icon-corner-up-round::before {
  content: "\ebfd";
}

.nucleo-icon-corner-up::before {
  content: "\ebfe";
}

.nucleo-icon-corset::before {
  content: "\ebff";
}

.nucleo-icon-countdown-34::before {
  content: "\ec00";
}

.nucleo-icon-coupon::before {
  content: "\ec01";
}

.nucleo-icon-cow::before {
  content: "\ec02";
}

.nucleo-icon-crab::before {
  content: "\ec03";
}

.nucleo-icon-cradle::before {
  content: "\ec04";
}

.nucleo-icon-credit-card-in::before {
  content: "\ec05";
}

.nucleo-icon-credit-card::before {
  content: "\ec06";
}

.nucleo-icon-crepe::before {
  content: "\ec07";
}

.nucleo-icon-cricket::before {
  content: "\ec08";
}

.nucleo-icon-croissant::before {
  content: "\ec09";
}

.nucleo-icon-crop::before {
  content: "\ec0a";
}

.nucleo-icon-cross-down::before {
  content: "\ec0b";
}

.nucleo-icon-cross-left::before {
  content: "\ec0c";
}

.nucleo-icon-cross-right::before {
  content: "\ec0d";
}

.nucleo-icon-cross-up::before {
  content: "\ec0e";
}

.nucleo-icon-cross::before {
  content: "\ec0f";
}

.nucleo-icon-crosshair::before {
  content: "\ec10";
}

.nucleo-icon-crown::before {
  content: "\ec11";
}

.nucleo-icon-crumpet::before {
  content: "\ec12";
}

.nucleo-icon-cry-15::before {
  content: "\ec13";
}

.nucleo-icon-css3::before {
  content: "\ec14";
}

.nucleo-icon-cupcake::before {
  content: "\ec15";
}

.nucleo-icon-curling-stone::before {
  content: "\ec16";
}

.nucleo-icon-currency-dollar::before {
  content: "\ec17";
}

.nucleo-icon-currency-euro::before {
  content: "\ec18";
}

.nucleo-icon-currency-exchange::before {
  content: "\ec19";
}

.nucleo-icon-currency-pound::before {
  content: "\ec1a";
}

.nucleo-icon-currency-yen::before {
  content: "\ec1b";
}

.nucleo-icon-cursor-48::before {
  content: "\ec1c";
}

.nucleo-icon-cursor-49::before {
  content: "\ec1d";
}

.nucleo-icon-cursor-add::before {
  content: "\ec1e";
}

.nucleo-icon-cursor-grab::before {
  content: "\ec1f";
}

.nucleo-icon-cursor-load::before {
  content: "\ec20";
}

.nucleo-icon-cursor-menu::before {
  content: "\ec21";
}

.nucleo-icon-cursor-not-allowed::before {
  content: "\ec22";
}

.nucleo-icon-cursor-pointer::before {
  content: "\ec23";
}

.nucleo-icon-cursor-text::before {
  content: "\ec24";
}

.nucleo-icon-curtain::before {
  content: "\ec25";
}

.nucleo-icon-curved-next::before {
  content: "\ec26";
}

.nucleo-icon-curved-previous::before {
  content: "\ec27";
}

.nucleo-icon-customer-support::before {
  content: "\ec28";
}

.nucleo-icon-cute::before {
  content: "\ec29";
}

.nucleo-icon-cutlery-75::before {
  content: "\ec2a";
}

.nucleo-icon-cutlery-76::before {
  content: "\ec2b";
}

.nucleo-icon-cutlery-77::before {
  content: "\ec2c";
}

.nucleo-icon-cutlery::before {
  content: "\ec2e";
}

.nucleo-icon-cyclist::before {
  content: "\ec2d";
}

.nucleo-icon-dancer::before {
  content: "\ec2f";
}

.nucleo-icon-dart::before {
  content: "\ec30";
}

.nucleo-icon-dashboard-29::before {
  content: "\ec31";
}

.nucleo-icon-dashboard-30::before {
  content: "\ec32";
}

.nucleo-icon-dashboard-half::before {
  content: "\ec33";
}

.nucleo-icon-dashboard-level::before {
  content: "\ec36";
}

.nucleo-icon-database::before {
  content: "\ec34";
}

.nucleo-icon-deer::before {
  content: "\ec35";
}

.nucleo-icon-delete-28::before {
  content: "\ec37";
}

.nucleo-icon-delete-30::before {
  content: "\ec38";
}

.nucleo-icon-delete-49::before {
  content: "\ec39";
}

.nucleo-icon-delete-50::before {
  content: "\ec3a";
}

.nucleo-icon-delivery-2::before {
  content: "\ec3b";
}

.nucleo-icon-delivery-fast::before {
  content: "\ec3c";
}

.nucleo-icon-delivery::before {
  content: "\ec3d";
}

.nucleo-icon-design-system::before {
  content: "\ec3e";
}

.nucleo-icon-design::before {
  content: "\ec3f";
}

.nucleo-icon-desk::before {
  content: "\ec40";
}

.nucleo-icon-desktop-screen::before {
  content: "\ec41";
}

.nucleo-icon-desktop::before {
  content: "\ec42";
}

.nucleo-icon-detox::before {
  content: "\ec43";
}

.nucleo-icon-device-connection::before {
  content: "\ec44";
}

.nucleo-icon-devil::before {
  content: "\ec45";
}

.nucleo-icon-diag-bottom-left::before {
  content: "\ec46";
}

.nucleo-icon-diag-bottom-right::before {
  content: "\ec47";
}

.nucleo-icon-diag-top-left::before {
  content: "\ec48";
}

.nucleo-icon-diag-top-right::before {
  content: "\ec49";
}

.nucleo-icon-diamond::before {
  content: "\ec4a";
}

.nucleo-icon-dice::before {
  content: "\ec4b";
}

.nucleo-icon-diet-food::before {
  content: "\ec4c";
}

.nucleo-icon-diet-plan::before {
  content: "\ec4d";
}

.nucleo-icon-diet::before {
  content: "\ec4e";
}

.nucleo-icon-direction-53::before {
  content: "\ec4f";
}

.nucleo-icon-direction-56::before {
  content: "\ec50";
}

.nucleo-icon-direction::before {
  content: "\ec51";
}

.nucleo-icon-directions::before {
  content: "\ec52";
}

.nucleo-icon-disabled::before {
  content: "\ec53";
}

.nucleo-icon-discord::before {
  content: "\ec55";
}

.nucleo-icon-discount-2::before {
  content: "\ec54";
}

.nucleo-icon-discount::before {
  content: "\ec56";
}

.nucleo-icon-dish::before {
  content: "\ec57";
}

.nucleo-icon-disk-2::before {
  content: "\ec58";
}

.nucleo-icon-disk-reader::before {
  content: "\ec59";
}

.nucleo-icon-disk::before {
  content: "\ec5a";
}

.nucleo-icon-disperse::before {
  content: "\ec5b";
}

.nucleo-icon-distance::before {
  content: "\ec5e";
}

.nucleo-icon-distribute-horizontal::before {
  content: "\ec5c";
}

.nucleo-icon-distribute-vertical::before {
  content: "\ec5d";
}

.nucleo-icon-divider::before {
  content: "\ec5f";
}

.nucleo-icon-dna-27::before {
  content: "\ec60";
}

.nucleo-icon-dna-38::before {
  content: "\ec61";
}

.nucleo-icon-dock-bottom-2::before {
  content: "\ec62";
}

.nucleo-icon-dock-bottom::before {
  content: "\ec63";
}

.nucleo-icon-dock-left-2::before {
  content: "\ec64";
}

.nucleo-icon-dock-left::before {
  content: "\ec65";
}

.nucleo-icon-dock-right-2::before {
  content: "\ec66";
}

.nucleo-icon-dock-right::before {
  content: "\ec67";
}

.nucleo-icon-dock-top-2::before {
  content: "\ec68";
}

.nucleo-icon-dock-top::before {
  content: "\ec69";
}

.nucleo-icon-dock::before {
  content: "\ec6a";
}

.nucleo-icon-dog-house::before {
  content: "\ec6b";
}

.nucleo-icon-dog::before {
  content: "\ec6c";
}

.nucleo-icon-donut::before {
  content: "\ec6d";
}

.nucleo-icon-door::before {
  content: "\ec6e";
}

.nucleo-icon-dots-05::before {
  content: "\ec6f";
}

.nucleo-icon-dots-06::before {
  content: "\ec71";
}

.nucleo-icon-dots-07::before {
  content: "\ec72";
}

.nucleo-icon-dots-download::before {
  content: "\ec70";
}

.nucleo-icon-dots-upload::before {
  content: "\ec73";
}

.nucleo-icon-dots::before {
  content: "\ec74";
}

.nucleo-icon-double-left::before {
  content: "\ec75";
}

.nucleo-icon-double-right::before {
  content: "\ec76";
}

.nucleo-icon-double-tap::before {
  content: "\ec77";
}

.nucleo-icon-download-2::before {
  content: "\ec78";
}

.nucleo-icon-download::before {
  content: "\ec79";
}

.nucleo-icon-drag-21::before {
  content: "\ec7a";
}

.nucleo-icon-drag-31::before {
  content: "\ec7b";
}

.nucleo-icon-drag-down::before {
  content: "\ec7c";
}

.nucleo-icon-drag-left::before {
  content: "\ec7d";
}

.nucleo-icon-drag-right::before {
  content: "\ec7e";
}

.nucleo-icon-drag-up::before {
  content: "\ec7f";
}

.nucleo-icon-drawer-2::before {
  content: "\ec80";
}

.nucleo-icon-drawer::before {
  content: "\ec81";
}

.nucleo-icon-dress-woman::before {
  content: "\ec82";
}

.nucleo-icon-drink-2::before {
  content: "\ec83";
}

.nucleo-icon-drink-list::before {
  content: "\ec85";
}

.nucleo-icon-drink::before {
  content: "\ec84";
}

.nucleo-icon-drinking-bottle::before {
  content: "\ec86";
}

.nucleo-icon-drop-15::before {
  content: "\ec87";
}

.nucleo-icon-drop::before {
  content: "\ec88";
}

.nucleo-icon-drops::before {
  content: "\ec89";
}

.nucleo-icon-dumbbells::before {
  content: "\ec8a";
}

.nucleo-icon-e-check::before {
  content: "\ec8b";
}

.nucleo-icon-eclipse::before {
  content: "\ec8c";
}

.nucleo-icon-edit-2::before {
  content: "\ec8d";
}

.nucleo-icon-edit-71::before {
  content: "\ec8e";
}

.nucleo-icon-edit-72::before {
  content: "\ec8f";
}

.nucleo-icon-edit-73::before {
  content: "\ec90";
}

.nucleo-icon-edit-74::before {
  content: "\ec91";
}

.nucleo-icon-edit-75::before {
  content: "\ec92";
}

.nucleo-icon-edit-76::before {
  content: "\ec93";
}

.nucleo-icon-edit-77::before {
  content: "\ec94";
}

.nucleo-icon-edit-78::before {
  content: "\ec95";
}

.nucleo-icon-edit-color::before {
  content: "\ec96";
}

.nucleo-icon-edit-contrast-42::before {
  content: "\ec97";
}

.nucleo-icon-edit-contrast-43::before {
  content: "\ec98";
}

.nucleo-icon-edit-saturation::before {
  content: "\ec99";
}

.nucleo-icon-egg-38::before {
  content: "\ec9a";
}

.nucleo-icon-egg-39::before {
  content: "\ec9b";
}

.nucleo-icon-egg::before {
  content: "\ec9c";
}

.nucleo-icon-eggs::before {
  content: "\ec9e";
}

.nucleo-icon-eject::before {
  content: "\ec9d";
}

.nucleo-icon-email-83::before {
  content: "\ec9f";
}

.nucleo-icon-email-84::before {
  content: "\eca0";
}

.nucleo-icon-email-85::before {
  content: "\eca1";
}

.nucleo-icon-energy-drink::before {
  content: "\eca2";
}

.nucleo-icon-energy::before {
  content: "\eca3";
}

.nucleo-icon-engine-start::before {
  content: "\eca4";
}

.nucleo-icon-engine::before {
  content: "\eca6";
}

.nucleo-icon-enlarge-21::before {
  content: "\eca5";
}

.nucleo-icon-enlarge-22::before {
  content: "\eca7";
}

.nucleo-icon-enlarge-45::before {
  content: "\eca8";
}

.nucleo-icon-enlarge-46::before {
  content: "\eca9";
}

.nucleo-icon-enlarge-57::before {
  content: "\ecaa";
}

.nucleo-icon-enlarge-58::before {
  content: "\ecab";
}

.nucleo-icon-enlarge-circle::before {
  content: "\ecac";
}

.nucleo-icon-enlarge-diagonal-43::before {
  content: "\ecad";
}

.nucleo-icon-enlarge-diagonal-44::before {
  content: "\ecae";
}

.nucleo-icon-enlarge-horizontal::before {
  content: "\ecaf";
}

.nucleo-icon-enlarge-vertical::before {
  content: "\ecb0";
}

.nucleo-icon-eraser-32::before {
  content: "\ecb1";
}

.nucleo-icon-eraser-33::before {
  content: "\ecb2";
}

.nucleo-icon-eraser-46::before {
  content: "\ecb3";
}

.nucleo-icon-event-confirm::before {
  content: "\ecb5";
}

.nucleo-icon-event-create::before {
  content: "\ecb6";
}

.nucleo-icon-expand::before {
  content: "\ecb4";
}

.nucleo-icon-explore-2::before {
  content: "\ecb7";
}

.nucleo-icon-explore::before {
  content: "\ecb8";
}

.nucleo-icon-export::before {
  content: "\ecb9";
}

.nucleo-icon-eye-17::before {
  content: "\ecba";
}

.nucleo-icon-eye-19::before {
  content: "\ecbb";
}

.nucleo-icon-eye-ban-18::before {
  content: "\ecbc";
}

.nucleo-icon-eye-ban-20::before {
  content: "\ecbd";
}

.nucleo-icon-factory::before {
  content: "\ecbe";
}

.nucleo-icon-fahrenheit::before {
  content: "\ecbf";
}

.nucleo-icon-fav-remove::before {
  content: "\ecc0";
}

.nucleo-icon-favourite-28::before {
  content: "\ecc2";
}

.nucleo-icon-favourite-31::before {
  content: "\ecc1";
}

.nucleo-icon-favourite-add-29::before {
  content: "\ecc3";
}

.nucleo-icon-favourite-add-32::before {
  content: "\ecc4";
}

.nucleo-icon-favourite-remove-30::before {
  content: "\ecc5";
}

.nucleo-icon-favourite-remove-33::before {
  content: "\ecc6";
}

.nucleo-icon-fencing::before {
  content: "\ecc7";
}

.nucleo-icon-file-add::before {
  content: "\ecc8";
}

.nucleo-icon-file-delete::before {
  content: "\ecc9";
}

.nucleo-icon-file-download-87::before {
  content: "\ecca";
}

.nucleo-icon-file-upload-86::before {
  content: "\eccb";
}

.nucleo-icon-filter-check::before {
  content: "\eccc";
}

.nucleo-icon-filter-organization::before {
  content: "\eccd";
}

.nucleo-icon-filter-remove::before {
  content: "\ecce";
}

.nucleo-icon-filter::before {
  content: "\eccf";
}

.nucleo-icon-fire::before {
  content: "\ecd0";
}

.nucleo-icon-firewall::before {
  content: "\ecd1";
}

.nucleo-icon-fish::before {
  content: "\ecd2";
}

.nucleo-icon-fishing::before {
  content: "\ecd3";
}

.nucleo-icon-fist::before {
  content: "\ecd4";
}

.nucleo-icon-flag-diagonal-33::before {
  content: "\ecd5";
}

.nucleo-icon-flag-diagonal-34::before {
  content: "\ecd6";
}

.nucleo-icon-flag-e-06::before {
  content: "\ecd7";
}

.nucleo-icon-flag-e-07::before {
  content: "\ecd8";
}

.nucleo-icon-flag-finish::before {
  content: "\ecd9";
}

.nucleo-icon-flag-points-31::before {
  content: "\ecda";
}

.nucleo-icon-flag-points-32::before {
  content: "\ecdb";
}

.nucleo-icon-flag-simple::before {
  content: "\ecdc";
}

.nucleo-icon-flag-triangle::before {
  content: "\ecdd";
}

.nucleo-icon-flag::before {
  content: "\ecde";
}

.nucleo-icon-flame::before {
  content: "\ecdf";
}

.nucleo-icon-flash-21::before {
  content: "\ece0";
}

.nucleo-icon-flash-24::before {
  content: "\ece3";
}

.nucleo-icon-flash-29::before {
  content: "\ece2";
}

.nucleo-icon-flask-2::before {
  content: "\ece1";
}

.nucleo-icon-flask::before {
  content: "\ece4";
}

.nucleo-icon-flip-horizontal::before {
  content: "\ece5";
}

.nucleo-icon-flip-vertical::before {
  content: "\ece6";
}

.nucleo-icon-flip::before {
  content: "\ece7";
}

.nucleo-icon-flower-05::before {
  content: "\ece8";
}

.nucleo-icon-flower-06::before {
  content: "\ece9";
}

.nucleo-icon-flower-07::before {
  content: "\ecea";
}

.nucleo-icon-focus-32::before {
  content: "\eceb";
}

.nucleo-icon-focus-38::before {
  content: "\ecec";
}

.nucleo-icon-focus-40::before {
  content: "\eced";
}

.nucleo-icon-focus-circle::before {
  content: "\ecee";
}

.nucleo-icon-focus::before {
  content: "\ecef";
}

.nucleo-icon-fog::before {
  content: "\ecf0";
}

.nucleo-icon-folder-13::before {
  content: "\ecf1";
}

.nucleo-icon-folder-14::before {
  content: "\ecf2";
}

.nucleo-icon-folder-15::before {
  content: "\ecf3";
}

.nucleo-icon-folder-16::before {
  content: "\ecf4";
}

.nucleo-icon-folder-17::before {
  content: "\ecf5";
}

.nucleo-icon-folder-18::before {
  content: "\ecf6";
}

.nucleo-icon-folder-add-e-2::before {
  content: "\ecf7";
}

.nucleo-icon-folder-add::before {
  content: "\ecf8";
}

.nucleo-icon-folder-e-02::before {
  content: "\ecf9";
}

.nucleo-icon-folder-e-03::before {
  content: "\ecfa";
}

.nucleo-icon-folder-remove-e-2::before {
  content: "\ecfb";
}

.nucleo-icon-folder-remove::before {
  content: "\ecfc";
}

.nucleo-icon-food-course::before {
  content: "\ecfd";
}

.nucleo-icon-food-dog::before {
  content: "\ecfe";
}

.nucleo-icon-food-scale::before {
  content: "\ed00";
}

.nucleo-icon-football-headguard::before {
  content: "\ecff";
}

.nucleo-icon-forecast::before {
  content: "\ed01";
}

.nucleo-icon-form::before {
  content: "\ed02";
}

.nucleo-icon-format-left::before {
  content: "\ed03";
}

.nucleo-icon-format-right::before {
  content: "\ed04";
}

.nucleo-icon-forward::before {
  content: "\ed05";
}

.nucleo-icon-frame-12::before {
  content: "\ed06";
}

.nucleo-icon-frame-41::before {
  content: "\ed07";
}

.nucleo-icon-frame::before {
  content: "\ed08";
}

.nucleo-icon-fridge::before {
  content: "\ed09";
}

.nucleo-icon-fuel-2::before {
  content: "\ed0a";
}

.nucleo-icon-fuel-electric::before {
  content: "\ed0b";
}

.nucleo-icon-fuel::before {
  content: "\ed0c";
}

.nucleo-icon-full-screen::before {
  content: "\ed0d";
}

.nucleo-icon-fullscreen-70::before {
  content: "\ed0e";
}

.nucleo-icon-fullscreen-71::before {
  content: "\ed0f";
}

.nucleo-icon-fullscreen-76::before {
  content: "\ed10";
}

.nucleo-icon-fullscreen-77::before {
  content: "\ed11";
}

.nucleo-icon-fullscreen-double-74::before {
  content: "\ed12";
}

.nucleo-icon-fullscreen-double-75::before {
  content: "\ed13";
}

.nucleo-icon-fullscreen-split-72::before {
  content: "\ed14";
}

.nucleo-icon-fullscreen-split-73::before {
  content: "\ed15";
}

.nucleo-icon-fullsize::before {
  content: "\ed16";
}

.nucleo-icon-funnel-39::before {
  content: "\ed17";
}

.nucleo-icon-funnel-40::before {
  content: "\ed18";
}

.nucleo-icon-funnel-41::before {
  content: "\ed19";
}

.nucleo-icon-gantt::before {
  content: "\ed1a";
}

.nucleo-icon-garlic::before {
  content: "\ed1b";
}

.nucleo-icon-ghost-2::before {
  content: "\ed1c";
}

.nucleo-icon-ghost-e::before {
  content: "\ed1d";
}

.nucleo-icon-ghost::before {
  content: "\ed1e";
}

.nucleo-icon-gift-2::before {
  content: "\ed1f";
}

.nucleo-icon-gift::before {
  content: "\ed20";
}

.nucleo-icon-git-commit::before {
  content: "\ed21";
}

.nucleo-icon-glass-water::before {
  content: "\ed22";
}

.nucleo-icon-glass::before {
  content: "\ed23";
}

.nucleo-icon-glasses-2::before {
  content: "\ed24";
}

.nucleo-icon-glasses::before {
  content: "\ed25";
}

.nucleo-icon-globe-2::before {
  content: "\ed26";
}

.nucleo-icon-globe::before {
  content: "\ed27";
}

.nucleo-icon-glove::before {
  content: "\ed28";
}

.nucleo-icon-goal-64::before {
  content: "\ed29";
}

.nucleo-icon-goal-65::before {
  content: "\ed2a";
}

.nucleo-icon-golf-ball::before {
  content: "\ed2b";
}

.nucleo-icon-golf-course::before {
  content: "\ed2c";
}

.nucleo-icon-golf-strike::before {
  content: "\ed2e";
}

.nucleo-icon-golf::before {
  content: "\ed2d";
}

.nucleo-icon-gps-e::before {
  content: "\ed2f";
}

.nucleo-icon-gps::before {
  content: "\ed30";
}

.nucleo-icon-grab::before {
  content: "\ed31";
}

.nucleo-icon-gradient::before {
  content: "\ed32";
}

.nucleo-icon-grain::before {
  content: "\ed34";
}

.nucleo-icon-grape::before {
  content: "\ed33";
}

.nucleo-icon-grave::before {
  content: "\ed35";
}

.nucleo-icon-grid-45::before {
  content: "\ed36";
}

.nucleo-icon-grid-46::before {
  content: "\ed37";
}

.nucleo-icon-grid-48::before {
  content: "\ed38";
}

.nucleo-icon-grid-50::before {
  content: "\ed39";
}

.nucleo-icon-grid-square::before {
  content: "\ed3a";
}

.nucleo-icon-grid-system::before {
  content: "\ed3c";
}

.nucleo-icon-grid::before {
  content: "\ed3b";
}

.nucleo-icon-group::before {
  content: "\ed3d";
}

.nucleo-icon-hammer::before {
  content: "\ed3e";
}

.nucleo-icon-handout::before {
  content: "\ed3f";
}

.nucleo-icon-handshake::before {
  content: "\ed40";
}

.nucleo-icon-hanger::before {
  content: "\ed41";
}

.nucleo-icon-happy-sun::before {
  content: "\ed42";
}

.nucleo-icon-hat-2::before {
  content: "\ed43";
}

.nucleo-icon-hat-3::before {
  content: "\ed44";
}

.nucleo-icon-hat-top::before {
  content: "\ed45";
}

.nucleo-icon-hat::before {
  content: "\ed46";
}

.nucleo-icon-hazelnut::before {
  content: "\ed48";
}

.nucleo-icon-hdmi::before {
  content: "\ed47";
}

.nucleo-icon-headphone::before {
  content: "\ed49";
}

.nucleo-icon-headphones-2::before {
  content: "\ed4a";
}

.nucleo-icon-headphones-mic::before {
  content: "\ed4b";
}

.nucleo-icon-headphones::before {
  content: "\ed4c";
}

.nucleo-icon-heart-2::before {
  content: "\ed4d";
}

.nucleo-icon-heart-add::before {
  content: "\ed4e";
}

.nucleo-icon-heart-remove::before {
  content: "\ed4f";
}

.nucleo-icon-heartbeat::before {
  content: "\ed50";
}

.nucleo-icon-height::before {
  content: "\ed51";
}

.nucleo-icon-helicopter::before {
  content: "\ed52";
}

.nucleo-icon-helmet-2::before {
  content: "\ed53";
}

.nucleo-icon-helmet::before {
  content: "\ed54";
}

.nucleo-icon-hierarchy-53::before {
  content: "\ed55";
}

.nucleo-icon-hit-down::before {
  content: "\ed56";
}

.nucleo-icon-hit-left::before {
  content: "\ed57";
}

.nucleo-icon-hit-right::before {
  content: "\ed58";
}

.nucleo-icon-hit-up::before {
  content: "\ed59";
}

.nucleo-icon-hob::before {
  content: "\ed5a";
}

.nucleo-icon-hockey::before {
  content: "\ed5b";
}

.nucleo-icon-home-2::before {
  content: "\ed5c";
}

.nucleo-icon-home-51::before {
  content: "\ed5d";
}

.nucleo-icon-home-52::before {
  content: "\ed5e";
}

.nucleo-icon-home-minimal::before {
  content: "\ed5f";
}

.nucleo-icon-home-simple::before {
  content: "\ed60";
}

.nucleo-icon-home::before {
  content: "\ed61";
}

.nucleo-icon-honey::before {
  content: "\ed62";
}

.nucleo-icon-horse::before {
  content: "\ed64";
}

.nucleo-icon-hospital-32::before {
  content: "\ed63";
}

.nucleo-icon-hospital-33::before {
  content: "\ed65";
}

.nucleo-icon-hospital-34::before {
  content: "\ed66";
}

.nucleo-icon-hot-dog::before {
  content: "\ed67";
}

.nucleo-icon-hot-key::before {
  content: "\ed68";
}

.nucleo-icon-hotel-bell::before {
  content: "\ed69";
}

.nucleo-icon-hotel-symbol::before {
  content: "\ed6a";
}

.nucleo-icon-hotel::before {
  content: "\ed6b";
}

.nucleo-icon-hourglass::before {
  content: "\ed6c";
}

.nucleo-icon-html5::before {
  content: "\ed6d";
}

.nucleo-icon-humidity-26::before {
  content: "\ed6e";
}

.nucleo-icon-humidity-52::before {
  content: "\ed6f";
}

.nucleo-icon-hurricane-44::before {
  content: "\ed70";
}

.nucleo-icon-hurricane-45::before {
  content: "\ed71";
}

.nucleo-icon-hut::before {
  content: "\ed72";
}

.nucleo-icon-hybrid::before {
  content: "\ed74";
}

.nucleo-icon-ice-cream-22::before {
  content: "\ed73";
}

.nucleo-icon-ice-cream-72::before {
  content: "\ed75";
}

.nucleo-icon-ice-cream::before {
  content: "\ed76";
}

.nucleo-icon-ice-skates::before {
  content: "\ed78";
}

.nucleo-icon-igloo::before {
  content: "\ed77";
}

.nucleo-icon-image-01::before {
  content: "\ed79";
}

.nucleo-icon-image-02::before {
  content: "\ed7a";
}

.nucleo-icon-image-05::before {
  content: "\ed7b";
}

.nucleo-icon-image-2::before {
  content: "\ed7c";
}

.nucleo-icon-image-add::before {
  content: "\ed7d";
}

.nucleo-icon-image-delete::before {
  content: "\ed7e";
}

.nucleo-icon-image::before {
  content: "\ed7f";
}

.nucleo-icon-img-stack::before {
  content: "\ed81";
}

.nucleo-icon-infinite::before {
  content: "\ed80";
}

.nucleo-icon-input-12::before {
  content: "\ed82";
}

.nucleo-icon-input-21::before {
  content: "\ed83";
}

.nucleo-icon-invert::before {
  content: "\ed84";
}

.nucleo-icon-iron-2::before {
  content: "\ed85";
}

.nucleo-icon-iron::before {
  content: "\ed86";
}

.nucleo-icon-italic::before {
  content: "\ed87";
}

.nucleo-icon-jeans-41::before {
  content: "\ed88";
}

.nucleo-icon-jeans-pocket::before {
  content: "\ed89";
}

.nucleo-icon-jelly::before {
  content: "\ed8a";
}

.nucleo-icon-jellyfish::before {
  content: "\ed8b";
}

.nucleo-icon-journey-04::before {
  content: "\ed8c";
}

.nucleo-icon-journey-05::before {
  content: "\ed8d";
}

.nucleo-icon-journey-06::before {
  content: "\ed8e";
}

.nucleo-icon-journey-07::before {
  content: "\ed8f";
}

.nucleo-icon-journey-08::before {
  content: "\ed90";
}

.nucleo-icon-juice::before {
  content: "\ed91";
}

.nucleo-icon-ketchup::before {
  content: "\ed92";
}

.nucleo-icon-kettle::before {
  content: "\ed93";
}

.nucleo-icon-kettlebell::before {
  content: "\ed94";
}

.nucleo-icon-key-25::before {
  content: "\ed95";
}

.nucleo-icon-key-26::before {
  content: "\ed96";
}

.nucleo-icon-keyboard-hide::before {
  content: "\ed97";
}

.nucleo-icon-keyboard-wifi::before {
  content: "\ed98";
}

.nucleo-icon-keyboard-wireless::before {
  content: "\ed99";
}

.nucleo-icon-keyboard::before {
  content: "\ed9a";
}

.nucleo-icon-kiss-e::before {
  content: "\ed9b";
}

.nucleo-icon-kiss::before {
  content: "\ed9c";
}

.nucleo-icon-kiwi::before {
  content: "\ed9e";
}

.nucleo-icon-knife::before {
  content: "\ed9d";
}

.nucleo-icon-knob::before {
  content: "\ed9f";
}

.nucleo-icon-l-add::before {
  content: "\eda0";
}

.nucleo-icon-l-check::before {
  content: "\eda1";
}

.nucleo-icon-l-circle::before {
  content: "\eda2";
}

.nucleo-icon-l-circles::before {
  content: "\eda3";
}

.nucleo-icon-l-remove::before {
  content: "\eda4";
}

.nucleo-icon-l-system-update::before {
  content: "\eda5";
}

.nucleo-icon-lab::before {
  content: "\eda6";
}

.nucleo-icon-lamp-2::before {
  content: "\eda7";
}

.nucleo-icon-lamp-3::before {
  content: "\eda8";
}

.nucleo-icon-lamp-floor::before {
  content: "\eda9";
}

.nucleo-icon-lamp::before {
  content: "\edaa";
}

.nucleo-icon-language::before {
  content: "\edab";
}

.nucleo-icon-laptop-1::before {
  content: "\edac";
}

.nucleo-icon-laptop-2::before {
  content: "\edad";
}

.nucleo-icon-laptop::before {
  content: "\edae";
}

.nucleo-icon-laugh-35::before {
  content: "\edaf";
}

.nucleo-icon-laugh-e::before {
  content: "\edb0";
}

.nucleo-icon-launch-11::before {
  content: "\edb1";
}

.nucleo-icon-launch-47::before {
  content: "\edb2";
}

.nucleo-icon-layers-2::before {
  content: "\edb3";
}

.nucleo-icon-layers-3::before {
  content: "\edb4";
}

.nucleo-icon-layers::before {
  content: "\edb5";
}

.nucleo-icon-layout-11::before {
  content: "\edb6";
}

.nucleo-icon-layout-25::before {
  content: "\edb7";
}

.nucleo-icon-leaf-36::before {
  content: "\edb8";
}

.nucleo-icon-leaf-80::before {
  content: "\edb9";
}

.nucleo-icon-leaf-81::before {
  content: "\edba";
}

.nucleo-icon-leaf-edit::before {
  content: "\edbb";
}

.nucleo-icon-leggins::before {
  content: "\edbd";
}

.nucleo-icon-lemon::before {
  content: "\edc5";
}

.nucleo-icon-lens-31::before {
  content: "\edbc";
}

.nucleo-icon-lens-56::before {
  content: "\edbe";
}

.nucleo-icon-library::before {
  content: "\edbf";
}

.nucleo-icon-light-2::before {
  content: "\edc0";
}

.nucleo-icon-light-3::before {
  content: "\edc1";
}

.nucleo-icon-light-traffic::before {
  content: "\edc2";
}

.nucleo-icon-light::before {
  content: "\edc3";
}

.nucleo-icon-lighter::before {
  content: "\edc4";
}

.nucleo-icon-like-2::before {
  content: "\edc6";
}

.nucleo-icon-like-no::before {
  content: "\edc7";
}

.nucleo-icon-like::before {
  content: "\edc8";
}

.nucleo-icon-line-height::before {
  content: "\edc9";
}

.nucleo-icon-link-2::before {
  content: "\edca";
}

.nucleo-icon-link-66::before {
  content: "\edcb";
}

.nucleo-icon-link-67::before {
  content: "\edcc";
}

.nucleo-icon-link-68::before {
  content: "\edcd";
}

.nucleo-icon-link-69::before {
  content: "\edce";
}

.nucleo-icon-link-72::before {
  content: "\edcf";
}

.nucleo-icon-link-broken-70::before {
  content: "\edd0";
}

.nucleo-icon-link-broken-73::before {
  content: "\edd1";
}

.nucleo-icon-linux::before {
  content: "\edd3";
}

.nucleo-icon-list-bullet::before {
  content: "\edd2";
}

.nucleo-icon-list-numbers::before {
  content: "\edd4";
}

.nucleo-icon-list::before {
  content: "\edd5";
}

.nucleo-icon-lock-circle-open::before {
  content: "\edd6";
}

.nucleo-icon-lock-circle::before {
  content: "\edd7";
}

.nucleo-icon-lock-landscape::before {
  content: "\edd8";
}

.nucleo-icon-lock-open::before {
  content: "\edd9";
}

.nucleo-icon-lock-portrait::before {
  content: "\edda";
}

.nucleo-icon-lock::before {
  content: "\eddb";
}

.nucleo-icon-log-in::before {
  content: "\eddc";
}

.nucleo-icon-log-out-2::before {
  content: "\eddd";
}

.nucleo-icon-log-out::before {
  content: "\edde";
}

.nucleo-icon-logo-500px::before {
  content: "\eddf";
}

.nucleo-icon-logo-angellist::before {
  content: "\ede0";
}

.nucleo-icon-logo-behance::before {
  content: "\ede1";
}

.nucleo-icon-logo-blogger::before {
  content: "\ede2";
}

.nucleo-icon-logo-buffer::before {
  content: "\ede3";
}

.nucleo-icon-logo-buysellads::before {
  content: "\ede4";
}

.nucleo-icon-logo-codepen::before {
  content: "\ede5";
}

.nucleo-icon-logo-creative-market::before {
  content: "\ede6";
}

.nucleo-icon-logo-crunchbase::before {
  content: "\ede7";
}

.nucleo-icon-logo-deviantart::before {
  content: "\ede8";
}

.nucleo-icon-logo-dribbble::before {
  content: "\ede9";
}

.nucleo-icon-logo-dropbox::before {
  content: "\edea";
}

.nucleo-icon-logo-envato::before {
  content: "\edeb";
}

.nucleo-icon-logo-evernote::before {
  content: "\edec";
}

.nucleo-icon-logo-facebook::before {
  content: "\eded";
}

.nucleo-icon-logo-fb-simple::before {
  content: "\edee";
}

.nucleo-icon-logo-feedly::before {
  content: "\edef";
}

.nucleo-icon-logo-flickr::before {
  content: "\edf0";
}

.nucleo-icon-logo-github::before {
  content: "\edf1";
}

.nucleo-icon-logo-google-plus::before {
  content: "\edf2";
}

.nucleo-icon-logo-instagram::before {
  content: "\edf3";
}

.nucleo-icon-logo-lastfm::before {
  content: "\edf4";
}

.nucleo-icon-logo-linkedin::before {
  content: "\edf5";
}

.nucleo-icon-logo-medium::before {
  content: "\edf6";
}

.nucleo-icon-logo-meetup::before {
  content: "\edf7";
}

.nucleo-icon-logo-messenger::before {
  content: "\edf8";
}

.nucleo-icon-logo-mixer::before {
  content: "\edfe";
}

.nucleo-icon-logo-myspace::before {
  content: "\edf9";
}

.nucleo-icon-logo-paypal::before {
  content: "\edfa";
}

.nucleo-icon-logo-pinterest::before {
  content: "\edfb";
}

.nucleo-icon-logo-product-hunt::before {
  content: "\edfc";
}

.nucleo-icon-logo-qq::before {
  content: "\edfd";
}

.nucleo-icon-logo-reddit::before {
  content: "\edff";
}

.nucleo-icon-logo-rss::before {
  content: "\ee00";
}

.nucleo-icon-logo-shopify::before {
  content: "\ee01";
}

.nucleo-icon-logo-skype::before {
  content: "\ee02";
}

.nucleo-icon-logo-slack::before {
  content: "\ee03";
}

.nucleo-icon-logo-soundcloud::before {
  content: "\ee04";
}

.nucleo-icon-logo-spotify::before {
  content: "\ee05";
}

.nucleo-icon-logo-squarespace::before {
  content: "\ee06";
}

.nucleo-icon-logo-trello::before {
  content: "\ee07";
}

.nucleo-icon-logo-tumblr::before {
  content: "\ee08";
}

.nucleo-icon-logo-twitter::before {
  content: "\ee09";
}

.nucleo-icon-logo-vimeo::before {
  content: "\ee0a";
}

.nucleo-icon-logo-vine::before {
  content: "\ee0b";
}

.nucleo-icon-logo-vk::before {
  content: "\ee0c";
}

.nucleo-icon-logo-wechat::before {
  content: "\ee0d";
}

.nucleo-icon-logo-weibo::before {
  content: "\ee0e";
}

.nucleo-icon-logo-whatsapp::before {
  content: "\ee0f";
}

.nucleo-icon-logo-wikipedia::before {
  content: "\ee10";
}

.nucleo-icon-logo-wordpress::before {
  content: "\ee11";
}

.nucleo-icon-logo-yelp::before {
  content: "\ee12";
}

.nucleo-icon-logo-youtube::before {
  content: "\ee13";
}

.nucleo-icon-logout::before {
  content: "\ee14";
}

.nucleo-icon-lollipop::before {
  content: "\ee2f";
}

.nucleo-icon-long-sleeve::before {
  content: "\ee15";
}

.nucleo-icon-loop-30::before {
  content: "\ee16";
}

.nucleo-icon-loop-34::before {
  content: "\ee17";
}

.nucleo-icon-loop-82::before {
  content: "\ee18";
}

.nucleo-icon-love-card::before {
  content: "\ee19";
}

.nucleo-icon-luggage::before {
  content: "\ee1a";
}

.nucleo-icon-m-add::before {
  content: "\ee1b";
}

.nucleo-icon-m-check::before {
  content: "\ee1c";
}

.nucleo-icon-m-edit::before {
  content: "\ee1d";
}

.nucleo-icon-m-heart::before {
  content: "\ee1f";
}

.nucleo-icon-m-remove::before {
  content: "\ee1e";
}

.nucleo-icon-m-security::before {
  content: "\ee20";
}

.nucleo-icon-m-update::before {
  content: "\ee30";
}

.nucleo-icon-macro::before {
  content: "\ee21";
}

.nucleo-icon-magnet::before {
  content: "\ee22";
}

.nucleo-icon-makeup::before {
  content: "\ee23";
}

.nucleo-icon-man-20::before {
  content: "\ee24";
}

.nucleo-icon-man::before {
  content: "\ee25";
}

.nucleo-icon-manga-62::before {
  content: "\ee26";
}

.nucleo-icon-manga-63::before {
  content: "\ee27";
}

.nucleo-icon-map::before {
  content: "\ee28";
}

.nucleo-icon-margin-left::before {
  content: "\ee29";
}

.nucleo-icon-margin-right::before {
  content: "\ee2a";
}

.nucleo-icon-marker-2::before {
  content: "\ee31";
}

.nucleo-icon-marker-3::before {
  content: "\ee2b";
}

.nucleo-icon-marker::before {
  content: "\ee2c";
}

.nucleo-icon-market-music::before {
  content: "\ee2d";
}

.nucleo-icon-market-play::before {
  content: "\ee2e";
}

.nucleo-icon-mask-oval::before {
  content: "\ee32";
}

.nucleo-icon-mask-rect::before {
  content: "\ee39";
}

.nucleo-icon-matches::before {
  content: "\ee33";
}

.nucleo-icon-math::before {
  content: "\ee34";
}

.nucleo-icon-mayo::before {
  content: "\ee3a";
}

.nucleo-icon-measure-02::before {
  content: "\ee35";
}

.nucleo-icon-measure-17::before {
  content: "\ee36";
}

.nucleo-icon-measure-big::before {
  content: "\ee37";
}

.nucleo-icon-measuring-cup::before {
  content: "\ee38";
}

.nucleo-icon-menu-34::before {
  content: "\ee3b";
}

.nucleo-icon-menu-35::before {
  content: "\ee3c";
}

.nucleo-icon-menu-bold::before {
  content: "\ee3d";
}

.nucleo-icon-menu-dots::before {
  content: "\ee3e";
}

.nucleo-icon-menu-left::before {
  content: "\ee3f";
}

.nucleo-icon-menu-right::before {
  content: "\ee40";
}

.nucleo-icon-menu-square::before {
  content: "\ee41";
}

.nucleo-icon-menu::before {
  content: "\ee42";
}

.nucleo-icon-merge::before {
  content: "\ee43";
}

.nucleo-icon-metrics::before {
  content: "\ee44";
}

.nucleo-icon-mic-2::before {
  content: "\ee45";
}

.nucleo-icon-mic::before {
  content: "\ee46";
}

.nucleo-icon-mickey-mouse::before {
  content: "\ee47";
}

.nucleo-icon-microwave::before {
  content: "\ee48";
}

.nucleo-icon-milk::before {
  content: "\ee49";
}

.nucleo-icon-minimal-down::before {
  content: "\ee4a";
}

.nucleo-icon-minimal-left::before {
  content: "\ee4b";
}

.nucleo-icon-minimal-right::before {
  content: "\ee4c";
}

.nucleo-icon-minimal-up::before {
  content: "\ee4d";
}

.nucleo-icon-mirror-2::before {
  content: "\ee4e";
}

.nucleo-icon-mirror::before {
  content: "\ee4f";
}

.nucleo-icon-mixer::before {
  content: "\ee50";
}

.nucleo-icon-mobile-button::before {
  content: "\ee51";
}

.nucleo-icon-mobile-camera::before {
  content: "\ee52";
}

.nucleo-icon-mobile-landscape::before {
  content: "\ee53";
}

.nucleo-icon-mobile-recharger-08::before {
  content: "\ee54";
}

.nucleo-icon-mobile-recharger-09::before {
  content: "\ee55";
}

.nucleo-icon-mobile-toolbar::before {
  content: "\ee56";
}

.nucleo-icon-mobile::before {
  content: "\ee57";
}

.nucleo-icon-moka::before {
  content: "\ee58";
}

.nucleo-icon-molecule-39::before {
  content: "\ee59";
}

.nucleo-icon-molecule::before {
  content: "\ee5a";
}

.nucleo-icon-money-11::before {
  content: "\ee5b";
}

.nucleo-icon-money-12::before {
  content: "\ee5c";
}

.nucleo-icon-money-13::before {
  content: "\ee5d";
}

.nucleo-icon-money-bag::before {
  content: "\ee5e";
}

.nucleo-icon-monster-e::before {
  content: "\ee5f";
}

.nucleo-icon-monster::before {
  content: "\ee60";
}

.nucleo-icon-moon-cloud-drop::before {
  content: "\ee61";
}

.nucleo-icon-moon-cloud-fog::before {
  content: "\ee62";
}

.nucleo-icon-moon-cloud-hail::before {
  content: "\ee63";
}

.nucleo-icon-moon-cloud-light::before {
  content: "\ee64";
}

.nucleo-icon-moon-cloud-rain::before {
  content: "\ee65";
}

.nucleo-icon-moon-cloud-snow-61::before {
  content: "\ee66";
}

.nucleo-icon-moon-cloud-snow-62::before {
  content: "\ee67";
}

.nucleo-icon-moon-fog::before {
  content: "\ee68";
}

.nucleo-icon-moon-full::before {
  content: "\ee69";
}

.nucleo-icon-moon-stars::before {
  content: "\ee6a";
}

.nucleo-icon-moon::before {
  content: "\ee6b";
}

.nucleo-icon-mortar::before {
  content: "\ee6c";
}

.nucleo-icon-moto::before {
  content: "\ee6d";
}

.nucleo-icon-mountain::before {
  content: "\ee6e";
}

.nucleo-icon-mouse-08::before {
  content: "\ee6f";
}

.nucleo-icon-mouse-09::before {
  content: "\ee70";
}

.nucleo-icon-mouse-10::before {
  content: "\ee71";
}

.nucleo-icon-mouse::before {
  content: "\ee97";
}

.nucleo-icon-move-bottom-right::before {
  content: "\ee72";
}

.nucleo-icon-move-down-2::before {
  content: "\ee85";
}

.nucleo-icon-move-down-right::before {
  content: "\ee73";
}

.nucleo-icon-move-down::before {
  content: "\ee74";
}

.nucleo-icon-move-left::before {
  content: "\ee75";
}

.nucleo-icon-move-right::before {
  content: "\ee76";
}

.nucleo-icon-move-top-left::before {
  content: "\ee77";
}

.nucleo-icon-move-up-2::before {
  content: "\ee86";
}

.nucleo-icon-move-up-left::before {
  content: "\ee78";
}

.nucleo-icon-move-up::before {
  content: "\ee79";
}

.nucleo-icon-movie-61::before {
  content: "\ee7a";
}

.nucleo-icon-movie-62::before {
  content: "\ee7b";
}

.nucleo-icon-mower::before {
  content: "\ee7c";
}

.nucleo-icon-muffin::before {
  content: "\ee7d";
}

.nucleo-icon-mug::before {
  content: "\ee7e";
}

.nucleo-icon-multiple-11::before {
  content: "\ee7f";
}

.nucleo-icon-multiple-19::before {
  content: "\ee80";
}

.nucleo-icon-multiple::before {
  content: "\ee81";
}

.nucleo-icon-mushroom::before {
  content: "\ee82";
}

.nucleo-icon-music-2::before {
  content: "\ee83";
}

.nucleo-icon-music-album::before {
  content: "\ee84";
}

.nucleo-icon-name-card::before {
  content: "\ee87";
}

.nucleo-icon-navigation::before {
  content: "\ee88";
}

.nucleo-icon-nerd-22::before {
  content: "\ee89";
}

.nucleo-icon-net::before {
  content: "\ee8a";
}

.nucleo-icon-newsletter::before {
  content: "\ee8b";
}

.nucleo-icon-night::before {
  content: "\ee8c";
}

.nucleo-icon-node::before {
  content: "\ee8d";
}

.nucleo-icon-noodles::before {
  content: "\ee98";
}

.nucleo-icon-note-03::before {
  content: "\ee8e";
}

.nucleo-icon-note-04::before {
  content: "\ee8f";
}

.nucleo-icon-note-code::before {
  content: "\ee90";
}

.nucleo-icon-notebook-2::before {
  content: "\ee91";
}

.nucleo-icon-notebook::before {
  content: "\ee92";
}

.nucleo-icon-notepad::before {
  content: "\ee93";
}

.nucleo-icon-notes::before {
  content: "\ee94";
}

.nucleo-icon-notification-69::before {
  content: "\ee95";
}

.nucleo-icon-nutrition::before {
  content: "\ee9e";
}

.nucleo-icon-octopus::before {
  content: "\ee96";
}

.nucleo-icon-oil-2::before {
  content: "\ee99";
}

.nucleo-icon-oil::before {
  content: "\ee9b";
}

.nucleo-icon-olympic-flame::before {
  content: "\ee9a";
}

.nucleo-icon-onenote::before {
  content: "\ee9f";
}

.nucleo-icon-onion::before {
  content: "\ee9c";
}

.nucleo-icon-open-in-browser::before {
  content: "\ee9d";
}

.nucleo-icon-opening-times::before {
  content: "\eea0";
}

.nucleo-icon-orange::before {
  content: "\eeab";
}

.nucleo-icon-organic-2::before {
  content: "\eea1";
}

.nucleo-icon-organic::before {
  content: "\eea2";
}

.nucleo-icon-oven::before {
  content: "\eea3";
}

.nucleo-icon-owl::before {
  content: "\eea4";
}

.nucleo-icon-p-add::before {
  content: "\eea5";
}

.nucleo-icon-p-check::before {
  content: "\eea6";
}

.nucleo-icon-p-edit::before {
  content: "\eea7";
}

.nucleo-icon-p-heart::before {
  content: "\eea8";
}

.nucleo-icon-p-remove::before {
  content: "\eea9";
}

.nucleo-icon-p-system-update::before {
  content: "\eeaa";
}

.nucleo-icon-paint-16::before {
  content: "\eeac";
}

.nucleo-icon-paint-37::before {
  content: "\eead";
}

.nucleo-icon-paint-38::before {
  content: "\eeae";
}

.nucleo-icon-paint-brush::before {
  content: "\eeaf";
}

.nucleo-icon-paint-bucket-39::before {
  content: "\eeb0";
}

.nucleo-icon-paint-bucket-40::before {
  content: "\eeb1";
}

.nucleo-icon-palette::before {
  content: "\eeb2";
}

.nucleo-icon-pan::before {
  content: "\eeb3";
}

.nucleo-icon-pancake::before {
  content: "\eeba";
}

.nucleo-icon-panda::before {
  content: "\eeb4";
}

.nucleo-icon-panel::before {
  content: "\eeb5";
}

.nucleo-icon-pantone::before {
  content: "\eeb6";
}

.nucleo-icon-paper-2::before {
  content: "\eebb";
}

.nucleo-icon-paper-diploma::before {
  content: "\eeb7";
}

.nucleo-icon-paper::before {
  content: "\eeb8";
}

.nucleo-icon-paragraph::before {
  content: "\eeb9";
}

.nucleo-icon-paralympic-games::before {
  content: "\eec7";
}

.nucleo-icon-parking-sensors::before {
  content: "\eebc";
}

.nucleo-icon-parking::before {
  content: "\eec8";
}

.nucleo-icon-passport::before {
  content: "\eebd";
}

.nucleo-icon-pasta::before {
  content: "\eed3";
}

.nucleo-icon-patch-19::before {
  content: "\eebe";
}

.nucleo-icon-patch-34::before {
  content: "\eebf";
}

.nucleo-icon-patch::before {
  content: "\eec0";
}

.nucleo-icon-path-exclude::before {
  content: "\eec1";
}

.nucleo-icon-path-intersect::before {
  content: "\eec2";
}

.nucleo-icon-path-minus::before {
  content: "\eec3";
}

.nucleo-icon-path-unite::before {
  content: "\eec4";
}

.nucleo-icon-paw::before {
  content: "\eec5";
}

.nucleo-icon-pci-card::before {
  content: "\eec6";
}

.nucleo-icon-peanut::before {
  content: "\eec9";
}

.nucleo-icon-pear::before {
  content: "\eecb";
}

.nucleo-icon-peas::before {
  content: "\eed4";
}

.nucleo-icon-pen-01::before {
  content: "\eeca";
}

.nucleo-icon-pen-23::before {
  content: "\eecc";
}

.nucleo-icon-pen-tool::before {
  content: "\eecd";
}

.nucleo-icon-pencil-47::before {
  content: "\eece";
}

.nucleo-icon-pencil::before {
  content: "\eecf";
}

.nucleo-icon-pepper::before {
  content: "\eedc";
}

.nucleo-icon-percentage-39::before {
  content: "\eed0";
}

.nucleo-icon-pet-food::before {
  content: "\eed1";
}

.nucleo-icon-phone-2::before {
  content: "\eed2";
}

.nucleo-icon-phone-3::before {
  content: "\eed5";
}

.nucleo-icon-phone-call-end::before {
  content: "\eed6";
}

.nucleo-icon-phone-call::before {
  content: "\eed7";
}

.nucleo-icon-phone::before {
  content: "\eed8";
}

.nucleo-icon-pickaxe::before {
  content: "\eed9";
}

.nucleo-icon-pickle::before {
  content: "\eedd";
}

.nucleo-icon-picnic-basket::before {
  content: "\eede";
}

.nucleo-icon-picture::before {
  content: "\eeda";
}

.nucleo-icon-pig-2::before {
  content: "\eedb";
}

.nucleo-icon-pill-42::before {
  content: "\eedf";
}

.nucleo-icon-pill-43::before {
  content: "\eee0";
}

.nucleo-icon-pill-container-44::before {
  content: "\eee1";
}

.nucleo-icon-pill-container-47::before {
  content: "\eee2";
}

.nucleo-icon-pin-2::before {
  content: "\eee3";
}

.nucleo-icon-pin-3::before {
  content: "\eee4";
}

.nucleo-icon-pin-4::before {
  content: "\eee9";
}

.nucleo-icon-pin-add-2::before {
  content: "\eeea";
}

.nucleo-icon-pin-add-e::before {
  content: "\eee5";
}

.nucleo-icon-pin-add::before {
  content: "\eee6";
}

.nucleo-icon-pin-check::before {
  content: "\eeeb";
}

.nucleo-icon-pin-copy::before {
  content: "\eee7";
}

.nucleo-icon-pin-e::before {
  content: "\eee8";
}

.nucleo-icon-pin-edit::before {
  content: "\eeec";
}

.nucleo-icon-pin-heart::before {
  content: "\eef1";
}

.nucleo-icon-pin-remove-2::before {
  content: "\eef2";
}

.nucleo-icon-pin-remove-e::before {
  content: "\eeed";
}

.nucleo-icon-pin-remove::before {
  content: "\eeee";
}

.nucleo-icon-pin-security::before {
  content: "\eef3";
}

.nucleo-icon-pin::before {
  content: "\eeef";
}

.nucleo-icon-pinch::before {
  content: "\eef0";
}

.nucleo-icon-pineapple::before {
  content: "\eef4";
}

.nucleo-icon-ping-pong::before {
  content: "\eef5";
}

.nucleo-icon-pins::before {
  content: "\eef6";
}

.nucleo-icon-pizza-slice::before {
  content: "\eef7";
}

.nucleo-icon-plane-17::before {
  content: "\eef8";
}

.nucleo-icon-plane-18::before {
  content: "\eef9";
}

.nucleo-icon-planet::before {
  content: "\eefa";
}

.nucleo-icon-plant-ground::before {
  content: "\eefb";
}

.nucleo-icon-plant-vase::before {
  content: "\eefc";
}

.nucleo-icon-plate::before {
  content: "\eefd";
}

.nucleo-icon-play-68::before {
  content: "\eefe";
}

.nucleo-icon-play-69::before {
  content: "\eeff";
}

.nucleo-icon-player-19::before {
  content: "\ef00";
}

.nucleo-icon-player-48::before {
  content: "\ef01";
}

.nucleo-icon-player::before {
  content: "\ef02";
}

.nucleo-icon-playlist::before {
  content: "\ef03";
}

.nucleo-icon-plug::before {
  content: "\ef04";
}

.nucleo-icon-podium::before {
  content: "\ef05";
}

.nucleo-icon-polaroid-add::before {
  content: "\ef06";
}

.nucleo-icon-polaroid-delete::before {
  content: "\ef07";
}

.nucleo-icon-polaroid-multiple::before {
  content: "\ef08";
}

.nucleo-icon-polaroid::before {
  content: "\ef09";
}

.nucleo-icon-police::before {
  content: "\ef0a";
}

.nucleo-icon-pool::before {
  content: "\ef0b";
}

.nucleo-icon-poop::before {
  content: "\ef0c";
}

.nucleo-icon-popcorn::before {
  content: "\ef0f";
}

.nucleo-icon-position-marker::before {
  content: "\ef0d";
}

.nucleo-icon-position::before {
  content: "\ef3a";
}

.nucleo-icon-pot::before {
  content: "\ef0e";
}

.nucleo-icon-potato::before {
  content: "\ef10";
}

.nucleo-icon-power-level::before {
  content: "\ef11";
}

.nucleo-icon-preferences-circle-rotate::before {
  content: "\ef12";
}

.nucleo-icon-preferences-circle::before {
  content: "\ef13";
}

.nucleo-icon-preferences-container-circle-rotate::before {
  content: "\ef14";
}

.nucleo-icon-preferences-container-circle::before {
  content: "\ef15";
}

.nucleo-icon-preferences-container-rotate::before {
  content: "\ef16";
}

.nucleo-icon-preferences-container::before {
  content: "\ef17";
}

.nucleo-icon-preferences-rotate::before {
  content: "\ef18";
}

.nucleo-icon-preferences::before {
  content: "\ef19";
}

.nucleo-icon-print::before {
  content: "\ef1a";
}

.nucleo-icon-printer::before {
  content: "\ef1b";
}

.nucleo-icon-priority-high::before {
  content: "\ef1c";
}

.nucleo-icon-priority-low::before {
  content: "\ef1d";
}

.nucleo-icon-privacy-policy::before {
  content: "\ef21";
}

.nucleo-icon-progress::before {
  content: "\ef1e";
}

.nucleo-icon-prototype::before {
  content: "\ef3d";
}

.nucleo-icon-pulse-chart::before {
  content: "\ef1f";
}

.nucleo-icon-pulse-sleep::before {
  content: "\ef20";
}

.nucleo-icon-pulse::before {
  content: "\ef22";
}

.nucleo-icon-pumpkin::before {
  content: "\ef23";
}

.nucleo-icon-push-next::before {
  content: "\ef24";
}

.nucleo-icon-push-previous::before {
  content: "\ef25";
}

.nucleo-icon-puzzle-09::before {
  content: "\ef26";
}

.nucleo-icon-puzzle-10::before {
  content: "\ef27";
}

.nucleo-icon-puzzled-e::before {
  content: "\ef28";
}

.nucleo-icon-puzzled::before {
  content: "\ef29";
}

.nucleo-icon-quite-happy-e::before {
  content: "\ef2a";
}

.nucleo-icon-quite-happy::before {
  content: "\ef2b";
}

.nucleo-icon-quote::before {
  content: "\ef2c";
}

.nucleo-icon-rabbit::before {
  content: "\ef2d";
}

.nucleo-icon-rackets::before {
  content: "\ef2e";
}

.nucleo-icon-radar::before {
  content: "\ef2f";
}

.nucleo-icon-radiation::before {
  content: "\ef30";
}

.nucleo-icon-radio::before {
  content: "\ef31";
}

.nucleo-icon-rain-hail::before {
  content: "\ef32";
}

.nucleo-icon-rain::before {
  content: "\ef33";
}

.nucleo-icon-rainbow::before {
  content: "\ef34";
}

.nucleo-icon-ram::before {
  content: "\ef35";
}

.nucleo-icon-ranking::before {
  content: "\ef3b";
}

.nucleo-icon-rat::before {
  content: "\ef36";
}

.nucleo-icon-receipt-list-42::before {
  content: "\ef37";
}

.nucleo-icon-receipt-list-43::before {
  content: "\ef38";
}

.nucleo-icon-receipt::before {
  content: "\ef39";
}

.nucleo-icon-recipe-book-46::before {
  content: "\ef3c";
}

.nucleo-icon-recipe-book-47::before {
  content: "\ef3e";
}

.nucleo-icon-recipe-create::before {
  content: "\ef4f";
}

.nucleo-icon-recipe::before {
  content: "\ef50";
}

.nucleo-icon-recycling::before {
  content: "\ef3f";
}

.nucleo-icon-redo-10::before {
  content: "\ef40";
}

.nucleo-icon-redo-26::before {
  content: "\ef41";
}

.nucleo-icon-redo-79::before {
  content: "\ef42";
}

.nucleo-icon-redo-81::before {
  content: "\ef43";
}

.nucleo-icon-refresh-01::before {
  content: "\ef44";
}

.nucleo-icon-refresh-02::before {
  content: "\ef52";
}

.nucleo-icon-refresh-19::before {
  content: "\ef45";
}

.nucleo-icon-refresh-20::before {
  content: "\ef46";
}

.nucleo-icon-refresh-68::before {
  content: "\ef47";
}

.nucleo-icon-refresh-69::before {
  content: "\ef48";
}

.nucleo-icon-remote::before {
  content: "\ef49";
}

.nucleo-icon-remove-e-2::before {
  content: "\ef4a";
}

.nucleo-icon-remove::before {
  content: "\ef4b";
}

.nucleo-icon-replay::before {
  content: "\ef4c";
}

.nucleo-icon-reply-all::before {
  content: "\ef4d";
}

.nucleo-icon-reply::before {
  content: "\ef4e";
}

.nucleo-icon-reservation::before {
  content: "\ef51";
}

.nucleo-icon-resize-h::before {
  content: "\ef53";
}

.nucleo-icon-resize-v::before {
  content: "\ef54";
}

.nucleo-icon-restaurant-menu::before {
  content: "\ef55";
}

.nucleo-icon-rice::before {
  content: "\ef56";
}

.nucleo-icon-rim::before {
  content: "\ef58";
}

.nucleo-icon-ring::before {
  content: "\ef57";
}

.nucleo-icon-rings::before {
  content: "\ef59";
}

.nucleo-icon-ripples::before {
  content: "\ef5a";
}

.nucleo-icon-road-2::before {
  content: "\ef5b";
}

.nucleo-icon-road-sign-left::before {
  content: "\ef5c";
}

.nucleo-icon-road-sign-right::before {
  content: "\ef5d";
}

.nucleo-icon-roast-chicken::before {
  content: "\ef5e";
}

.nucleo-icon-robot-e::before {
  content: "\ef5f";
}

.nucleo-icon-robot::before {
  content: "\ef60";
}

.nucleo-icon-roll::before {
  content: "\ef61";
}

.nucleo-icon-rolling-pin::before {
  content: "\ef62";
}

.nucleo-icon-rope::before {
  content: "\ef63";
}

.nucleo-icon-rotate-22::before {
  content: "\ef64";
}

.nucleo-icon-rotate::before {
  content: "\ef65";
}

.nucleo-icon-round-down::before {
  content: "\ef66";
}

.nucleo-icon-round-e-alert::before {
  content: "\ef67";
}

.nucleo-icon-round-e-help::before {
  content: "\ef68";
}

.nucleo-icon-round-e-info::before {
  content: "\ef69";
}

.nucleo-icon-round-left-down::before {
  content: "\ef6a";
}

.nucleo-icon-round-left::before {
  content: "\ef6b";
}

.nucleo-icon-round-right-down::before {
  content: "\ef6c";
}

.nucleo-icon-round-right::before {
  content: "\ef6d";
}

.nucleo-icon-round-up-left::before {
  content: "\ef6e";
}

.nucleo-icon-round-up-right::before {
  content: "\ef6f";
}

.nucleo-icon-round-up::before {
  content: "\ef70";
}

.nucleo-icon-rowing::before {
  content: "\ef71";
}

.nucleo-icon-rugby::before {
  content: "\ef72";
}

.nucleo-icon-ruler-pencil::before {
  content: "\ef73";
}

.nucleo-icon-sad-e::before {
  content: "\ef74";
}

.nucleo-icon-sad::before {
  content: "\ef75";
}

.nucleo-icon-safe::before {
  content: "\ef76";
}

.nucleo-icon-salad::before {
  content: "\ef78";
}

.nucleo-icon-santa-hat::before {
  content: "\ef77";
}

.nucleo-icon-satisfied-e::before {
  content: "\ef79";
}

.nucleo-icon-satisfied::before {
  content: "\ef7a";
}

.nucleo-icon-sausage::before {
  content: "\ef7b";
}

.nucleo-icon-save-planet::before {
  content: "\ef7c";
}

.nucleo-icon-scale-2::before {
  content: "\ef7d";
}

.nucleo-icon-scale-3::before {
  content: "\ef7e";
}

.nucleo-icon-scale-4::before {
  content: "\ef7f";
}

.nucleo-icon-scale-down::before {
  content: "\ef80";
}

.nucleo-icon-scale-horizontal::before {
  content: "\ef81";
}

.nucleo-icon-scale-up::before {
  content: "\ef82";
}

.nucleo-icon-scale-vertical::before {
  content: "\ef83";
}

.nucleo-icon-scan::before {
  content: "\ef84";
}

.nucleo-icon-scarf::before {
  content: "\ef85";
}

.nucleo-icon-school::before {
  content: "\ef86";
}

.nucleo-icon-scissors::before {
  content: "\ef87";
}

.nucleo-icon-sd::before {
  content: "\ef88";
}

.nucleo-icon-sea-mask::before {
  content: "\ef89";
}

.nucleo-icon-search-2::before {
  content: "\ef8a";
}

.nucleo-icon-seat::before {
  content: "\ef8b";
}

.nucleo-icon-seatbelt::before {
  content: "\ef8d";
}

.nucleo-icon-security::before {
  content: "\ef8c";
}

.nucleo-icon-segmentation::before {
  content: "\ef8e";
}

.nucleo-icon-select-83::before {
  content: "\ef90";
}

.nucleo-icon-select-84::before {
  content: "\ef91";
}

.nucleo-icon-select::before {
  content: "\ef8f";
}

.nucleo-icon-selection::before {
  content: "\ef92";
}

.nucleo-icon-send-2::before {
  content: "\ef93";
}

.nucleo-icon-send::before {
  content: "\ef94";
}

.nucleo-icon-sensor::before {
  content: "\ef95";
}

.nucleo-icon-server-rack::before {
  content: "\ef96";
}

.nucleo-icon-settings-gear-63::before {
  content: "\ef97";
}

.nucleo-icon-settings-gear-64::before {
  content: "\ef98";
}

.nucleo-icon-settings-gear-65::before {
  content: "\ef99";
}

.nucleo-icon-settings-tool-66::before {
  content: "\ef9a";
}

.nucleo-icon-shaker::before {
  content: "\ef9b";
}

.nucleo-icon-shape-adjust::before {
  content: "\ef9c";
}

.nucleo-icon-shape-arrow::before {
  content: "\ef9d";
}

.nucleo-icon-shape-circle::before {
  content: "\ef9e";
}

.nucleo-icon-shape-custom::before {
  content: "\ef9f";
}

.nucleo-icon-shape-line::before {
  content: "\efa0";
}

.nucleo-icon-shape-oval::before {
  content: "\efa1";
}

.nucleo-icon-shape-polygon-2::before {
  content: "\efa2";
}

.nucleo-icon-shape-polygon::before {
  content: "\efa3";
}

.nucleo-icon-shape-rectangle::before {
  content: "\efa5";
}

.nucleo-icon-shape-square::before {
  content: "\efa4";
}

.nucleo-icon-shape-star::before {
  content: "\efa6";
}

.nucleo-icon-shape-triangle-2::before {
  content: "\efa8";
}

.nucleo-icon-shape-triangle::before {
  content: "\efa7";
}

.nucleo-icon-shapes::before {
  content: "\efa9";
}

.nucleo-icon-share-2::before {
  content: "\efaa";
}

.nucleo-icon-share-26::before {
  content: "\efab";
}

.nucleo-icon-share-27::before {
  content: "\efac";
}

.nucleo-icon-share-66::before {
  content: "\efad";
}

.nucleo-icon-share-91::before {
  content: "\efae";
}

.nucleo-icon-share-92::before {
  content: "\efaf";
}

.nucleo-icon-share-bold::before {
  content: "\efb0";
}

.nucleo-icon-share::before {
  content: "\efb1";
}

.nucleo-icon-shark-2::before {
  content: "\efb2";
}

.nucleo-icon-shark-e::before {
  content: "\efb3";
}

.nucleo-icon-shark::before {
  content: "\efb4";
}

.nucleo-icon-sharpener::before {
  content: "\efb5";
}

.nucleo-icon-shell::before {
  content: "\efb6";
}

.nucleo-icon-shirt-buttons::before {
  content: "\efb7";
}

.nucleo-icon-shirt-neck::before {
  content: "\efb8";
}

.nucleo-icon-shirt::before {
  content: "\efb9";
}

.nucleo-icon-shoe-man::before {
  content: "\efba";
}

.nucleo-icon-shoe-sport::before {
  content: "\efbb";
}

.nucleo-icon-shoe-woman::before {
  content: "\efbc";
}

.nucleo-icon-shop::before {
  content: "\efbd";
}

.nucleo-icon-shorts::before {
  content: "\efbe";
}

.nucleo-icon-shovel::before {
  content: "\efbf";
}

.nucleo-icon-shower::before {
  content: "\efc0";
}

.nucleo-icon-shuffle-01::before {
  content: "\efc1";
}

.nucleo-icon-shuffle-35::before {
  content: "\efc2";
}

.nucleo-icon-shuffle-97::before {
  content: "\efc3";
}

.nucleo-icon-shuffle-98::before {
  content: "\efc4";
}

.nucleo-icon-shy-e::before {
  content: "\efc5";
}

.nucleo-icon-shy::before {
  content: "\efc6";
}

.nucleo-icon-sickle::before {
  content: "\efc7";
}

.nucleo-icon-sidebar::before {
  content: "\efc8";
}

.nucleo-icon-sign-board::before {
  content: "\efc9";
}

.nucleo-icon-sign::before {
  content: "\efca";
}

.nucleo-icon-signal-2::before {
  content: "\efcb";
}

.nucleo-icon-signal::before {
  content: "\efcc";
}

.nucleo-icon-signature::before {
  content: "\efcd";
}

.nucleo-icon-sim-card::before {
  content: "\efce";
}

.nucleo-icon-simple-add::before {
  content: "\efcf";
}

.nucleo-icon-simple-delete::before {
  content: "\efd0";
}

.nucleo-icon-simple-down::before {
  content: "\efd1";
}

.nucleo-icon-simple-left::before {
  content: "\efd2";
}

.nucleo-icon-simple-remove::before {
  content: "\efd3";
}

.nucleo-icon-simple-right::before {
  content: "\efd4";
}

.nucleo-icon-simple-up::before {
  content: "\efd5";
}

.nucleo-icon-single-01::before {
  content: "\efd6";
}

.nucleo-icon-single-02::before {
  content: "\efd7";
}

.nucleo-icon-single-03::before {
  content: "\efd8";
}

.nucleo-icon-single-04::before {
  content: "\efd9";
}

.nucleo-icon-single-05::before {
  content: "\efda";
}

.nucleo-icon-single-body::before {
  content: "\efdb";
}

.nucleo-icon-single-content-02::before {
  content: "\efdc";
}

.nucleo-icon-single-content-03::before {
  content: "\efdd";
}

.nucleo-icon-single-copies::before {
  content: "\efde";
}

.nucleo-icon-single-copy-04::before {
  content: "\efdf";
}

.nucleo-icon-single-copy-06::before {
  content: "\efe0";
}

.nucleo-icon-single-folded-content::before {
  content: "\efe6";
}

.nucleo-icon-single-folded::before {
  content: "\efe1";
}

.nucleo-icon-single-paragraph::before {
  content: "\efe5";
}

.nucleo-icon-single::before {
  content: "\efe2";
}

.nucleo-icon-sink-wash::before {
  content: "\efe3";
}

.nucleo-icon-sink::before {
  content: "\efe4";
}

.nucleo-icon-size-large::before {
  content: "\efe7";
}

.nucleo-icon-size-medium::before {
  content: "\efe8";
}

.nucleo-icon-size-small::before {
  content: "\efec";
}

.nucleo-icon-size::before {
  content: "\efe9";
}

.nucleo-icon-skateboard-2::before {
  content: "\efea";
}

.nucleo-icon-skateboard::before {
  content: "\efeb";
}

.nucleo-icon-skating::before {
  content: "\eff0";
}

.nucleo-icon-skew-down::before {
  content: "\efed";
}

.nucleo-icon-skew-left::before {
  content: "\efee";
}

.nucleo-icon-skew-right::before {
  content: "\efef";
}

.nucleo-icon-skew-up::before {
  content: "\eff1";
}

.nucleo-icon-skirt::before {
  content: "\eff2";
}

.nucleo-icon-skull-2::before {
  content: "\eff3";
}

.nucleo-icon-skull::before {
  content: "\eff4";
}

.nucleo-icon-slacks-12::before {
  content: "\eff5";
}

.nucleo-icon-slice::before {
  content: "\eff6";
}

.nucleo-icon-slide-left::before {
  content: "\eff7";
}

.nucleo-icon-slide-right::before {
  content: "\eff8";
}

.nucleo-icon-slider::before {
  content: "\eff9";
}

.nucleo-icon-small-add::before {
  content: "\effa";
}

.nucleo-icon-small-delete::before {
  content: "\effb";
}

.nucleo-icon-small-down::before {
  content: "\effc";
}

.nucleo-icon-small-left::before {
  content: "\effe";
}

.nucleo-icon-small-remove::before {
  content: "\efff";
}

.nucleo-icon-small-right::before {
  content: "\effd";
}

.nucleo-icon-small-triangle-down::before {
  content: "\f000";
}

.nucleo-icon-small-triangle-left::before {
  content: "\f001";
}

.nucleo-icon-small-triangle-right::before {
  content: "\f002";
}

.nucleo-icon-small-triangle-up::before {
  content: "\f003";
}

.nucleo-icon-small-up::before {
  content: "\f004";
}

.nucleo-icon-smile-e::before {
  content: "\f005";
}

.nucleo-icon-smile::before {
  content: "\f006";
}

.nucleo-icon-smoothie::before {
  content: "\f007";
}

.nucleo-icon-snack::before {
  content: "\f008";
}

.nucleo-icon-snow-ball::before {
  content: "\f009";
}

.nucleo-icon-snow::before {
  content: "\f00a";
}

.nucleo-icon-snowboard::before {
  content: "\f00b";
}

.nucleo-icon-snowman::before {
  content: "\f00c";
}

.nucleo-icon-soccer-field::before {
  content: "\f00d";
}

.nucleo-icon-sock::before {
  content: "\f00e";
}

.nucleo-icon-socket-europe-1::before {
  content: "\f00f";
}

.nucleo-icon-socket-europe-2::before {
  content: "\f010";
}

.nucleo-icon-socket-uk::before {
  content: "\f011";
}

.nucleo-icon-socket::before {
  content: "\f012";
}

.nucleo-icon-soft-drink::before {
  content: "\f013";
}

.nucleo-icon-sound-wave::before {
  content: "\f014";
}

.nucleo-icon-soup::before {
  content: "\f015";
}

.nucleo-icon-soy-sauce::before {
  content: "\f016";
}

.nucleo-icon-spa::before {
  content: "\f017";
}

.nucleo-icon-spaceship::before {
  content: "\f018";
}

.nucleo-icon-speaker-01::before {
  content: "\f019";
}

.nucleo-icon-speaker-05::before {
  content: "\f01a";
}

.nucleo-icon-spectrum::before {
  content: "\f01c";
}

.nucleo-icon-speechless-e::before {
  content: "\f01b";
}

.nucleo-icon-speechless::before {
  content: "\f01d";
}

.nucleo-icon-spider::before {
  content: "\f01e";
}

.nucleo-icon-split-33::before {
  content: "\f01f";
}

.nucleo-icon-split-37::before {
  content: "\f020";
}

.nucleo-icon-sport-bag::before {
  content: "\f021";
}

.nucleo-icon-sports-fan::before {
  content: "\f022";
}

.nucleo-icon-spray::before {
  content: "\f023";
}

.nucleo-icon-square-add-08::before {
  content: "\f024";
}

.nucleo-icon-square-add-11::before {
  content: "\f025";
}

.nucleo-icon-square-corner-down-left::before {
  content: "\f026";
}

.nucleo-icon-square-corner-down-right::before {
  content: "\f027";
}

.nucleo-icon-square-corner-up-left::before {
  content: "\f028";
}

.nucleo-icon-square-corner-up-right::before {
  content: "\f029";
}

.nucleo-icon-square-delete-10::before {
  content: "\f02a";
}

.nucleo-icon-square-delete-13::before {
  content: "\f02b";
}

.nucleo-icon-square-down-06::before {
  content: "\f02c";
}

.nucleo-icon-square-down::before {
  content: "\f02d";
}

.nucleo-icon-square-download::before {
  content: "\f02e";
}

.nucleo-icon-square-e-alert::before {
  content: "\f02f";
}

.nucleo-icon-square-e-down::before {
  content: "\f030";
}

.nucleo-icon-square-e-help::before {
  content: "\f031";
}

.nucleo-icon-square-e-info::before {
  content: "\f032";
}

.nucleo-icon-square-e-left::before {
  content: "\f033";
}

.nucleo-icon-square-e-right::before {
  content: "\f034";
}

.nucleo-icon-square-e-up::before {
  content: "\f035";
}

.nucleo-icon-square-left-04::before {
  content: "\f036";
}

.nucleo-icon-square-left::before {
  content: "\f037";
}

.nucleo-icon-square-remove-09::before {
  content: "\f038";
}

.nucleo-icon-square-remove-12::before {
  content: "\f039";
}

.nucleo-icon-square-right-03::before {
  content: "\f03a";
}

.nucleo-icon-square-right::before {
  content: "\f03b";
}

.nucleo-icon-square-simple-down::before {
  content: "\f03c";
}

.nucleo-icon-square-simple-left::before {
  content: "\f03d";
}

.nucleo-icon-square-simple-right::before {
  content: "\f03e";
}

.nucleo-icon-square-simple-up::before {
  content: "\f03f";
}

.nucleo-icon-square-up-05::before {
  content: "\f040";
}

.nucleo-icon-square-up::before {
  content: "\f041";
}

.nucleo-icon-square-upload::before {
  content: "\f042";
}

.nucleo-icon-squares::before {
  content: "\f043";
}

.nucleo-icon-stadium::before {
  content: "\f044";
}

.nucleo-icon-stamp::before {
  content: "\f045";
}

.nucleo-icon-star-empty::before {
  content: "\f046";
}

.nucleo-icon-star-half::before {
  content: "\f047";
}

.nucleo-icon-steak-2::before {
  content: "\f048";
}

.nucleo-icon-steak::before {
  content: "\f049";
}

.nucleo-icon-steering-wheel::before {
  content: "\f04a";
}

.nucleo-icon-steps::before {
  content: "\f04b";
}

.nucleo-icon-stock-2::before {
  content: "\f04c";
}

.nucleo-icon-stock::before {
  content: "\f04d";
}

.nucleo-icon-storage-hanger::before {
  content: "\f04e";
}

.nucleo-icon-storage::before {
  content: "\f04f";
}

.nucleo-icon-store::before {
  content: "\f050";
}

.nucleo-icon-strategy::before {
  content: "\f051";
}

.nucleo-icon-strawberry::before {
  content: "\f052";
}

.nucleo-icon-stre-down::before {
  content: "\f053";
}

.nucleo-icon-stre-left::before {
  content: "\f054";
}

.nucleo-icon-stre-right::before {
  content: "\f055";
}

.nucleo-icon-stre-up::before {
  content: "\f056";
}

.nucleo-icon-stretch::before {
  content: "\f057";
}

.nucleo-icon-strikethrough::before {
  content: "\f058";
}

.nucleo-icon-strong-down::before {
  content: "\f059";
}

.nucleo-icon-strong-left::before {
  content: "\f05a";
}

.nucleo-icon-strong-right::before {
  content: "\f05b";
}

.nucleo-icon-strong-up::before {
  content: "\f05c";
}

.nucleo-icon-subscript::before {
  content: "\f05d";
}

.nucleo-icon-subtitles::before {
  content: "\f05e";
}

.nucleo-icon-sugar::before {
  content: "\f060";
}

.nucleo-icon-sun-cloud-drop::before {
  content: "\f05f";
}

.nucleo-icon-sun-cloud-fog::before {
  content: "\f061";
}

.nucleo-icon-sun-cloud-hail::before {
  content: "\f062";
}

.nucleo-icon-sun-cloud-light::before {
  content: "\f063";
}

.nucleo-icon-sun-cloud-rain::before {
  content: "\f064";
}

.nucleo-icon-sun-cloud-snow-54::before {
  content: "\f065";
}

.nucleo-icon-sun-cloud-snow-55::before {
  content: "\f066";
}

.nucleo-icon-sun-cloud::before {
  content: "\f067";
}

.nucleo-icon-sun-fog-30::before {
  content: "\f068";
}

.nucleo-icon-sun-fog-43::before {
  content: "\f069";
}

.nucleo-icon-sunglasses-48::before {
  content: "\f06a";
}

.nucleo-icon-sunglasses-49::before {
  content: "\f06b";
}

.nucleo-icon-sunglasses::before {
  content: "\f06c";
}

.nucleo-icon-superscript::before {
  content: "\f06d";
}

.nucleo-icon-supplement::before {
  content: "\f06e";
}

.nucleo-icon-support-16::before {
  content: "\f06f";
}

.nucleo-icon-support-17::before {
  content: "\f070";
}

.nucleo-icon-surf-2::before {
  content: "\f071";
}

.nucleo-icon-surf::before {
  content: "\f072";
}

.nucleo-icon-surprise-e::before {
  content: "\f073";
}

.nucleo-icon-surprise::before {
  content: "\f074";
}

.nucleo-icon-sushi::before {
  content: "\f075";
}

.nucleo-icon-swap-horizontal::before {
  content: "\f076";
}

.nucleo-icon-swap-vertical::before {
  content: "\f077";
}

.nucleo-icon-swimwear::before {
  content: "\f078";
}

.nucleo-icon-swipe-bottom::before {
  content: "\f079";
}

.nucleo-icon-swipe-left::before {
  content: "\f07a";
}

.nucleo-icon-swipe-right::before {
  content: "\f07b";
}

.nucleo-icon-swipe-up::before {
  content: "\f07c";
}

.nucleo-icon-swiss-knife::before {
  content: "\f07d";
}

.nucleo-icon-switcher::before {
  content: "\f07e";
}

.nucleo-icon-sync::before {
  content: "\f07f";
}

.nucleo-icon-syringe::before {
  content: "\f080";
}

.nucleo-icon-system-update::before {
  content: "\f081";
}

.nucleo-icon-table-left::before {
  content: "\f082";
}

.nucleo-icon-table-right::before {
  content: "\f083";
}

.nucleo-icon-tablet-2::before {
  content: "\f084";
}

.nucleo-icon-tablet-button::before {
  content: "\f085";
}

.nucleo-icon-tablet-reader-31::before {
  content: "\f086";
}

.nucleo-icon-tablet-reader-42::before {
  content: "\f087";
}

.nucleo-icon-tablet-toolbar::before {
  content: "\f088";
}

.nucleo-icon-tablet::before {
  content: "\f089";
}

.nucleo-icon-tacos::before {
  content: "\f08a";
}

.nucleo-icon-tactic::before {
  content: "\f08b";
}

.nucleo-icon-tag-2::before {
  content: "\f08c";
}

.nucleo-icon-tag-add::before {
  content: "\f08d";
}

.nucleo-icon-tag-check::before {
  content: "\f08e";
}

.nucleo-icon-tag-content::before {
  content: "\f08f";
}

.nucleo-icon-tag-cut::before {
  content: "\f090";
}

.nucleo-icon-tag-loyalty::before {
  content: "\f09c";
}

.nucleo-icon-tag-remove::before {
  content: "\f091";
}

.nucleo-icon-tag::before {
  content: "\f092";
}

.nucleo-icon-tags-stack::before {
  content: "\f0ad";
}

.nucleo-icon-tail-down::before {
  content: "\f093";
}

.nucleo-icon-tail-left::before {
  content: "\f094";
}

.nucleo-icon-tail-right::before {
  content: "\f095";
}

.nucleo-icon-tail-triangle-down::before {
  content: "\f096";
}

.nucleo-icon-tail-triangle-left::before {
  content: "\f097";
}

.nucleo-icon-tail-triangle-right::before {
  content: "\f098";
}

.nucleo-icon-tail-triangle-up::before {
  content: "\f099";
}

.nucleo-icon-tail-up::before {
  content: "\f09a";
}

.nucleo-icon-takeaway::before {
  content: "\f09d";
}

.nucleo-icon-tap-01::before {
  content: "\f09b";
}

.nucleo-icon-tap-02::before {
  content: "\f09e";
}

.nucleo-icon-tape::before {
  content: "\f09f";
}

.nucleo-icon-target::before {
  content: "\f0a0";
}

.nucleo-icon-tea-bag::before {
  content: "\f0a5";
}

.nucleo-icon-tea::before {
  content: "\f0a1";
}

.nucleo-icon-temperature-23::before {
  content: "\f0a2";
}

.nucleo-icon-temperature-24::before {
  content: "\f0a3";
}

.nucleo-icon-tennis-ball::before {
  content: "\f0a4";
}

.nucleo-icon-tennis::before {
  content: "\f0a6";
}

.nucleo-icon-terrace::before {
  content: "\f0ba";
}

.nucleo-icon-text-2::before {
  content: "\f0a7";
}

.nucleo-icon-text::before {
  content: "\f0a8";
}

.nucleo-icon-texture::before {
  content: "\f0ae";
}

.nucleo-icon-ticket-75::before {
  content: "\f0a9";
}

.nucleo-icon-ticket-76::before {
  content: "\f0aa";
}

.nucleo-icon-tie-01::before {
  content: "\f0ab";
}

.nucleo-icon-tie-02::before {
  content: "\f0ac";
}

.nucleo-icon-tie-bow::before {
  content: "\f0af";
}

.nucleo-icon-tile-55::before {
  content: "\f0b0";
}

.nucleo-icon-tile-56::before {
  content: "\f0b1";
}

.nucleo-icon-time-3::before {
  content: "\f0b2";
}

.nucleo-icon-time-alarm::before {
  content: "\f0b3";
}

.nucleo-icon-time-clock::before {
  content: "\f0b4";
}

.nucleo-icon-time-countdown::before {
  content: "\f0b5";
}

.nucleo-icon-timeline::before {
  content: "\f0b6";
}

.nucleo-icon-timer::before {
  content: "\f0bb";
}

.nucleo-icon-todo::before {
  content: "\f0b7";
}

.nucleo-icon-toilet-paper::before {
  content: "\f0b8";
}

.nucleo-icon-toilet::before {
  content: "\f0b9";
}

.nucleo-icon-tomato::before {
  content: "\f0c8";
}

.nucleo-icon-tool-blur::before {
  content: "\f0bc";
}

.nucleo-icon-tool-hand::before {
  content: "\f0c1";
}

.nucleo-icon-tool-select::before {
  content: "\f0c2";
}

.nucleo-icon-tooth::before {
  content: "\f0bd";
}

.nucleo-icon-touch::before {
  content: "\f0be";
}

.nucleo-icon-tracking::before {
  content: "\f0bf";
}

.nucleo-icon-tractor::before {
  content: "\f0c0";
}

.nucleo-icon-train::before {
  content: "\f0c3";
}

.nucleo-icon-tram::before {
  content: "\f0c4";
}

.nucleo-icon-transform-2d::before {
  content: "\f0c9";
}

.nucleo-icon-transform-origin::before {
  content: "\f0c5";
}

.nucleo-icon-transform::before {
  content: "\f0c6";
}

.nucleo-icon-transparent::before {
  content: "\f0ca";
}

.nucleo-icon-trash-round::before {
  content: "\f0c7";
}

.nucleo-icon-trash-simple::before {
  content: "\f0cb";
}

.nucleo-icon-trash::before {
  content: "\f0cc";
}

.nucleo-icon-treadmill::before {
  content: "\f0ce";
}

.nucleo-icon-treasure-map-40::before {
  content: "\f0cd";
}

.nucleo-icon-tree-01::before {
  content: "\f0cf";
}

.nucleo-icon-tree-02::before {
  content: "\f0d0";
}

.nucleo-icon-tree-03::before {
  content: "\f0d1";
}

.nucleo-icon-tree-ball::before {
  content: "\f0d2";
}

.nucleo-icon-tree::before {
  content: "\f0d3";
}

.nucleo-icon-trend-down::before {
  content: "\f0d4";
}

.nucleo-icon-trend-up::before {
  content: "\f0d5";
}

.nucleo-icon-triangle-down-20::before {
  content: "\f0d6";
}

.nucleo-icon-triangle-down-65::before {
  content: "\f0d7";
}

.nucleo-icon-triangle-down::before {
  content: "\f0d8";
}

.nucleo-icon-triangle-left-18::before {
  content: "\f0d9";
}

.nucleo-icon-triangle-left-63::before {
  content: "\f0da";
}

.nucleo-icon-triangle-left::before {
  content: "\f0db";
}

.nucleo-icon-triangle-right-17::before {
  content: "\f0dc";
}

.nucleo-icon-triangle-right-62::before {
  content: "\f0dd";
}

.nucleo-icon-triangle-right::before {
  content: "\f0de";
}

.nucleo-icon-triangle-up-19::before {
  content: "\f0df";
}

.nucleo-icon-triangle-up-64::before {
  content: "\f0e0";
}

.nucleo-icon-triangle-up::before {
  content: "\f0e1";
}

.nucleo-icon-tripod::before {
  content: "\f0e2";
}

.nucleo-icon-trolley::before {
  content: "\f0e3";
}

.nucleo-icon-trophy::before {
  content: "\f0e4";
}

.nucleo-icon-truck-front::before {
  content: "\f0e5";
}

.nucleo-icon-trunk::before {
  content: "\f0e6";
}

.nucleo-icon-tshirt-53::before {
  content: "\f0e7";
}

.nucleo-icon-tshirt-54::before {
  content: "\f0e8";
}

.nucleo-icon-turtle::before {
  content: "\f0e9";
}

.nucleo-icon-tv-2::before {
  content: "\f0ea";
}

.nucleo-icon-tv-old::before {
  content: "\f0eb";
}

.nucleo-icon-tv::before {
  content: "\f0ec";
}

.nucleo-icon-twitch::before {
  content: "\f0ee";
}

.nucleo-icon-ui-03::before {
  content: "\f0ed";
}

.nucleo-icon-ui-04::before {
  content: "\f0ef";
}

.nucleo-icon-umbrella-13::before {
  content: "\f0f0";
}

.nucleo-icon-umbrella-14::before {
  content: "\f0f1";
}

.nucleo-icon-underline::before {
  content: "\f0f2";
}

.nucleo-icon-underwear-man::before {
  content: "\f0f3";
}

.nucleo-icon-underwear::before {
  content: "\f0f4";
}

.nucleo-icon-undo-25::before {
  content: "\f0f5";
}

.nucleo-icon-undo-29::before {
  content: "\f0f6";
}

.nucleo-icon-ungroup::before {
  content: "\f0f7";
}

.nucleo-icon-upload::before {
  content: "\f0f8";
}

.nucleo-icon-usb::before {
  content: "\f0f9";
}

.nucleo-icon-user-frame-31::before {
  content: "\f0fa";
}

.nucleo-icon-user-frame-32::before {
  content: "\f0fb";
}

.nucleo-icon-user-frame-33::before {
  content: "\f0fc";
}

.nucleo-icon-user-run::before {
  content: "\f0fd";
}

.nucleo-icon-vampire::before {
  content: "\f0fe";
}

.nucleo-icon-vector::before {
  content: "\f0ff";
}

.nucleo-icon-ventilation::before {
  content: "\f102";
}

.nucleo-icon-vespa-front::before {
  content: "\f100";
}

.nucleo-icon-vest::before {
  content: "\f101";
}

.nucleo-icon-video-64::before {
  content: "\f103";
}

.nucleo-icon-video-65::before {
  content: "\f104";
}

.nucleo-icon-video-66::before {
  content: "\f105";
}

.nucleo-icon-video-67::before {
  content: "\f106";
}

.nucleo-icon-videocamera-71::before {
  content: "\f107";
}

.nucleo-icon-videocamera-72::before {
  content: "\f108";
}

.nucleo-icon-virus::before {
  content: "\f109";
}

.nucleo-icon-voice-record::before {
  content: "\f10a";
}

.nucleo-icon-volleyball-player::before {
  content: "\f10e";
}

.nucleo-icon-volleyball::before {
  content: "\f10b";
}

.nucleo-icon-volume-93::before {
  content: "\f10c";
}

.nucleo-icon-volume-97::before {
  content: "\f10d";
}

.nucleo-icon-volume-down::before {
  content: "\f10f";
}

.nucleo-icon-volume-up::before {
  content: "\f110";
}

.nucleo-icon-vpn::before {
  content: "\f111";
}

.nucleo-icon-waffle::before {
  content: "\f112";
}

.nucleo-icon-walk::before {
  content: "\f115";
}

.nucleo-icon-wallet-43::before {
  content: "\f113";
}

.nucleo-icon-wallet-44::before {
  content: "\f114";
}

.nucleo-icon-wallet::before {
  content: "\f116";
}

.nucleo-icon-wand-11::before {
  content: "\f117";
}

.nucleo-icon-wardrobe::before {
  content: "\f118";
}

.nucleo-icon-wash-2::before {
  content: "\f119";
}

.nucleo-icon-wash::before {
  content: "\f11a";
}

.nucleo-icon-washing-fluid::before {
  content: "\f11d";
}

.nucleo-icon-waste-danger::before {
  content: "\f11b";
}

.nucleo-icon-waste-recycling::before {
  content: "\f11c";
}

.nucleo-icon-waste::before {
  content: "\f11e";
}

.nucleo-icon-watch-circle::before {
  content: "\f11f";
}

.nucleo-icon-watch-time::before {
  content: "\f120";
}

.nucleo-icon-watch::before {
  content: "\f121";
}

.nucleo-icon-water-aerobics::before {
  content: "\f122";
}

.nucleo-icon-water-hand::before {
  content: "\f123";
}

.nucleo-icon-water-polo-ball::before {
  content: "\f125";
}

.nucleo-icon-water-sink::before {
  content: "\f124";
}

.nucleo-icon-water-surface::before {
  content: "\f126";
}

.nucleo-icon-water-wave::before {
  content: "\f128";
}

.nucleo-icon-water::before {
  content: "\f127";
}

.nucleo-icon-watermelon::before {
  content: "\f129";
}

.nucleo-icon-webcam-38::before {
  content: "\f12a";
}

.nucleo-icon-webcam-39::before {
  content: "\f12b";
}

.nucleo-icon-webpage-2::before {
  content: "\f12c";
}

.nucleo-icon-webpage::before {
  content: "\f12d";
}

.nucleo-icon-weed::before {
  content: "\f12e";
}

.nucleo-icon-weight-plate::before {
  content: "\f12f";
}

.nucleo-icon-weight::before {
  content: "\f130";
}

.nucleo-icon-what-e::before {
  content: "\f131";
}

.nucleo-icon-what::before {
  content: "\f132";
}

.nucleo-icon-wheel-2::before {
  content: "\f133";
}

.nucleo-icon-wheel::before {
  content: "\f135";
}

.nucleo-icon-wheelchair::before {
  content: "\f134";
}

.nucleo-icon-whisk::before {
  content: "\f136";
}

.nucleo-icon-whistle::before {
  content: "\f137";
}

.nucleo-icon-widget::before {
  content: "\f138";
}

.nucleo-icon-wifi-off::before {
  content: "\f139";
}

.nucleo-icon-wifi-protected::before {
  content: "\f13a";
}

.nucleo-icon-wifi-router::before {
  content: "\f13b";
}

.nucleo-icon-wifi::before {
  content: "\f13c";
}

.nucleo-icon-wind-2::before {
  content: "\f13d";
}

.nucleo-icon-wind::before {
  content: "\f13e";
}

.nucleo-icon-window-add::before {
  content: "\f13f";
}

.nucleo-icon-window-code::before {
  content: "\f140";
}

.nucleo-icon-window-delete::before {
  content: "\f141";
}

.nucleo-icon-window-paragraph::before {
  content: "\f142";
}

.nucleo-icon-window-responsive::before {
  content: "\f143";
}

.nucleo-icon-wine-list::before {
  content: "\f145";
}

.nucleo-icon-witch-hat::before {
  content: "\f144";
}

.nucleo-icon-wolf::before {
  content: "\f146";
}

.nucleo-icon-woman-21::before {
  content: "\f147";
}

.nucleo-icon-woman::before {
  content: "\f148";
}

.nucleo-icon-wood::before {
  content: "\f149";
}

.nucleo-icon-wool-ball::before {
  content: "\f14a";
}

.nucleo-icon-workout-plan::before {
  content: "\f14d";
}

.nucleo-icon-world-2::before {
  content: "\f14b";
}

.nucleo-icon-world::before {
  content: "\f14c";
}

.nucleo-icon-xmas-sock::before {
  content: "\f14e";
}

.nucleo-icon-yogurt::before {
  content: "\f151";
}

.nucleo-icon-zombie::before {
  content: "\f14f";
}

.nucleo-icon-zoom-100::before {
  content: "\f150";
}

.nucleo-icon-zoom-2::before {
  content: "\f152";
}

.nucleo-icon-zoom-88::before {
  content: "\f153";
}

.nucleo-icon-zoom-99::before {
  content: "\f155";
}

.nucleo-icon-zoom-bold-in::before {
  content: "\f154";
}

.nucleo-icon-zoom-bold-out::before {
  content: "\f156";
}

.nucleo-icon-zoom-bold::before {
  content: "\f157";
}

.nucleo-icon-zoom-circle::before {
  content: "\f158";
}

.nucleo-icon-zoom-e::before {
  content: "\f159";
}

.nucleo-icon-zoom-in::before {
  content: "\f15a";
}

.nucleo-icon-zoom-out::before {
  content: "\f15b";
}

.nucleo-icon-zoom-split-in::before {
  content: "\f15c";
}

.nucleo-icon-zoom-split-out::before {
  content: "\f15d";
}

.nucleo-icon-zoom-split::before {
  content: "\f15e";
}

.nucleo-icon-zoom-square::before {
  content: "\f15f";
}

.nucleo-icon-zoom-triangles::before {
  content: "\f160";
}

.nucleo-icon-zoom::before {
  content: "\f161";
}

